import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";

import { DEMO_ACCOUNT } from "../../Constants/features.js";
import { withSentry } from "../../helpers/wrapper";
import { db } from "../../services/firebase";
import { useAuth } from "../auth";
import { IntegrationsContextI, Source, SourceBase } from "./integrations.i";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const initialState: IntegrationsContextI = {
  sources: [],
  createSource: async (handling: string, name: string, source: string, source_type: string) => "",
  updateSourceName: async (docId: string, newName: string) => ({ success: true, message: "" }),
  deleteSource: async (docId: string) => ({ success: true, message: "" }),
};

export const IntegrationContext = createContext(initialState);

function useIntegrationFromFirebase() {
  const { _ } = useLingui();
  const { userDocument, featureFlags } = useAuth();
  const [sources, setSources] = useState<Source[]>([]);

  useEffect(() => {
    let unsubscribe = () => {};
    if (userDocument && userDocument.organization) {
      const handling = featureFlags.includes(DEMO_ACCOUNT) ? "demo_sources" : "sources";
      const sourcesRef = collection(db, "organizations", userDocument.organization, "sources");
      const orgSourcesQuery = query(
        sourcesRef,
        where("organization_id", "==", userDocument.organization),
        where("handling", "==", handling)
      );
      unsubscribe = onSnapshot(orgSourcesQuery, (snapshot) => {
        setSources(snapshot.docs.map((doc): Source => ({ ...doc.data(), id: doc.id }) as Source));
      });
    }
    return () => unsubscribe();
  }, [userDocument, featureFlags]);

  const createSource = withSentry(
    async (handling: string, name: string, source: string, source_type: string) => {
      try {
        if (!userDocument || !userDocument.organization) {
          throw new Error("User not found!");
        }

        const org = userDocument.organization;
        const user_id = userDocument.id;
        // Reference to the sources collection
        const sourcesRef = collection(db, "organizations", org, "sources");

        if (handling === "demo_sources") {
          const demoQuery = query(
            sourcesRef,
            where("handling", "==", "demo_sources"),
            where("user_id", "==", user_id)
          );
          const demoSnapshot = await getDocs(demoQuery);
          if (!demoSnapshot.empty) {
            throw new Error("You already have a demo source!");
          }
        }
        // Query firestore to check if source already exists
        const srcQuery = query(sourcesRef, where("source", "==", source));

        const srcSnapshot = await getDocs(srcQuery);

        if (!srcSnapshot.empty) {
          throw new Error("Source already exists!");
        }

        // Get current date and time as Timestamp
        const now = Timestamp.now();

        // Create a new document
        const doc: SourceBase = {
          created_at: now,
          organization_id: org,
          source: source,
          source_type: source_type,
          sync_status: "UPLOADED",
          synced_at: now,
          user_id: user_id,
          handling: handling,
          name: name,
        };
        const docRef = await addDoc(sourcesRef, doc);

        return docRef.id;
      } catch (e) {
        console.error("Error adding source: ", e);
        throw e;
      }
    }
  );

  const updateSourceName = withSentry(async (docId: string, newName: string) => {
    try {
      if (!userDocument || !userDocument.organization) {
        throw new Error("User not found!");
      }
      const org = userDocument.organization;
      // Reference to the specific document in the sources collection
      const sourceDocRef = doc(db, "organizations", org, "sources", docId);

      // Update the name field of the specified document
      await updateDoc(sourceDocRef, { name: newName });

      return { success: true, message: _(msg`Source name updated successfully!`) };
    } catch (e: unknown) {
      console.error("Error updating source name: ", e);
      let message = JSON.stringify(e);

      if (e instanceof Error) {
        message = e.message;
      }

      return { success: false, message };
    }
  });

  const deleteSource = withSentry(async (docId: string) => {
    try {
      if (!userDocument || !userDocument.organization) {
        throw new Error("User not found!");
      }
      const org = userDocument.organization;
      // Reference to the specific document in the sources collection
      const sourceDocRef = doc(db, "organizations", org, "sources", docId);

      // Delete the specified document
      await deleteDoc(sourceDocRef);

      return { success: true, message: _(msg`Source deleted successfully!`) };
    } catch (e: unknown) {
      console.error("Error deleting source: ", e);
      let message = JSON.stringify(e);

      if (e instanceof Error) {
        message = e.message;
      }

      return { success: false, message };
    }
  });

  return {
    sources,
    createSource,
    updateSourceName,
    deleteSource,
  };
}

export const IntegrationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const integration = useIntegrationFromFirebase();

  return <IntegrationContext.Provider value={integration}>{children}</IntegrationContext.Provider>;
};

export const useIntegration = () => useContext(IntegrationContext);
