import {
  CHAT_VIEW,
  FAVORITE_VIEW,
  INTEGRATIONS_VIEW,
  SETTINGS_VIEW,
  USER_MANAGEMENT_VIEW,
} from "../../Constants/constants";
import { IntegrationProvider } from "../../context/integrations";
import { useUI } from "../../context/ui";
import { OrganizationUsersProvider } from "../../context/users";
import { Favorites } from "../Favorites/Favorites";
import Integrations from "../Integrations/Integrations";
import { Settings } from "../Settings/Settings";
import { UserManagement } from "../UserManagement/UserManagement";
import { ChatView } from "./ChatView/ChatView";
import * as s from "./Main.s";

export const Main = () => {
  const { view } = useUI();

  return (
    <s.Main>
      {view === FAVORITE_VIEW && <Favorites />}

      {view === CHAT_VIEW && <ChatView />}

      {view === USER_MANAGEMENT_VIEW && (
        <OrganizationUsersProvider>
          <UserManagement />
        </OrganizationUsersProvider>
      )}

      {view === SETTINGS_VIEW && <Settings />}

      {view === INTEGRATIONS_VIEW && (
        <IntegrationProvider>
          <Integrations />
        </IntegrationProvider>
      )}
    </s.Main>
  );
};
