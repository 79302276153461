import React from "react";

const SadGiraffeSavant = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
      width="50vw"
      height="50vh"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M4451 8784 c-89 -31 -140 -92 -161 -191 -14 -65 -1 -120 42 -169 39
-44 77 -64 122 -64 l34 0 16 -69 c24 -107 26 -103 -71 -167 -49 -31 -109 -75
-136 -97 -70 -58 -170 -188 -239 -312 -126 -225 -145 -241 -483 -428 -201
-111 -326 -221 -431 -378 -89 -134 -146 -279 -124 -319 28 -52 152 -83 330
-83 148 0 256 24 365 81 78 41 191 146 243 226 l32 50 -2 -165 -3 -164 31 -66
c52 -109 134 -183 294 -264 61 -31 249 -79 383 -99 54 -7 102 -17 106 -22 4
-5 4 -99 0 -209 -4 -110 -12 -387 -18 -615 -18 -660 -32 -900 -76 -1315 -38
-351 -41 -362 -178 -645 -230 -477 -289 -744 -247 -1125 12 -111 13 -114 77
-344 45 -161 46 -167 40 -270 -3 -58 -13 -145 -22 -192 l-17 -86 -141 -6 c-78
-4 -203 -7 -277 -8 -246 -1 -181 -16 100 -23 257 -7 988 -10 1055 -5 17 2 44
-1 60 -6 l30 -8 -25 -6 c-31 -8 -38 -36 -12 -50 68 -36 475 -51 1441 -51 706
0 802 -2 797 -15 -3 -8 3 -19 13 -24 32 -17 327 -24 511 -12 200 13 273 27
278 54 9 47 -154 66 -648 71 -314 4 -328 4 -155 -5 105 -5 246 -10 315 -9 151
0 367 -16 422 -31 103 -27 -38 -49 -363 -56 -251 -6 -349 -1 -349 17 0 5 17
13 38 17 70 15 -155 20 -658 15 -724 -6 -1593 17 -1620 44 -17 18 46 24 215
19 207 -6 233 -1 60 11 -100 7 -105 8 -60 15 28 4 195 8 373 8 390 1 422 12
110 38 -43 3 -78 6 -78 7 0 1 -9 53 -20 116 -11 63 -23 157 -27 207 -5 77 -8
91 -20 82 -9 -9 -13 -43 -13 -131 l0 -120 -104 4 c-57 1 -108 6 -112 11 -19
19 33 450 57 471 2 2 30 16 62 31 39 18 68 41 88 68 37 50 38 69 2 26 -29 -34
-78 -59 -116 -59 l-24 0 24 108 c13 59 33 134 44 167 12 33 39 110 60 172 36
103 79 279 79 324 0 10 -4 19 -10 19 -5 0 -10 -13 -10 -28 0 -35 -27 -129 -40
-137 -5 -3 -7 11 -4 32 3 29 0 23 -14 -22 -10 -33 -24 -70 -30 -82 -7 -12 -12
-32 -12 -45 0 -13 -27 -102 -60 -198 -37 -108 -69 -225 -84 -306 -13 -72 -33
-151 -44 -176 -17 -39 -26 -48 -66 -61 -26 -9 -54 -13 -63 -10 -10 4 -14 2
-10 -4 11 -17 80 -25 111 -14 24 10 27 9 23 -6 -3 -10 -11 -75 -18 -145 -7
-70 -16 -133 -20 -139 -10 -17 -140 -24 -184 -9 l-37 12 7 47 c3 25 8 161 10
301 2 140 9 345 16 455 7 110 9 227 4 260 -7 57 -8 58 -21 35 -7 -14 -13 -45
-13 -70 0 -25 -8 -143 -18 -262 l-18 -218 -82 0 c-78 0 -83 1 -87 22 -5 23
-20 206 -32 388 -4 52 -13 111 -20 130 -11 31 -13 16 -13 -130 -1 -91 4 -236
10 -323 7 -110 7 -192 0 -269 -9 -90 -8 -135 5 -231 15 -113 14 -120 -3 -132
-27 -20 -92 -27 -152 -15 -29 5 -55 10 -57 10 -6 0 -13 67 -19 187 l-7 121 39
-13 c52 -19 75 -18 38 2 -48 25 -83 63 -100 107 -8 23 -28 109 -45 193 -31
158 -72 294 -115 388 -14 31 -25 63 -25 73 0 9 -16 48 -36 86 -19 39 -42 97
-50 130 l-14 61 4 -80 c4 -65 17 -118 74 -290 38 -115 75 -237 82 -270 7 -33
19 -79 26 -103 8 -23 14 -47 14 -51 0 -16 -64 5 -114 37 -51 33 -70 39 -64 23
7 -20 129 -110 158 -116 16 -4 30 -10 30 -13 0 -4 12 -47 26 -97 18 -65 26
-127 31 -221 5 -123 5 -132 -13 -142 -28 -14 -133 -22 -179 -14 l-40 7 3 75
c1 41 8 105 14 143 11 64 10 68 -11 91 -19 20 -22 31 -16 64 4 27 2 42 -6 48
-9 5 -11 1 -7 -14 4 -12 1 -22 -4 -22 -6 0 -8 12 -5 29 4 19 2 31 -7 34 -33
13 -86 324 -86 502 0 102 40 345 75 455 15 46 45 129 66 185 48 121 191 424
202 425 4 0 7 -30 8 -67 0 -38 2 -63 5 -58 4 9 20 116 64 405 47 315 87 840
95 1245 6 287 24 727 31 734 2 2 5 -4 6 -15 0 -10 4 1 8 26 l7 45 1 -53 c1
-33 -2 -51 -8 -47 -12 7 -12 -19 0 -55 8 -24 8 -24 9 -2 1 18 7 22 31 22 23 0
30 -4 30 -20 0 -13 10 -24 26 -30 35 -14 34 -14 34 23 0 43 -10 78 -21 71 -5
-3 -6 2 -3 10 14 36 -72 107 -111 92 -12 -4 -15 6 -15 64 0 39 3 70 6 70 3 0
15 -5 27 -11 l22 -10 -21 18 c-12 10 -24 30 -26 46 -5 26 5 37 17 18 7 -12 88
-36 168 -50 59 -11 149 1 221 29 16 6 31 8 34 5 4 -3 9 -166 13 -363 15 -717
24 -896 80 -1572 29 -357 41 -447 79 -620 l19 -85 -5 78 c-3 42 -4 77 -1 77 3
0 14 -17 25 -38 10 -20 38 -73 61 -117 139 -264 252 -621 271 -860 10 -118 0
-358 -16 -400 -4 -11 -2 -13 9 -9 23 8 36 49 47 149 40 357 -38 672 -293 1180
-95 189 -104 227 -132 510 -50 510 -78 1069 -91 1796 l-7 406 94 12 c389 50
620 178 699 389 20 51 22 71 18 199 -3 78 -2 146 2 152 3 6 17 -7 30 -31 80
-145 236 -256 411 -295 105 -23 323 -23 409 0 143 39 151 69 70 244 -52 112
-126 216 -213 300 -94 91 -172 145 -337 233 -74 40 -157 87 -185 106 -78 51
-168 148 -214 230 -22 41 -60 109 -83 151 -53 95 -209 258 -316 327 -104 69
-105 70 -99 135 8 90 20 111 62 111 64 0 120 43 150 112 46 112 -22 263 -141
307 -107 41 -202 24 -274 -48 -48 -48 -70 -102 -70 -172 0 -45 36 -114 71
-140 35 -25 36 -39 5 -106 l-25 -52 -71 24 c-180 61 -471 54 -599 -14 -20 -11
-23 -8 -46 44 -32 71 -31 79 6 106 49 35 72 97 65 176 -7 74 -30 120 -79 157
-65 48 -161 59 -246 30z m187 -35 c49 -24 87 -76 98 -133 8 -46 -10 -43 -19 2
-3 16 -17 43 -31 59 -76 91 -235 83 -316 -15 -34 -41 -40 -33 -10 15 50 82
184 117 278 72z m1078 -7 c49 -25 69 -47 89 -97 l16 -40 -25 30 c-14 17 -25
33 -26 37 0 4 -19 18 -42 32 -114 66 -259 28 -289 -75 -13 -42 -29 -52 -29
-18 0 56 62 129 127 149 42 14 135 5 179 -18z m-1116 -21 c3 0 3 -23 2 -51 -3
-41 -7 -50 -23 -50 -14 0 -19 7 -19 30 0 26 -3 30 -29 30 -16 0 -40 6 -55 14
l-26 13 23 10 c17 8 103 11 127 4z m925 -21 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-868 -27 c20 -23 22 -28 8 -35 -21
-12 -37 0 -30 23 4 13 2 19 -9 19 -9 0 -16 5 -16 10 0 19 24 10 47 -17z m-21
-277 c35 -107 115 -236 147 -236 6 0 -2 13 -17 29 -27 28 -27 30 -9 39 31 17
173 49 245 56 83 8 229 -8 328 -35 41 -12 77 -23 79 -25 2 -2 -2 -12 -10 -22
-8 -10 3 -1 23 20 23 24 47 66 63 111 15 40 30 82 35 93 11 28 51 35 90 16 35
-17 39 -43 14 -100 -21 -49 -33 -208 -14 -197 8 5 17 2 24 -6 6 -8 52 -43 103
-78 147 -100 235 -205 352 -420 84 -154 168 -232 378 -351 224 -126 311 -186
388 -264 116 -119 255 -372 228 -414 -32 -52 -321 -76 -463 -39 -97 25 -202
83 -274 149 -81 73 -150 179 -150 228 1 45 15 140 22 140 12 0 170 -111 194
-136 15 -16 50 -67 77 -113 55 -91 65 -90 27 2 -31 76 -70 131 -110 157 l-31
20 139 -5 c127 -4 143 -7 200 -33 75 -36 162 -121 206 -203 46 -87 49 -65 3
28 -45 93 -125 178 -204 217 -43 20 -79 28 -169 36 -136 12 -196 30 -267 77
-48 32 -53 38 -63 91 -13 66 -40 162 -45 162 -2 0 -1 -19 2 -42 4 -29 3 -39
-5 -34 -8 5 -11 -28 -11 -116 1 -95 -4 -145 -22 -218 -18 -78 -22 -125 -23
-260 -1 -158 -2 -167 -29 -224 -26 -56 -77 -122 -123 -158 l-21 -17 9 47 c9
47 3 221 -11 298 -5 24 -4 49 2 59 6 12 6 16 -1 11 -6 -3 -13 -2 -17 4 -3 5
-2 10 4 10 25 0 81 64 96 111 17 51 17 154 0 207 l-8 23 38 -26 c25 -16 42
-22 48 -16 20 20 0 41 -151 154 -40 29 -42 32 -23 41 31 14 26 36 -8 36 -63 0
-205 123 -241 209 -17 41 -44 38 -48 -6 -4 -41 28 -103 84 -163 24 -26 44 -49
44 -51 0 -2 -19 -5 -42 -6 -63 -4 -125 -47 -162 -113 -27 -49 -31 -64 -31
-135 0 -69 4 -86 27 -127 41 -69 89 -104 172 -124 88 -21 126 -39 127 -62 0
-11 2 -12 6 -4 8 21 -26 62 -65 80 l-37 16 55 6 c93 10 142 49 175 137 l14 38
0 -40 c1 -58 -31 -121 -74 -147 -20 -11 -33 -24 -28 -27 4 -3 9 -15 9 -26 1
-11 7 -46 13 -77 15 -71 19 -246 8 -311 -7 -40 -14 -52 -48 -74 -22 -14 -41
-24 -43 -22 -3 2 4 29 14 59 22 66 34 243 21 320 l-9 55 -1 -85 c-2 -114 -17
-246 -33 -295 -23 -72 -113 -101 -498 -161 -176 -28 -394 -24 -590 10 -195 34
-300 60 -365 90 -43 20 -53 29 -62 62 -21 71 -36 204 -31 282 6 92 27 139 66
147 15 3 58 15 95 26 116 35 186 126 187 242 0 86 -17 141 -61 191 -47 53 -93
76 -150 76 l-43 0 46 43 c65 58 98 112 98 157 0 51 -25 61 -48 19 -53 -98
-141 -177 -227 -205 -67 -22 -74 -31 -34 -42 l34 -8 -25 -14 c-14 -7 -65 -44
-113 -83 -80 -63 -86 -71 -70 -83 15 -11 23 -10 51 7 42 24 43 24 31 -8 -6
-15 -11 -53 -11 -85 -1 -73 38 -159 88 -191 19 -12 34 -28 34 -34 0 -17 12
-16 36 1 21 16 22 14 -29 45 -47 29 -85 93 -92 157 l-6 49 29 -61 c45 -93 132
-151 206 -137 39 8 31 -4 -18 -26 -58 -25 -73 -51 -87 -143 -14 -94 0 -265 27
-320 21 -45 14 -57 -21 -34 -53 35 -60 59 -60 206 0 74 3 151 7 170 7 30 6 32
-4 15 -19 -30 -34 -181 -27 -260 4 -38 6 -71 5 -72 -2 -2 -23 18 -48 43 -94
94 -118 175 -117 394 0 100 -4 149 -16 190 -11 37 -19 107 -22 212 -3 87 -9
155 -14 152 -11 -7 -12 -9 6 56 8 30 15 60 14 65 -1 21 -22 -45 -50 -155 -31
-125 -35 -131 -124 -175 -36 -17 -93 -30 -200 -45 -92 -13 -167 -29 -193 -42
-59 -28 -141 -105 -179 -169 -33 -55 -67 -129 -59 -129 2 0 25 34 50 75 52 82
115 143 182 175 45 22 171 39 292 40 l64 0 -43 -32 c-45 -36 -93 -111 -115
-183 -7 -25 -11 -45 -9 -45 2 0 23 36 46 80 49 91 95 139 194 204 l70 46 17
-53 c24 -72 23 -91 -14 -160 -66 -122 -223 -254 -350 -292 -132 -39 -381 -39
-475 0 -35 15 -35 16 -31 60 12 99 117 276 237 396 84 84 173 144 396 270 209
118 276 182 386 372 87 151 124 202 205 283 48 47 166 132 217 155 42 19 48
40 36 130 -5 45 -17 98 -25 117 -18 44 -13 59 27 76 48 20 66 10 85 -48z
m-118 -1238 c9 -20 10 -33 2 -48 -26 -48 -110 -27 -110 28 0 38 18 53 60 50
27 -2 38 -9 48 -30z m1204 15 c23 -21 23 -34 -3 -67 -35 -45 -89 -30 -89 25 0
52 54 77 92 42z m-552 -5404 c0 -165 -4 -175 -76 -166 -28 3 -62 11 -77 18
-27 12 -27 13 -27 113 0 56 3 117 6 136 l6 35 84 0 84 0 0 -136z m-101 -374
l24 -20 -5 33 c-5 36 -2 38 30 16 15 -11 22 -12 22 -3 0 7 2 10 4 8 2 -2 0
-27 -5 -54 -17 -98 -16 -95 -59 -95 -22 0 -40 1 -41 3 0 1 -6 37 -13 79 -14
85 -15 83 43 33z"
        />
        <path
          d="M4711 7904 c-12 -15 -21 -38 -21 -51 0 -20 4 -24 27 -21 34 4 67 48
58 77 -9 29 -39 26 -64 -5z"
        />
        <path
          d="M5380 7906 c0 -38 10 -56 43 -72 24 -13 31 -14 38 -2 14 21 10 44
-12 72 -27 35 -69 36 -69 2z"
        />
        <path
          d="M4181 6723 c7 -10 15 -20 16 -22 2 -2 3 3 3 11 0 9 -7 19 -16 22 -15
6 -15 4 -3 -11z"
        />
        <path d="M5980 6736 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z" />
        <path
          d="M4379 6713 c-13 -16 -12 -17 2 -5 23 17 51 4 47 -22 -2 -16 -10 -21
-30 -21 -16 1 -24 -3 -19 -8 26 -26 75 19 55 51 -15 24 -38 26 -55 5z"
        />
        <path
          d="M4872 6698 c-29 -29 4 -91 46 -86 35 4 40 35 12 71 -23 29 -39 34
-58 15z"
        />
        <path
          d="M5220 6678 c-24 -40 -24 -42 -5 -62 33 -32 104 32 79 71 -20 32 -51
28 -74 -9z"
        />
        <path
          d="M5993 6696 c-10 -11 -9 -15 4 -20 9 -4 21 -2 27 4 8 8 6 9 -7 4 -13
-5 -16 -2 -11 10 7 19 3 20 -13 2z"
        />
        <path
          d="M5720 6659 c0 -14 20 -20 36 -11 14 8 12 10 -10 14 -14 3 -26 2 -26
-3z"
        />
        <path
          d="M4982 6539 c-40 -20 -82 -61 -82 -80 0 -16 27 -9 46 12 32 35 76 52
134 52 58 0 102 -17 134 -52 11 -12 25 -21 33 -21 11 0 11 5 -1 29 -10 18 -36
39 -68 55 -66 33 -136 35 -196 5z"
        />
        <path
          d="M4165 6200 c-14 -23 -45 -147 -45 -181 0 -41 23 -74 58 -84 31 -9 82
42 82 82 0 34 -61 185 -77 190 -6 2 -14 -1 -18 -7z m65 -160 c4 -48 0 -61 -25
-72 -21 -9 -28 -8 -45 7 -11 10 -20 29 -20 43 0 25 28 140 38 156 6 11 49 -98
52 -134z"
        />
        <path
          d="M4985 5890 c-8 -39 2 -85 23 -102 11 -9 14 -9 8 -1 -5 9 -2 11 8 7
19 -7 21 -24 4 -24 -8 0 -5 -7 6 -15 23 -17 40 -20 31 -5 -3 6 1 10 10 10 9 0
14 -4 11 -9 -3 -5 6 -11 19 -14 14 -2 28 0 31 4 3 5 -1 9 -9 9 -10 0 -13 8 -9
25 2 15 0 25 -7 25 -6 0 -11 7 -11 15 0 8 -4 15 -10 15 -5 0 -10 -7 -10 -15 0
-8 -9 -15 -20 -15 -13 0 -20 7 -20 20 0 16 7 20 33 21 17 0 26 3 20 6 -7 2
-13 11 -13 19 0 8 -4 14 -10 14 -5 0 -10 9 -10 20 0 16 -7 20 -34 20 -30 0
-35 -4 -41 -30z"
        />
        <path
          d="M5160 5911 c0 -5 11 -11 25 -13 20 -4 23 -3 14 8 -13 16 -39 19 -39
5z"
        />
        <path
          d="M5201 5846 c-1 -28 -8 -48 -27 -67 -15 -15 -21 -29 -16 -33 10 -6 43
33 55 66 3 9 2 29 -3 45 -8 27 -8 26 -9 -11z"
        />
        <path
          d="M5172 5670 c-19 -4 -39 -13 -45 -20 -9 -10 -9 -11 1 -6 7 5 20 -2 32
-16 11 -13 19 -19 20 -14 0 5 -7 18 -16 27 -16 17 -15 18 15 9 18 -5 39 -6 48
-3 12 5 14 1 8 -16 -4 -15 -3 -21 7 -19 7 2 14 -8 16 -22 9 -76 32 -99 32 -32
0 52 -43 116 -72 106 -14 -4 -18 -2 -14 5 4 6 6 11 4 10 -2 -1 -18 -5 -36 -9z"
        />
        <path d="M5096 5621 c-3 -5 1 -11 9 -14 9 -4 12 -1 8 9 -6 16 -10 17 -17 5z" />
        <path
          d="M5205 5618 c-4 -10 -16 -18 -26 -18 -10 0 -19 -4 -19 -9 0 -5 -10
-12 -22 -14 -18 -3 -17 -5 7 -6 23 -1 27 -3 15 -11 -12 -7 -8 -10 15 -10 23 0
27 3 15 10 -13 9 -12 13 8 30 13 11 22 25 19 32 -3 9 -7 8 -12 -4z"
        />
        <path
          d="M5706 2705 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"
        />
        <path
          d="M4350 2182 c0 -70 19 -102 61 -102 27 0 29 3 29 38 0 35 -30 99 -54
114 -28 19 -36 7 -36 -50z"
        />
        <path d="M5751 2144 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M5807 2002 c-9 -10 -25 -39 -37 -64 -17 -39 -19 -58 -14 -118 4 -39
11 -67 15 -63 4 4 17 61 28 126 11 64 23 122 26 127 10 16 -1 12 -18 -8z"
        />
        <path
          d="M4482 1879 c-8 -89 -5 -113 18 -131 32 -26 44 -18 48 28 2 33 -3 46
-31 79 -20 23 -34 33 -35 24z"
        />
        <path
          d="M4825 1830 c-58 -5 -65 -7 -42 -14 28 -8 103 -3 114 8 11 10 0 11
-72 6z"
        />
        <path d="M5425 1830 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
        <path
          d="M4557 1643 c-4 -3 -7 -13 -7 -20 0 -12 3 -12 15 1 8 8 15 17 15 20 0
8 -15 7 -23 -1z"
        />
        <path
          d="M6090 1260 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"
        />
        <path
          d="M2515 1200 c3 -5 15 -10 26 -10 11 0 17 5 14 10 -3 6 -15 10 -26 10
-11 0 -17 -4 -14 -10z"
        />
        <path
          d="M3484 1196 c-10 -8 -15 -18 -11 -23 10 -10 272 -11 282 -1 4 4 5 14
2 23 -9 21 -243 22 -273 1z m241 -5 c-7 -12 -188 -11 -207 0 -7 5 35 9 100 9
68 0 111 -4 107 -9z"
        />
        <path d="M5968 1203 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        <path
          d="M6143 1203 c54 -2 139 -2 190 0 51 1 7 3 -98 3 -104 0 -146 -2 -92
-3z"
        />
        <path d="M6483 1203 c32 -2 81 -2 110 0 29 2 3 3 -58 3 -60 0 -84 -1 -52 -3z" />
        <path d="M6688 1203 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path d="M6768 1203 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z" />
        <path d="M6918 1203 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        <path d="M7003 1203 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path
          d="M3140 1180 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path
          d="M3245 1180 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
      </g>
    </svg>
  );
};

export default SadGiraffeSavant;
