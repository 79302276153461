import MUIGrid from "@mui/material/Grid";
import styled from "styled-components";
import { determineColumnWidth, determineColumnWidthMobile } from "./TableHeader.s";
import { HeaderFieldValues, headerFields } from "./UserManagement";

const shouldHideOnMobile = (headerField: HeaderFieldValues) => {
  return ([headerFields.joinedOn, headerFields.lastLoggedIn] as HeaderFieldValues[]).includes(
    headerField
  );
};

export const ResponsiveBodyGrid = styled(MUIGrid)<{ $headerField: HeaderFieldValues }>`
  display: ${({ $headerField }) => (shouldHideOnMobile($headerField) ? "none" : "grid")};
  flex: ${({ $headerField }) => determineColumnWidthMobile($headerField)};

  @media (min-width: 768px) {
    display: grid;
    flex: ${({ $headerField }) => determineColumnWidth($headerField)};
  }
`;
