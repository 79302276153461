import { msg } from "@lingui/macro";

export const initialScreenOnboarding = {
  id: "initialScreenOnboarding",
  steps: [
    {
      title: msg`Welcome to Doowii!`,
      intro: msg`Hey there! 👋 Welcome to Doowii. We're thrilled to have you. Let's take a little tour of the interface together, shall we?`,
    },
    {
      element: `[data-onboarding="tab-view-buttons"]`,
      title: msg`Exploring Around`,
      intro: msg`Got a feel for adventure? Use this space to switch between our chat and your collection of saved pins. It's like your personal Doowii playground.`,
    },
    {
      element: `[data-onboarding="open-settings-button"]`,
      title: msg`Settings`,
      intro: msg`Dive into these settings to make Doowii truly yours. Manage your account here and get everything just the way you like it.`,
    },
    {
      element: `[data-onboarding="initial-search"]`,
      title: msg`Ask a question`,
      intro: msg`Got a question? Perfect, that's what we're here for! Ask away and let's explore the answers together. Your Doowii adventure starts now!`,
    },
  ],
};
