import { Alert, FormControl, InputLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useAuth } from "../../context/auth";
import { useOrgUsers } from "../../context/users";
import { withSentry } from "../../helpers/wrapper";
import { createInvitation } from "../../services/firebase";
import { sendInvitationEmail } from "../../services/webserver/emails";
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#15394680",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  padding: "2rem",
};

const CustomInput = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: error ? "red" : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    marginBottom: "-0.7rem",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#153946",
    },
  },
}));

export default function InviteModal({ open, setOpen }) {
  const [email, setEmail] = useState("");
  const [inviteStatus, setInviteStatus] = useState(null);
  const [fireBaseError, setFireBaseError] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const { userDocument } = useAuth();
  const { organizationUsers, disabledUsers, fetchUsersInOrganization } = useOrgUsers();

  const handleClose = () => setOpen(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleSend = withSentry(async () => {
    if (!inProgress) {
      setInProgress(true);
      if (!validateEmail(email)) {
        setError(true);
        setErrorMessage(t`Please enter a valid email address.`);
      } else if (organizationUsers.find((user) => user.email === email.toLowerCase())) {
        setError(true);
        setErrorMessage(t`User already exists in organization.`);
      } else if (disabledUsers.find((user) => user.email === email.toLowerCase())) {
        setError(true);
        setErrorMessage(t`User has been disabled.`);
      } else {
        try {
          await createInvitation(userDocument.organization, email.toLowerCase());
          setInviteStatus("success");
          await fetchUsersInOrganization();
          sendInvitationEmail(email, userDocument.firstName);
        } catch (e) {
          setFireBaseError(true);
          clearState();
          console.error(e);
          throw e;
        }
      }
      setInProgress(false);
    } else {
      return;
    }
  });
  const handleEmailChange = (event) => setEmail(event.target.value);

  const clearState = () => {
    setInviteStatus(null);
    setEmail("");
    setError(false);
    setErrorMessage("");
    setInProgress(false);
    setFireBaseError(false);
  };

  React.useEffect(() => {
    return () => {
      clearState();
    };
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        components={{
          Backdrop: CustomBackdrop,
        }}
      >
        <Box sx={style}>
          {inviteStatus === null && (
            <Stack direction="column">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#153946",
                  fontSize: "1.5625rem",
                  fontWeight: 800,
                }}
              >
                <Trans>Invite More Users</Trans>
              </Typography>
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{ mt: 2 }}
                style={{ fontSize: "0.9375rem", fontWeight: 300 }}
              >
                <Trans>
                  Invite others to leverage the power of AI-enabled analytics. Users will receive an
                  email to create an account. At this time, users can access all data you have
                  integrated with Doowii.
                </Trans>
              </Typography>
              <br />
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    "&.Mui-focused": {
                      color: "#153946",
                    },
                  }}
                  error={error}
                >
                  <Trans>Email:</Trans>
                </InputLabel>
                <CustomInput id="bootstrap-input" onChange={handleEmailChange} error={error} />
                {error && (
                  <p style={{ color: "red", fontSize: "0.8rem", marginTop: "16px" }}>
                    {errorMessage}
                  </p>
                )}
              </FormControl>{" "}
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{ mt: 2 }}
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 300,
                  fontStyle: "italic",
                }}
              >
                <Trans>Coming soon: Role-based controls.</Trans>
              </Typography>
              <br />
              {fireBaseError && (
                <Alert severity="error">
                  <Trans>Error sending invite. Please try again!</Trans>
                </Alert>
              )}
              <br />
              <Stack direction="row" justifyContent={"center"} spacing={4}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    border: "2px solid #8B8989",
                    color: "#8B8989",
                    textTransform: "capitalize",
                    fontSize: "0.875rem",
                    minWidth: "5.75rem",
                  }}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  onClick={handleSend}
                  variant="contained"
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "0.875rem",
                    background: "#4EB5CC",
                    minWidth: "5.75rem",
                  }}
                >
                  <Trans>Send</Trans>
                </Button>
              </Stack>
            </Stack>
          )}
          {inviteStatus === "success" && (
            <Stack direction="column">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#153946",
                  fontSize: "1.5625rem",
                  fontWeight: 800,
                }}
              >
                <Trans>Sent!</Trans>
              </Typography>
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{ mt: 2 }}
                style={{ fontSize: "0.9375rem", fontWeight: 300 }}
              >
                <Trans>Your invitation is on its way. You can resend at anytime.</Trans>
              </Typography>
              <br />
              <Stack direction="row" justifyContent={"center"} spacing={4}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "0.875rem",
                    background: "#4EB5CC",
                    minWidth: "5.75rem",
                  }}
                >
                  <Trans>OK</Trans>
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Modal>
    </div>
  );
}
