import styled from "styled-components";

export const InitialChat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
`;
