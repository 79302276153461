import styled from "styled-components";

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 20px 10vw 0;
  }
`;

export const HeadingText = styled.h2`
  color: #153946;
  font-size: 1.5625rem;
  font-weight: 900 !important;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const InputHeading = styled.h3`
  margin-top: 15px;
`;

export const TitleAndEdit = styled(InputHeading)`
  display: flex;
  align-items: center;
`;

export const Combo = styled.div`
  display: flex;
  gap: 10px;
`;

export const Half = styled.div`
  width: 50%;
`;

export const PasswordChangeMessage = styled.p`
  color: #153946;
  font-size: 0.9375rem;
  font-weight: 300;
`;
