import { FC, useEffect, useRef } from "react";

import { useChatData } from "../../context/chat";
import { getById } from "../../helpers/actionById";
import { Doowii } from "../../search/Doowii";
import { FullContainer } from "../FullContainer/FullContainer";
import { SearchBar } from "../SearchBar/Searchbar";
import * as s from "./SearchHistory.s";
import { SearchHistoryItem } from "./SearchHistoryItem";

interface SearchHistoryProps {
  doowii: Doowii;
  query: string;
  setQuery: (query: string) => void;
  $standAlone?: boolean;
}

export const SearchHistory: FC<SearchHistoryProps> = ({ doowii, query, setQuery, $standAlone }) => {
  const scrollRef = useRef(null);
  const { threads, currentThread, fetchNextHistory, chatCount, searchHistory, allResults } =
    useChatData();
  const { answer: answers, setAnswer: setAnswers, currentResult: results, loading } = useChatData();

  useEffect(() => {
    if (loading) {
      if (scrollRef?.current && scrollRef.current.children.length > 0) {
        const lastChildIndex = scrollRef.current.children.length - 1;
        const lastChild = scrollRef.current.children[lastChildIndex];
        if (lastChild) {
          lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    } else {
      let index = allResults?.findIndex((result) => result.id === results?.id);

      if (scrollRef?.current) {
        const item = scrollRef.current.children[index];
        if (item) {
          item.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    }
  }, [results, allResults, loading]);

  useEffect(() => {
    const selected = getById("selected-chat");
    if (selected) {
      setTimeout(() => {
        selected.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, []);

  const onChatScroll = (e: any) => {
    if (e.target.scrollTop === 0 && searchHistory.length !== chatCount) {
      fetchNextHistory();
    }
  };

  return (
    <s.SearchHistory $standAlone={$standAlone}>
      {!!searchHistory.length && (
        <s.Chat>
          <FullContainer>
            <s.ScrollableDiv ref={scrollRef} onScroll={onChatScroll}>
              {searchHistory.map((item, index) => {
                let timestamp;
                if (allResults?.length && allResults[index])
                  timestamp = allResults[index].timestamp;

                return (
                  <SearchHistoryItem
                    key={index}
                    index={index}
                    last={index === searchHistory.length - 1}
                    item={item}
                    timestamp={timestamp}
                    result={allResults?.length && allResults[index]}
                    single={searchHistory.length === 1}
                    doowii={doowii}
                  />
                );
              })}
            </s.ScrollableDiv>
          </FullContainer>
        </s.Chat>
      )}
      {$standAlone && (
        <s.InputContainer $standAlone={$standAlone}>
          <SearchBar doowii={doowii} query={query} setQuery={setQuery} $standAlone={$standAlone} />
        </s.InputContainer>
      )}
    </s.SearchHistory>
  );
};
