import MUIGrid from "@mui/material/Grid";
import styled from "styled-components";
import { HeaderFieldValues, headerFields } from "./UserManagement";

export const determineColumnWidth = (headerField: HeaderFieldValues) => {
  if (headerField === headerFields.name) return 2;
  if (headerField === headerFields.email) return 3.5;
  if (headerField === headerFields.lastLoggedIn) return 3;
  if (headerField === headerFields.action) return 1;

  return 2.5;
};

export const determineColumnWidthMobile = (headerField: HeaderFieldValues) => {
  if (headerField === headerFields.name) return 2;
  if (headerField === headerFields.email) return 4;
  if (headerField === headerFields.action) return 1;

  return 2;
};

const shouldHideOnMobile = (headerField: HeaderFieldValues) => {
  return ([headerFields.joinedOn, headerFields.lastLoggedIn] as HeaderFieldValues[]).includes(
    headerField
  );
};

export const ResponsiveHeaderGrid = styled(MUIGrid)<{ $headerField: HeaderFieldValues }>`
  display: ${({ $headerField }) => (shouldHideOnMobile($headerField) ? "none" : "grid")};
  flex: ${({ $headerField }) => determineColumnWidthMobile($headerField)};

  ${({ $headerField }) => $headerField === headerFields.action && "text-align: center;"}

  @media (min-width: 768px) {
    display: grid;
    flex: ${({ $headerField }) => determineColumnWidth($headerField)};
  }
`;
