import { IconButton } from "@mui/material";
import MUIButton from "@mui/material/Button";
import MUIDrawer from "@mui/material/Drawer";
import MUIInputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import s from "styled-components";

export const DrawerHeader = styled("div")`
  display: flex;
  align-items: center;
  padding: 24px 8px;
  justify-content: space-between;
  // necessary for content to be below app bar
  ${({ theme }) => theme.mixins.toolbar}

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

export const Drawer = s(MUIDrawer)<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-shrink: 0;
  width: ${({ open }) => (open ? "100%" : 0)};
  margin-top: 0;
  z-index: 500;

  & .MuiDrawer-paper {
    width: ${({ open }) => (open ? "100%" : 0)};
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @media (min-width: 768px) {
    position: fixed;
    width: ${({ open }) => (open ? "240px" : 0)};

    & .MuiDrawer-paper {
      position: fixed;
      width: ${({ open }) => (open ? "240px" : 0)};
    }
  }
`;

/* problems with themed libraries is that we need to force important in cases like this: */
export const Button = s(MUIButton)`
  background: #153947 !important;
  text-transform: capitalize !important;
  padding: 14.25px 0 !important;
  line-height: 1 !important;
  min-width: 80% !important;

  &:hover {
    background: #4EB5CC !important;
  }

  @media (min-width: 768px) {
    min-width: 93% !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #EBEBEB !important;
    opacity: 0.7 !important;
  `}
`;

export const InputBase = s(MUIInputBase)`
  width: 100%;
  border-radius: 1px;
  border: 1px solid #ced4da;
  padding: 0.5em;

  &:hover {
    border-color: #4EB5CC;
  }

  &.Mui-focused {
    border-color: #4EB5CC;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
`;
export const CloseButton = s(IconButton)`
  display: block;

  @media (min-width: 768px) {
    display: none !important;
  }
`;

export const EmptyMessage = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Take up the entire height of its container
  color: #6c757d; // Slightly grey text
  font-size: 0.875rem; // Small text
  text-align: center;
  width: 100%;
  padding: 0 16px;
  margin: 16px 0;
`;
