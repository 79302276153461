import { FC, useEffect, useState } from "react";

import { showChatAndResultOnboarding } from "../../../../helpers/onboarding";
import { Doowii } from "../../../../search/Doowii";
import { DataWhiteboard } from "../../../DataWhiteboard/DataWhiteboard";
import { SearchBar } from "../../../SearchBar/Searchbar";
import { SearchHistory } from "../../../SearchHistory/SearchHistory";
import * as s from "./ChatAndResult.s";

interface ChatAndResultProps {
  doowii: Doowii;
  loading: boolean;
  loadingText: string;
}

export const ChatAndResult: FC<ChatAndResultProps> = ({ doowii, loading, loadingText }) => {
  useEffect(() => {
    showChatAndResultOnboarding();
  }, []);

  const [query, setQuery] = useState("");

  return (
    <s.ChatAndResult>
      <s.Container>
        <s.ChatContainer>
          <SearchHistory doowii={doowii} query={query} setQuery={setQuery} />
        </s.ChatContainer>
        <s.ResultsContainer>
          <DataWhiteboard loading={loading} loadingText={loadingText} doowii={doowii} />
        </s.ResultsContainer>
      </s.Container>
      <SearchBar doowii={doowii} query={query} setQuery={setQuery} />
    </s.ChatAndResult>
  );
};
