import styled from "styled-components";

export const Suggestions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 15px;
  gap: 10px;

  @media (min-width: 768px) {
    gap: 20px;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
  }
`;
