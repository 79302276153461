import styled from "styled-components";

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
`;
