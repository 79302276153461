import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Fragment, useEffect, useRef, useState } from "react";

import { useAuth } from "../../../context";
import { useAlert } from "../../../context/alert";
import { useChart } from "../../../context/chart";
import { usePinboard } from "../../../context/pinboard";
import { withSentry } from "../../../helpers/wrapper";
import * as s from "./PinboardModal.s";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

export const PinboardModal = ({
  results,
  visualisation,
  openPinboardModal,
  setOpenPinboardModal,
  charts,
  dataMap,
}) => {
  const { _ } = useLingui();
  const { authUser } = useAuth();
  const { boards, pinToBoard, unpinFromBoard, addNewBoard } = usePinboard();
  const { selectedColumns } = useChart();
  const { showAlert } = useAlert();

  const inputRef = useRef();

  const [creating, setCreating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState("");
  const [selectedBoards, setSelectedBoards] = useState(
    Array.from(boards.keys())
      .filter((key) => boards.get(key).pins.includes(results.id))
      .map((key) => key)
  );

  useEffect(() => {
    setSelectedBoards(
      Array.from(boards.keys())
        .filter((key) => boards.get(key).pins.includes(results.id))
        .map((key) => key)
    );

    const timeout = setTimeout(() => {
      if (inputRef.current) (inputRef.current as any).focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [openPinboardModal]);

  const handleMenuItemClick = (isChecked, key) => {
    setSelectedBoards((prevSelectedBoards) => {
      if (isChecked) {
        return [...prevSelectedBoards, key];
      } else {
        return prevSelectedBoards.filter((selectedKey) => selectedKey !== key);
      }
    });
  };

  const handleSaveChanges = withSentry(async () => {
    let updated = false;
    setSaving(true);
    try {
      boards.forEach((board, key) => {
        if (selectedBoards.includes(key)) {
          if (!board.pins.includes(results.id)) {
            const fullResult = {
              ...results,
              visualisation,
              visualisationArray: charts,
              selectedColumns,
            };

            pinToBoard(fullResult, key);
            updated = true;
          }
        } else {
          if (board.pins.includes(results.id)) {
            unpinFromBoard(results.id, key);
            updated = true;
          }
        }
      });
      if (updated) {
        const resultTitle = results.title;
        showAlert({
          variant: "success",
          message: _(msg`${resultTitle} has been successfully pinned/unpinned!`),
        });
      }
    } catch (e) {
      console.error(e);
      showAlert({
        variant: "error",
        message: _(msg`Failed to add to pinboard. Please try again later.`),
      });
    } finally {
      setSaving(false);
    }

    setOpenPinboardModal(false);
    setValue("");
  });

  const handleClose = () => {
    setOpenPinboardModal(false);
    setValue("");
  };

  const onSelect = async () => {
    if (!value.trim()) return;

    setCreating(true);
    try {
      const id = await addNewBoard(value);

      setValue("");
      setSelectedBoards((prevSelectedBoards) => [...prevSelectedBoards, id]);
    } catch (e) {
      console.error(e);
      showAlert({
        variant: "error",
        message: `Error creating a pinboard: ${e.message}`,
      });
      throw e;
    } finally {
      setCreating(false);
    }
  };

  const onEnter = withSentry(async (e) => {
    if (e.key !== "Enter") return;

    await onSelect();
  });

  const loading = saving || creating;
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={openPinboardModal}
        onClose={handleClose}
        data-testid="add-to-pinboard-modal-chat"
      >
        <DialogTitle id="pinboard-modal-title">
          <Trans>Add to Pinboard</Trans>
        </DialogTitle>

        <DialogContent>
          <span>
            <Trans>Add to your existing pinboards or create a new board.</Trans>
          </span>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "100%",
              marginTop: "2%",
            }}
          >
            <List
              dense={false}
              sx={{
                width: "100%",
                borderRadius: "6px",
              }}
              disablePadding={true}
            >
              {Array.from(boards.keys()).map((key, index) => {
                const name = boards.get(key).name;
                const checked = selectedBoards.includes(key);

                return (
                  <Fragment key={index}>
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleMenuItemClick(!checked, key)}
                      dense
                    >
                      <ListItemIcon sx={{ minWidth: "35px", height: "30px" }}>
                        <Checkbox
                          edge="start"
                          checked={selectedBoards.includes(key)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": name }}
                        />
                      </ListItemIcon>
                      <ListItemText id={name} primary={name} />
                    </ListItemButton>
                  </Fragment>
                );
              })}
            </List>
          </Box>
          <hr />
          {isEditing ? (
            <s.InputAndButtons>
              <TextField
                id="add-board"
                label={_(msg`Enter name ↵ `)}
                variant="outlined"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={onEnter}
                fullWidth={true}
                autoComplete="off"
                inputRef={inputRef}
                size="small"
                disabled={loading}
              />
              <Button variant="outlined" onClick={() => setIsEditing(false)} disabled={loading}>
                <CloseIcon />
              </Button>
              <Button variant="contained" onClick={onSelect} disabled={!value.trim() || loading}>
                {creating ? <CircularProgress size="14px" /> : <CheckIcon />}
              </Button>
            </s.InputAndButtons>
          ) : (
            <s.AddNew onClick={() => setIsEditing(true)}>
              <AddIcon />
              <Trans>Create New Pinboard</Trans>
            </s.AddNew>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ marginRight: "5px" }}
            id="close-button"
            variant="outlined"
            onClick={handleClose}
            disabled={loading}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            sx={{ marginRight: "15px" }}
            id="close-button"
            variant="contained"
            onClick={handleSaveChanges}
            disabled={loading}
            startIcon={saving ? <CircularProgress size="14px" /> : <></>}
          >
            <Trans>Save</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
