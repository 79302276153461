import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useRef, useState } from "react";

import { DEFAULT_QUERY_OPTIONS } from "../../../Constants/constants";
import { useChatData } from "../../../context/chat";
import { useQuery } from "../../../hooks/useQuery";
import { Theme } from "../../../styled";
import {
  applyColumnOrderFromRef,
  applyColumnWidthsFromRef,
  determineColumnType,
  determineColumns,
  handleColumnOrderChange,
  handleColumnResize,
} from "./helpers";

interface DataTableProps {
  result: any;
  selectedColumns: any;
  setSelectedColumns: any;
  setTotalRows?: any;
  isMini?: boolean;
  width?: number | string;
  height?: number | string;
  selectable?: boolean;
}

export const DataTable = ({
  result,
  selectedColumns,
  setSelectedColumns,
  setTotalRows = () => {},
  width = "100%",
  height = "100%",
  selectable = true,
}: DataTableProps) => {
  const [paginationModel, setPaginationModel] = useState(DEFAULT_QUERY_OPTIONS);
  const [sortModel, setSortModel] = useState(
    JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]")
  );

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const prevSqlRef = useRef();
  const columnWidthsRef = useRef({});
  const columnOrderRef = useRef([]);

  const [queryOptions, setQueryOptions] = useState({ paginationModel: paginationModel });
  const {
    loading,
    rows,
    pageInfo: _pageInfo,
    queryError,
  } = useQuery(result, queryOptions, shouldFetchData);
  const { currentResult } = useChatData();

  const pageInfo = _pageInfo as any;

  const [rowCountState, setRowCountState] = useState(pageInfo?.totalRowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
    );
    (setTotalRows as any)((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState, setTotalRows]);

  const handlePaginationModelChange = (paginationModel) => {
    setQueryOptions((prev) => {
      return {
        ...prev,
        paginationModel: paginationModel,
      };
    });
    setPaginationModel(paginationModel);
    setShouldFetchData(true);
  };

  const handleSortModelChange = (newSortModel) => {
    if (rows.length === 0) {
      return;
    }
    setPaginationModel((prev) => {
      return {
        ...prev,
        page: 0,
      };
    });

    setQueryOptions((prev) => {
      return {
        ...prev,
        sortModel: newSortModel,
        paginationModel: { ...prev.paginationModel, page: 0 },
      };
    });

    setShouldFetchData(true);

    setSortModel(newSortModel);
    localStorage.setItem(`sortModel-${result?.id}`, JSON.stringify(newSortModel));
  };

  const handleColumnHeaderClick = (event, params) => {
    // Stop the default sorting behavior
    event.stopPropagation();
    handleColumnSelect(params);
  };

  const handleColumnSelect = (column) => {
    if (!selectable) return;

    if (selectedColumns[column?.headerName] === undefined) {
      setSelectedColumns((prev) => {
        return {
          ...prev,
          [column?.headerName]: {
            type:
              currentResult?.chartConfig?.column_types[column?.headerName] ||
              determineColumnType(column?.headerName, rows),
            name: column?.headerName,
            length: pageInfo?.totalRowCount,
          },
        };
      });
    } else {
      setSelectedColumns((prev) => {
        const newColumns = { ...prev };
        delete newColumns[column?.headerName];
        return newColumns;
      });
    }
  };

  const onColumnWidthChange = (params) => handleColumnResize(params, columnWidthsRef);
  const onColumnOrderChange = (params) => handleColumnOrderChange(params, columnOrderRef);

  const rawColumns = determineColumns(result, pageInfo, selectedColumns, handleColumnHeaderClick);
  const orderedColumns = applyColumnOrderFromRef(rawColumns, columnOrderRef);
  const columns = applyColumnWidthsFromRef(orderedColumns, columnWidthsRef);

  useEffect(() => {
    const isSqlChanged = prevSqlRef.current !== result?.sql;
    if (isSqlChanged) {
      prevSqlRef.current = result?.sql;
      const localSortModel = JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]");
      setShouldFetchData(true);

      setPaginationModel(DEFAULT_QUERY_OPTIONS);
      setQueryOptions((prev) => {
        return {
          ...prev,
          paginationModel: DEFAULT_QUERY_OPTIONS,
          sortModel: localSortModel,
        };
      });
      setSortModel(localSortModel);
      columnOrderRef.current = [];
      columnWidthsRef.current = {};
    }
  }, [result?.sql]);

  useEffect(() => {
    const localSortModel = JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]");
    setShouldFetchData(true);

    setPaginationModel(DEFAULT_QUERY_OPTIONS);
    setQueryOptions((prev) => {
      return {
        ...prev,
        paginationModel: DEFAULT_QUERY_OPTIONS,
        sortModel: localSortModel,
      };
    });
    setSortModel(localSortModel);
  }, [result, result?.status]);

  let colors = {
    headerBackground: Theme.core.colors.darkBlue,
    selectedBackground: Theme.core.colors.lightBlue,
    selectedRowBackground: Theme.core.colors["lightBlue:hover"],
  };

  return (
    <>
      <Box
        sx={{
          height,
          width,
          maxWidth: width,
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            cursor: selectable ? "pointer" : "default",
          },
          "& .MuiDataGrid-columnHeaders": {
            height: "35px",
            // eslint-disable-next-line lingui/no-unlocalized-strings
            minHeight: "35px !important",
            // eslint-disable-next-line lingui/no-unlocalized-strings
            maxHeight: "35px !important",
          },
          "& div.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) div.MuiDataGrid-columnHeaderTitleContainer button":
            {
              display: "none",
            },
          "& .column-header": {
            backgroundColor: colors.headerBackground,
            color: "white",
          },
          "& .column-header-selected": {
            backgroundColor: colors.selectedBackground,
            color: "white",
          },

          "& .row.highest": {
            backgroundColor: "#66b083",
          },

          "& .row.higher": {
            backgroundColor: "#b5ebce",
          },
          "& .row.high": {
            backgroundColor: "#d5eadf",
          },
          "& .row.low": {
            backgroundColor: "#f5e1df",
          },
          "& .row.lower": {
            backgroundColor: "#e4b1ab",
          },
          "& .row.lowest": {
            backgroundColor: "#d3756c",
          },
          "& .row.selected": {
            backgroundColor: "#e3f3f7",
            color: "black",
          },
        }}
      >
        <DataGridPro
          data-testid="table"
          rowHeight={30}
          sx={{
            ".MuiDataGrid-columnHeaderTitleContainer": { width },
            ".MuiDataGrid-columnHeaderTitleContainerContent": { width },
          }}
          loading={loading}
          rows={rows}
          columns={columns}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[50]}
          rowCount={rowCountState}
          onPaginationModelChange={handlePaginationModelChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          disableColumnFilter
          onColumnWidthChange={onColumnWidthChange}
          onColumnOrderChange={onColumnOrderChange}
        />
      </Box>
    </>
  );
};
