import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import {
  GENERAL_TIPS,
  MATH_DEMO_AFTER,
  MATH_DEMO_BEFORE,
  MATH_DEMO_TIPS,
  UPREP_EXAMPLE_AFTER,
  UPREP_EXAMPLE_BEFORE,
} from "../../../Constants/constants";
import { useAuth } from "../../../context";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
interface TipsModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function TipsModal({ open, setOpen }: TipsModalProps) {
  const { _ } = useLingui();
  const handleClose = () => {
    setOpen(false);
  };
  const { userDocument } = useAuth();

  /* TODO: get prompt tips for each org from database */
  const promptTips =
    userDocument?.organization === "student_math_demo" ? MATH_DEMO_TIPS : GENERAL_TIPS;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="tips-dialog-title"
      aria-describedby="tips-dialog-description"
    >
      <DialogTitle id="tips-dialog-title">
        <Typography variant="h5" gutterBottom>
          <Trans>Composing Prompts</Trans>
        </Typography>
      </DialogTitle>
      <DialogContent>
        {promptTips.map((promptTip, index) => (
          <div key={index}>
            <Typography variant="h6" gutterBottom>
              {_(promptTip.title)}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {_(promptTip.description)}
            </Typography>
          </div>
        ))}

        <>
          <Typography variant="h6" gutterBottom>
            <Trans>Example</Trans>
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            <Trans>Before</Trans>
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            defaultValue={
              userDocument?.organization === "066qkao65eomp21gt4ig"
                ? _(UPREP_EXAMPLE_BEFORE)
                : _(MATH_DEMO_BEFORE)
            }
            disabled
          />
          <Typography variant="subtitle2" gutterBottom>
            <Trans>After</Trans>
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            defaultValue={
              userDocument?.organization === "066qkao65eomp21gt4ig"
                ? _(UPREP_EXAMPLE_AFTER)
                : _(MATH_DEMO_AFTER)
            }
            disabled
          />
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans>Done</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
