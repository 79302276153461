import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAuth } from "../../../context";
import { useChart } from "../../../context/chart";
import { useChatData } from "../../../context/chat";
import { Result } from "../../../context/chat/chat.i";
import { usePinboard } from "../../../context/pinboard";
import { downloadCSV } from "../../../helpers/downloadCSV";
import { withSentry } from "../../../helpers/wrapper";
import { Doowii } from "../../../search/Doowii";
import { Theme } from "../../../styled";
import ChartContent from "../../Chart/ChartContent";
import { ChartSelector } from "../../ChartSelector/ChartSelector";
import { Modal } from "../../Modal/Modal";
import useNotification from "../../Navbar/UserProfile/Notifications/useNotification";
import * as dataS from "../DataWhiteboard.s";
import * as s from "./WhiteboardFullScreen.s";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { ParentDocTypeEnum } from "../../../api/retriever.i";

interface WhiteboardFullScreenProps {
  handleClose: () => void;
  results: Result;
  openAddToPinboardModal?: () => void;
  doowii: Doowii;
}

export const WhiteboardFullScreen: FC<WhiteboardFullScreenProps> = ({
  handleClose,
  results,
  openAddToPinboardModal,
  doowii,
}) => {
  const { _ } = useLingui();
  const { charts } = useChart();
  const { userDocument } = useAuth();
  const { view } = useUI();
  const { boards, unpinFromBoard, currBoardId } = usePinboard();
  const { currentResult, deleteChatFromThread, dataMap, currentThread } = useChatData();

  const { deleteNotificationByChatId } = useNotification();
  const [chartType, setChartType] = useState(null);

  const parentDocId = view === ViewEnum.CHAT_VIEW ? currentThread : currBoardId;
  const parentDocType =
    view === ViewEnum.CHAT_VIEW ? ParentDocTypeEnum.THREAD : ParentDocTypeEnum.PINBOARD;

  const handleDownloadCSV: any = withSentry(async () => {
    await downloadCSV({
      doc_id: results?.id,
      parent_doc_id: parentDocId,
      parent_doc_type: parentDocType,
      filename: `${results?.title}.csv`,
      currentResult: results,
      dataMap,
    });
  });

  const handleChatDelete = async () => {
    if (!window.confirm(_(msg`Are you sure you want to delete this chat?`))) return;

    for (const [board, details] of boards.entries()) {
      if (details.pins.includes(currentResult?.id)) {
        await unpinFromBoard(currentResult?.id, board);
      }
    }
    await deleteChatFromThread();
    if (currentResult?.type === "PREDICTION") {
      await deleteNotificationByChatId(currentResult?.id);
    }
  };

  useEffect(() => {
    setChartType(currentResult?.visualisation);
  }, [currentResult]);

  return (
    <Modal title={results.title} open handleClose={handleClose}>
      <s.WhiteboardFullScreen>
        <s.ChartSelectionAndActionsContainer>
          <ChartSelector
            result={results}
            chartType={chartType}
            setChartType={setChartType}
            charts={charts}
          />
          <s.ActionsContainer>
            <Tooltip title={_(msg`Add to pinboard`)} placement="top">
              <IconButton
                sx={{ color: Theme.core.colors.darkBlue }}
                onClick={openAddToPinboardModal}
              >
                <DashboardCustomizeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={_(msg`Download CSV`)} placement="top">
              <IconButton sx={{ color: Theme.core.colors.darkBlue }} onClick={handleDownloadCSV}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={_(msg`Delete chat`)} placement="top">
              <IconButton sx={{ color: Theme.core.colors.darkBlue }} onClick={handleChatDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </s.ActionsContainer>
        </s.ChartSelectionAndActionsContainer>
        <dataS.DataWhiteboard>
          <ChartContent chartType={chartType} doowii={doowii} />
        </dataS.DataWhiteboard>
      </s.WhiteboardFullScreen>
    </Modal>
  );
};
