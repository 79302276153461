import styled, { keyframes } from "styled-components";

const getBorderColor = (hasError: boolean, isActive: boolean) => {
  if (hasError) {
    return "#a50909";
  }
  if (isActive) {
    return "#003A47";
  }

  return "#dadfe4";
};

export const QuestionContainer = styled.div<{
  $hasError?: boolean;
  $isActive?: boolean;
  $loading: boolean;
}>`
  background-color: #edf4fb;
  border: 2px solid ${({ $hasError, $isActive }) => getBorderColor($hasError, $isActive)};
  border-radius: 32px;
  border-top-left-radius: 0;
  font-size: 15px;
  padding: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;

  transition: all 0.3s linear;

  ${({ $hasError, $isActive, $loading }) => {
    const interactive = !$hasError && !$isActive;

    return (
      interactive &&
      `
      &:hover {
        cursor: ${$hasError ? "not-allowed" : $loading ? "wait" : "pointer"};
        opacity: ${$loading ? 1 : 0.5};
        border: "#003A47";
      }
  `
    );
  }}
`;

const DotTypingAnimation = keyframes`
  0% {
    box-shadow:
      9984px 0 0 0 #153A48,
      9994px 0 0 0 #153A48,
      10004px 0 0 0 #153A48;
  }
  16.667% {
    box-shadow:
      9984px -10px 0 0 #153A48,
      9994px 0 0 0 #153A48,
      10004px 0 0 0 #153A48;
  }
  33.333% {
    box-shadow:
      9984px 0 0 0 #153A48,
      9994px 0 0 0 #153A48,
      10004px 0 0 0 #153A48;
  }
  50% {
    box-shadow:
      9984px 0 0 0 #153A48,
      9994px -10px 0 0 #153A48,
      10004px 0 0 0 #153A48;
  }
  66.667% {
    box-shadow:
      9984px 0 0 0 #153A48,
      9994px 0 0 0 #153A48,
      10004px 0 0 0 #153A48;
  }
  83.333% {
    box-shadow:
      9984px 0 0 0 #153A48,
      9994px 0 0 0 #153A48,
      10004px -10px 0 0 #153A48;
  }
  100% {
    box-shadow:
      9984px 0 0 0 #153A48,
      9994px 0 0 0 #153A48,
      10004px 0 0 0 #153A48;
  }

`;

export const Typing = styled.div`
  position: relative;
  margin: 8px 10px 0 20px;
  left: -9999px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  animation: ${DotTypingAnimation} 1.5s infinite linear;
`;

export const AnswerContainer = styled.div`
  margin: 18px 0;
  display: flex;
  flex-direction: column;
`;

export const Answer = styled.div`
  text-align: justify;
  border: 2px solid #eae3e3;
  border-radius: 32px;
  border-top-right-radius: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  flex-direction: column;
`;

export const RelativeDate = styled.div<{ $right?: boolean }>`
  font-size: 11px;

  ${({ $right }) => $right && "text-align: right;"}
`;

export const Duration = styled.div`
  font-size: 11px;
`;
export const FollowUpQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;

  h2 {
    font-size: 13px;
    font-weight: 500 !important;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

export const FollowUpQuestion = styled.div<{
  $loading: boolean;
}>`
  color: #013c49;
  font-size: 12px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  transition: all 0.3s linear;

  &:hover {
    cursor: pointer;
    opacity: ${({ $loading }) => ($loading ? 1 : 0.5)};
    border: "#003A47";
  }
`;

export const AnswerText = styled.div`
  padding: 20px;
  ul {
    list-style-type: disc;
    list-style-position: inside; /* This makes the bullet points appear inside the padding */
    padding-left: 0; /* This removes the default padding */
    margin-left: 20px; /* This can add some space to better align the text if needed */
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: 0;
    margin-left: 20px;
  }

  ol li p,
  ul li p {
    display: inline;
    margin: 0;
  }

  & p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h3 {
    font-weight: bold;
  }

  h4 {
    font-weight: bold;
  }

  h5 {
    font-weight: bold;
  }
`;
