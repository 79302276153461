import { Trans } from "@lingui/macro";
import "./PredictionLoader.css"; // Make sure your CSS file is correctly imported

const PredictionLoader = () => {
  return (
    <div className="loadingContainer">
      <div className="loadingDots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <div className="loadingText">
        <Trans>Thank you for your patience!</Trans>
        <br />
        <Trans>Your prediction is currently being processed and it might take a few minutes.</Trans>
        <br />
        <Trans>We'll let you know as soon as it's ready. 😊</Trans>
      </div>
    </div>
  );
};

export default PredictionLoader;
