import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ChatBubbleIcon from "@mui/icons-material/Chat";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { CHAT_VIEW, FAVORITE_VIEW } from "../../../Constants/constants";
import { useUI } from "../../../context/ui";

import { Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { fetchDiagramUrl } from "../../../services/firebase/functions";
import * as s from "./ToggleButtons.s";
import { Trans } from "@lingui/macro";

export const ToggleButtons = () => {
  const { view, setView } = useUI();
  const { loading } = useChatData();
  const { userDocument } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [diagramSrc, setDiagramSrc] = useState<string | null>(null);

  useEffect(() => {
    const getDiagramUrl = async () => {
      const url = await fetchDiagramUrl(userDocument?.organization);
      setDiagramSrc(url);
    };
    getDiagramUrl();
  }, [userDocument]);

  const handleToggle = (event, tView) => {
    if (tView === null || tView === "DATA_EXPLORE") return;
    setView(tView);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    open ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <s.ToggleButtonGroup
        data-onboarding="tab-view-buttons"
        value={view}
        exclusive
        onChange={handleToggle}
        aria-label="text alignment"
        disabled={loading}
      >
        {diagramSrc && !isMobileOnly && (
          <s.ToggleButton value="DATA_EXPLORE" onClick={handleClick}>
            <AutoStoriesIcon />
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </s.ToggleButton>
        )}

        <s.ToggleButton value={CHAT_VIEW} aria-label="chat view">
          <ChatBubbleIcon />
          <Trans>Chat</Trans>
        </s.ToggleButton>
        <s.ToggleButton value={FAVORITE_VIEW} aria-label="pin board">
          <DashboardIcon />
          <Trans>Pinboard</Trans>
        </s.ToggleButton>
      </s.ToggleButtonGroup>

      <Popover
        id={"data-explore-popover"}
        open={open}
        anchorEl={anchorEl}
        disablePortal={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ pointerEvents: "none", width: "40vw" }}
        PaperProps={{
          style: { width: "80%", maxWidth: "none", height: "80vh" },
        }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Typography sx={{ p: 2, pointerEvents: "auto", width: "100%", height: "100%" }}>
          {" "}
          <iframe
            width="100%"
            height="100%"
            style={{
              boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
              borderRadius: "15px",
            }}
            allowTransparency={true}
            allowFullScreen={true}
            scrolling="no"
            title="Embedded DrawSQL IFrame"
            frameBorder="0"
            src={diagramSrc}
          ></iframe>
        </Typography>
      </Popover>
    </>
  );
};
