import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
`;

export const Image = styled.img``;

export const MessageWrapper = styled.div`
  position: relative;
  max-width: 400px;
  max-height: 450px;
  padding: 20px;

  display: flex;
  flex-direction: column;

  background-color: #fff;
  font-size: 16px;
  text-align: start;

  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);

  &::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;

    left: -24px;
    bottom: 60%;
    z-index: 2;
  }
`;
export const MessageContent = styled.div`
  max-height: 460px;
  overflow-y: auto;
`;

export const Link = styled.a`
  color: #003a47;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;
