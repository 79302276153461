import { FormControl } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useAuth } from "../../context";
import { useOrgUsers } from "../../context/users";
import { withSentry } from "../../helpers/wrapper";
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#15394680",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  padding: "2rem",
};

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderRadius: "7px",
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #8B8989",
    fontSize: 16,
    width: "auto",
    paddingLeft: "10px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#153946",
    },
  },
}));

export default function EditModal({ open, setOpen, currUserInspected, setCurrUserInspected }) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  React.useEffect(() => {
    setFirstName(currUserInspected?.firstName);
    setLastName(currUserInspected?.lastName);
  }, [currUserInspected]);

  const { fetchUsersInOrganization } = useOrgUsers();
  const { updateUserField } = useAuth();

  const handleClose = () => setOpen(false);

  const handleUpdate = withSentry(async () => {
    if (firstName !== "" && firstName !== undefined && firstName !== currUserInspected?.firstName) {
      await updateUserField("firstName", firstName);
    }
    if (lastName !== "" && lastName !== undefined && lastName !== currUserInspected?.lastName) {
      await updateUserField("lastName", lastName);
    }
    fetchUsersInOrganization();
    handleClose();
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        components={{
          Backdrop: CustomBackdrop,
        }}
      >
        <Box sx={style}>
          <Stack direction="column">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#153946",
                fontSize: "1.5625rem",
                fontWeight: 800,
              }}
            >
              <Trans>Edit User Information</Trans>
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{
                fontSize: "0.9375rem",
                fontWeight: 300,
                color: "#153946",
              }}
            >
              <Trans>
                You can edit user name at this time. If this email is incorrect, please delete the
                user and send a new invite.
              </Trans>
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{
                fontSize: "0.9375rem",
                color: "#153946",
                fontWeight: 1000,
              }}
            >
              <Trans>
                User: {`${currUserInspected?.firstName} ${currUserInspected?.lastName}`}
              </Trans>
            </Typography>
            <Stack direction="row" spacing={1}>
              {" "}
              <FormControl variant="standard">
                <CustomInput
                  id="bootstrap-input"
                  placeholder={t`First Name`}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="standard">
                <CustomInput
                  id="bootstrap-input"
                  placeholder={t`Last Name`}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormControl>{" "}
            </Stack>

            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{
                fontSize: "0.8rem",
                fontWeight: 300,
                fontStyle: "italic",
              }}
            >
              <Trans>Coming soon: Role-based controls and Google Classroom integration.</Trans>
            </Typography>
            <Stack direction="row" justifyContent={"center"} spacing={4}>
              <Button
                variant="outlined"
                sx={{
                  border: "2px solid #8B8989",
                  color: "#8B8989",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  minWidth: "5.75rem",
                }}
                onClick={handleClose}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  background: "#4EB5CC",
                  minWidth: "5.75rem",
                }}
                onClick={handleUpdate}
              >
                <Trans>Confirm</Trans>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
