import styled from "styled-components";

export const FavoritesContent = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
  gap: 24px;
  padding: 12px 0;

  @media screen and (min-width: 768px) {
    padding: 24px;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
`;
