import { Paper, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";

import {
  BAR_CHART,
  LINE_CHART,
  PIE_CHART,
  SAVANT_LINE,
  SCATTER_CHART,
  SQL_CHART,
  TABLE,
} from "../../../Constants/constants";
import { usePinboard } from "../../../context/pinboard";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { Chart } from "../../../helpers/Chart";
import { withSentry } from "../../../helpers/wrapper";
import { useResize } from "../../../hooks/useResize";
import ChartMenu from "../../Chart/ChartMenu";
import { ChartTitle } from "../../Chart/ChartTitle";
import { SqlDisplay } from "../../Chart/SqlDisplay/SqlDisplay";
import { BarChart } from "../../Charts/BarChart/BarChart";
import { DataTable } from "../../Charts/DataTable/DataTable";
import { LineChart } from "../../Charts/LineChart/LineChart";
import { PieChart } from "../../Charts/PieChart/PieChart";
import PredictionLineChart from "../../Charts/PredictionLineChart/PredictionLineChart";
import { ScatterChart } from "../../Charts/ScatterChart/ScatterChart";
import * as s from "./FavoriteChart.s";
import MemoizedPieChartH from "../../Charts/PieChart/PieChartH";
import { useAuth } from "../../../context";
import { useSwrQuery } from "../../../hooks/useSwrQuery";
import MemoizedBarChartH from "../../Charts/BarChart/BarChartH";
import MemoizedLineChartH from "../../Charts/LineChart/LineChartH";
import MemoizedScatterChartH from "../../Charts/ScatterChart/ScatterChartH";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface FavoriteChartProps {
  pin: Pin;
  handleOpenModal: () => void;
}

export const FavoriteChart: FC<FavoriteChartProps> = ({ pin, handleOpenModal }) => {
  const { _ } = useLingui();
  const { unpinFromBoard, currBoardId } = usePinboard();
  const { userDocument } = useAuth();
  const [chartType, setChartType] = useState(TABLE);
  const [selectedColumns, setSelectedColumns] = useState(pin.selectedColumns || {});
  const { allData, isError, isLoading, totalRows } = useSwrQuery({
    sql_query: pin?.sql,
    doc_id: pin?.id,
  });

  const handleRemove = withSentry(async () => {
    if (!window.confirm(_(msg`Are you sure you want to remove this pin?`))) return;

    await unpinFromBoard(pin?.id, currBoardId);
  });

  const { componentRef, width: componentWidth } = useResize();
  const height = 300 - 10;
  const width = componentWidth - 10;

  useEffect(() => {
    if (pin?.type === "PREDICTION") {
      setChartType(SAVANT_LINE);
    } else {
      setChartType(pin?.chartConfig?.suggestion || TABLE);
    }
  }, [pin]);

  return (
    <s.FavoriteChart>
      <Paper elevation={3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ChartTitle results={pin} docId={pin?.id} isFavView={true} />
          <ChartMenu
            results={pin}
            handleRemove={handleRemove}
            setOpenPinboardModal={() => {}}
            handleOpenModal={handleOpenModal}
          />
        </Stack>

        <Paper
          ref={componentRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fffff",
            padding: "5px",
          }}
          elevation={0}
        >
          <div>
            {chartType === SAVANT_LINE && (
              <PredictionLineChart result={pin} height={height} width={width} />
            )}
            {chartType === TABLE && (
              <DataTable
                result={pin}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                height={height}
                selectable={false}
              />
            )}
            {chartType === PIE_CHART && (
              <MemoizedPieChartH
                data={allData}
                height={height}
                width={width}
                chartConfig={pin?.chartConfig}
                loading={isLoading}
                error={isError}
                totalRows={totalRows}
                title={pin?.title}
              />
            )}
            {chartType === BAR_CHART && (
              <MemoizedBarChartH
                data={allData}
                height={height}
                width={width}
                chartConfig={pin?.chartConfig}
                loading={isLoading}
                error={isError}
                totalRows={totalRows}
                title={pin?.title}
              />
            )}
            {chartType === LINE_CHART && (
              <MemoizedLineChartH
                data={allData}
                height={height}
                width={width}
                chartConfig={pin?.chartConfig}
                loading={isLoading}
                error={isError}
                totalRows={totalRows}
                title={pin?.title}
              />
            )}
            {chartType === SQL_CHART && (
              <SqlDisplay sql={pin?.sql} originalSql={(pin as any)?.originalSql} height="300px" />
            )}
            {chartType === SCATTER_CHART && (
              <MemoizedScatterChartH
                data={allData}
                height={height}
                width={width}
                chartConfig={pin?.chartConfig}
                loading={isLoading}
                error={isError}
                totalRows={totalRows}
              />
            )}
          </div>
        </Paper>
      </Paper>
    </s.FavoriteChart>
  );
};
