import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box } from "@mui/material";
import { HeaderFieldValues } from "./UserManagement";

import * as s from "./TableHeader.s";

export const TableHeader = ({
  headerLabel,
  sort,
  handleSort,
  sortField,
}: {
  headerLabel: string;
  sort: {
    [key in HeaderFieldValues]: "asc" | "desc";
  };
  handleSort: (sortField: HeaderFieldValues) => void;
  sortField: HeaderFieldValues;
}) => {
  return (
    <>
      <s.ResponsiveHeaderGrid $headerField={sortField} item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            color: "#153946",
            fontWeight: 800,
            fontSize: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => handleSort(sortField)}
          >
            {headerLabel} {sort[sortField] === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Box>
      </s.ResponsiveHeaderGrid>
    </>
  );
};
