import { Box } from "@mui/material";

export const DataTableHeader = ({ column, handleColumnSelect }) => (
  <Box
    data-testid={`column-${column?.headerName}`}
    sx={{ display: "flex", alignItems: "left", width: "100%" }}
    onClick={(e) => handleColumnSelect(e, column)}
  >
    <Box sx={{ flexGrow: 1 }}>{column?.headerName}</Box>
  </Box>
);
