import { ForwardedRef, ReactElement, forwardRef } from "react";

import * as s from "./FullContainer.s";

export const FullContainer = forwardRef(
  (
    {
      children,
      className,
      ...props
    }: {
      children: ReactElement | ReactElement[];
      // Send all other props directly to the container
      [x: string]: any;
    },
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <s.RelativeContainer>
        <s.AbsoluteContainer ref={ref} {...props}>
          {children}
        </s.AbsoluteContainer>
      </s.RelativeContainer>
    );
  }
);
