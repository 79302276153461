import { useLingui } from "@lingui/react";
import { useAuth } from "../../context";
import { withSentry } from "../../helpers/wrapper";
import { useInput } from "../../hooks/useInput";
import { Spinner } from "../Loading/Spinner";

import "./register.css";
import { Trans, msg } from "@lingui/macro";

export const Register = () => {
  const { _ } = useLingui();
  const firstName = useInput("");
  const lastName = useInput("");
  const role = useInput("admin");
  const auth = useAuth();

  const registerUser = withSentry(async (e) => {
    e.preventDefault();
    if (!auth.userDocument) {
      try {
        await auth.createNewDocument(firstName?.value, lastName?.value, role?.value);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  });

  return (
    <section className="authPage">
      <div className="flex flex-col items-center justify-center">
        <div className="authFormContainer">
          <div className="authForm">
            <h1>doowii</h1>
            <h1 className="primaryTitle">
              <Trans>Your information</Trans>
            </h1>
            <form className="authFormElements" onSubmit={(e) => registerUser(e)}>
              <div className="inputRow">
                <div className="inputGroup">
                  <label htmlFor="firstName" className="inputLabel">
                    <Trans>First Name</Trans>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="inputField"
                    placeholder={_(msg`First Name`)}
                    required
                    {...firstName}
                  />
                </div>
                <div className="inputGroup">
                  <label htmlFor="lastName" className="inputLabel">
                    <Trans>Last Name</Trans>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="inputField"
                    placeholder={_(msg`Last Name`)}
                    required
                    {...lastName}
                  />
                </div>
              </div>
              <div className="inputRow">
                <div className="inputGroup">
                  <label htmlFor="role" className="inputLabel">
                    <Trans>Role</Trans>
                  </label>
                  <select name="role" id="role" className="inputField" {...role}>
                    <option value="admin">
                      <Trans>Admin</Trans>
                    </option>
                    <option value="teacher" disabled>
                      <Trans>Teacher</Trans>
                    </option>
                  </select>
                </div>
              </div>
              <button type="submit" disabled={auth?.isLoading} className="primaryBtn">
                {auth?.isLoading ? <Spinner /> : _(msg`Sign Up`)}
              </button>
            </form>
            <div className="privacy">
              <Trans>By signing up, you have read and agree to Doowii's</Trans>{" "}
              <a
                href="https://www.doowii.io/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Terms of Service</Trans>
              </a>{" "}
              <Trans>and</Trans>{" "}
              <a href="https://www.doowii.io/privacy" target="_blank" rel="noopener noreferrer">
                <Trans>Privacy Policy</Trans>
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
