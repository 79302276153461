import {
  Button,
  CloseButton,
  Flex,
  Heading,
  IconArrowOpenDownLine,
  IconArrowOpenEndLine,
  IconButton,
  IconDownloadLine,
  IconStatsLine,
  IconTrashLine,
  Menu,
  Modal,
  Text,
  Tooltip,
  Tray,
  TruncateText,
} from "@instructure/ui";
import { DateTime } from "luxon";
import React, { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

import { SQL_CHART, TABLE } from "../../../../../../Constants/constants";
import { useAuth } from "../../../../../../context";
import { useChatData } from "../../../../../../context/chat";
import { usePinboard } from "../../../../../../context/pinboard";
import { availableChartsForCanvas } from "../../../../../../helpers";
import { downloadCSV } from "../../../../../../helpers/downloadCSV";
import { firebaseTimestampToLuxon } from "../../../../../../helpers/time";
import { withSentry } from "../../../../../../helpers/wrapper";
import useBreakpoint from "../../../../../../hooks/useBreakpoint";
import { ChartType } from "../../../../../types";
import { ChartContent } from "../../../../ChartContent/ChartContent";
import {
  ChartSelector,
  allCharts,
  getOptionTooltip,
} from "../../../../ChartSelector/ChartSelector";
import { renderChartIcon } from "../../../../ChartSelector/helpers";
import { SQLEditor } from "../../../../ChatTab/ResultArea/Whiteboard/SQLTray/SQLEditor";
import { SQLTray } from "../../../../ChatTab/ResultArea/Whiteboard/SQLTray/SQLTray";
import { IconSQL } from "../../../../ChatTab/ResultArea/Whiteboard/WhiteboardHeader/DropdownMenu/IconSQL";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";
import { ParentDocTypeEnum } from "../../../../../../api/retriever.i";

const getRelativeDateAndTime = (luxonTimestamp: DateTime = DateTime.now()): string => {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const time = luxonTimestamp.toFormat("hh:mm a");

  return `${luxonTimestamp.toFormat("D")} @ ${time} ${luxonTimestamp.toFormat("ZZZZ")}`;
};

interface FullScreenPinProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  chart: any; // TODO: Add type when context is fixed
  setDeleteChartModalOpen: (open: boolean) => void;
}

export const PinFullScreenView: FC<FullScreenPinProps> = ({
  open,
  setOpen,
  chart,
  setDeleteChartModalOpen,
}) => {
  const { _ } = useLingui();
  const [charts, setCharts] = useState<ChartType[]>([]);
  const [pinChartType, setPinChartType] = useState<any>(TABLE as ChartType);
  const [pinSelectedColumns, setPinSelectedColumns] = useState({});
  const [chatSummary, setChatSummary] = useState(_(msg`Loading...`));
  const [totalRows, setTotalRows] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [isTitleTruncated, setIsTitleTruncated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [pageData, setPageData] = useState({});

  const { currBoardId, updatePinVisualization } = usePinboard();
  const { userDocument, featureFlags } = useAuth();
  const { fetchSummaryFromPin } = useChatData();

  const whiteboardRef = useRef(null);

  // Table is flickering when closing the modal
  const [closing, setClosing] = useState(false);

  const clear = () => {
    setPinChartType(TABLE as ChartType);
    setPinSelectedColumns({});
    setHeight(0);
    setWidth(0);
  };

  const handleClose = () => {
    setClosing(true);
    setOpen(false);
  };

  useEffect(() => {
    if (open) setClosing(false);
  }, [open]);

  useEffect(() => {
    setPinSelectedColumns(chart?.selectedColumns ?? {});
  }, [chart, chart?.selectedColumns]);

  useEffect(() => {
    if (whiteboardRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!closing) {
          for (const entry of entries) {
            setHeight(entry.contentRect.height);
            setWidth(entry.contentRect.width);
          }
        }
      });

      resizeObserver.observe(whiteboardRef.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [whiteboardRef.current]);

  useEffect(() => {
    const fetchData = withSentry(async () => {
      const summary = await (fetchSummaryFromPin as any)(chart);

      setChatSummary(summary);
    });
    if (open && chart) {
      fetchData();
    }
  }, [open, chart]);

  useEffect(() => {
    return () => clear();
  }, [open]);

  useEffect(() => {
    const charts = availableChartsForCanvas(pinSelectedColumns);
    setCharts(charts as ChartType[]);
  }, [pinSelectedColumns]);

  const isDirty = useMemo(() => {
    if (pinChartType === SQL_CHART) return false;

    const orderedSelectedColumns = Object.keys(pinSelectedColumns).sort().toString();
    const orderedPinSelectedColumns = Object.keys(chart?.selectedColumns ?? {})
      .sort()
      .toString();

    if (orderedSelectedColumns !== orderedPinSelectedColumns) {
      if (chart?.visualisation === TABLE && pinChartType === TABLE) return false;

      return true;
    }

    if (chart?.visualisation !== pinChartType) return true;

    return false;
  }, [pinSelectedColumns, pinChartType]);

  const updatePin = async () => {
    if (!chart) return;

    setUpdating(true);

    await updatePinVisualization(currBoardId, chart.id, pinSelectedColumns, pinChartType as any);

    setUpdating(false);
    handleClose();
  };

  const handleDownloadCSV = withSentry(async () => {
    await downloadCSV({
      doc_id: chart?.id,
      parent_doc_id: currBoardId,
      parent_doc_type: ParentDocTypeEnum.PINBOARD,
      filename: `${chart?.title}.csv`,
    });
  });

  const [pinnedOn, lastUpdated] = useMemo(() => {
    const createdAtLuxon = firebaseTimestampToLuxon(chart?.created_at);
    const createdAtRelative = getRelativeDateAndTime(createdAtLuxon);

    const updatedAtLuxon = firebaseTimestampToLuxon(chart?.last_updated);
    const updatedAtRelative = getRelativeDateAndTime(updatedAtLuxon);

    return [createdAtRelative, updatedAtRelative];
  }, [chart?.created_at, chart?.last_updated]);

  useEffect(() => {
    setPinChartType(chart?.visualisation ?? TABLE);
  }, [pageData]);

  const renderHeader = () => (
    <Heading>
      <TruncateText maxLines={3} onUpdate={(isTruncated) => setIsTitleTruncated(isTruncated)}>
        {chart?.title}
      </TruncateText>
    </Heading>
  );

  return (
    <PinViewContainer isOpen={open} handleClose={handleClose}>
      <div>
        <CloseButton
          placement="end"
          offset="small"
          size="medium"
          screenReaderLabel={_(msg`Close`)}
          onClick={handleClose}
        />
        {isTitleTruncated ? (
          <Tooltip
            renderTip={chart?.title}
            constrain="parent"
            mountNode={() => document.getElementById("modal-container")}
          >
            {renderHeader()}
          </Tooltip>
        ) : (
          renderHeader()
        )}
      </div>
      <div>
        <div className="flex flex-col justify-between md:flex-row">
          <div className="mb-4 md:mb-0">
            <Text weight="bold">
              <Trans>Summary</Trans>
            </Text>
            <div className="max-w-[690px]">
              <div className="mt-3">
                <Text>{chatSummary}</Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start justify-between gap-3 chat-desktop:items-end">
            <div className="flex flex-col items-start text-start chat-desktop:items-end chat-desktop:text-end">
              <span className="hidden chat-desktop:inline">
                <Text>
                  <Trans>Pinned On {pinnedOn}</Trans>
                </Text>
              </span>
              <Text>
                <Trans>Last Updated On {lastUpdated}</Trans>
              </Text>
            </div>
            <ActionsOptionsContainer
              charts={charts}
              chartData={chart}
              totalRows={totalRows}
              pinChartType={pinChartType}
              setPinChartType={setPinChartType}
              handleDownloadCSV={handleDownloadCSV}
              setDeleteChartModalOpen={setDeleteChartModalOpen}
            />
          </div>
        </div>

        <div ref={whiteboardRef} style={{ minHeight: "45vh", height: "45vh" }} className="mt-5">
          <ChartContent
            chartType={pinChartType}
            height={height}
            width={width}
            results={chart}
            selectedColumns={pinSelectedColumns}
            setSelectedColumns={setPinSelectedColumns}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-2">
        <div className="mr-3">
          <Button color="primary" disabled={!isDirty || updating} onClick={updatePin}>
            <Trans>Save</Trans>
          </Button>
        </div>
        <Button onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
      </div>
    </PinViewContainer>
  );
};

type PinViewContainerProps = { isOpen: boolean; handleClose: () => void };
const PinViewContainer: React.FC<PropsWithChildren<PinViewContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { isChatDesktop } = useBreakpoint();
  const Container = isChatDesktop ? PinFullViewModal : PinFullViewDrawer;

  return (
    <Container isOpen={isOpen} handleClose={handleClose}>
      {children}
    </Container>
  );
};

const PinFullViewDrawer: React.FC<PropsWithChildren<PinViewContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { _ } = useLingui();
  const [header, body, footer] = React.Children.toArray(children);
  return (
    <Tray
      label={_(msg`Pinboard full drawer`)}
      open={isOpen}
      onDismiss={handleClose}
      size="large"
      placement="bottom"
    >
      <div className="h-screen pt-16">
        <div className="relative flex h-full flex-col justify-between gap-6">
          <div className="p-5">
            <div className="mr-5 mt-[-6px] block">{header}</div>
            <div className="my-6"></div>
            <div>{body}</div>
          </div>
          <div className="flex justify-end bg-[#F5F5F5] p-4">{footer}</div>
        </div>
      </div>
    </Tray>
  );
};

const PinFullViewModal: React.FC<PropsWithChildren<PinViewContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { _ } = useLingui();
  const [header, body, footer] = React.Children.toArray(children);
  return (
    <Modal open={isOpen} onClose={handleClose} label={_(msg`Pinboard full screen Modal`)}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

type ActionsOptionsContainerProps = {
  chartData: any;
  charts: ChartType[];
  totalRows: number;
  pinChartType: any;
  setPinChartType: React.Dispatch<any>;
  handleDownloadCSV: () => Promise<void>;
  setDeleteChartModalOpen: (open: boolean) => void;
};
const ActionsOptionsContainer: React.FC<ActionsOptionsContainerProps> = ({
  charts,
  chartData,
  totalRows,
  pinChartType,
  setPinChartType,
  handleDownloadCSV,
  setDeleteChartModalOpen,
}) => {
  const { _ } = useLingui();
  const { isChatDesktop } = useBreakpoint();

  return isChatDesktop ? (
    <div className="flex items-center gap-1">
      <ChartSelector charts={charts} setChartType={setPinChartType} chartType={pinChartType} />
      <Tooltip renderTip={_(msg`Download data as CSV`)} placement="top">
        <IconButton
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Download data as CSV`)}
          onClick={handleDownloadCSV as any}
        >
          <IconDownloadLine />
        </IconButton>
      </Tooltip>
      |
      <Tooltip renderTip={_(msg`Delete chart`)} placement="top">
        <IconButton
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Delete chart`)}
          onClick={() => setDeleteChartModalOpen(true)}
        >
          <IconTrashLine />
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    <ActionsDropdown
      chartData={chartData}
      charts={charts}
      totalRows={totalRows}
      pinChartType={pinChartType}
      setPinChartType={setPinChartType}
      handleDownloadCSV={handleDownloadCSV}
      setDeleteChartModalOpen={setDeleteChartModalOpen}
    />
  );
};

const ActionsDropdown: React.FC<ActionsOptionsContainerProps> = ({
  charts,
  chartData,
  totalRows,
  pinChartType,
  setPinChartType,
  handleDownloadCSV,
  setDeleteChartModalOpen,
}) => {
  const { _ } = useLingui();
  const [showMenu, setShowMenu] = useState(false);
  const [showChartOptions, setShowChartOptions] = useState(false);
  const [sqlTrayOpen, setSqlTrayOpen] = useState(false);

  return (
    <div className="w-full">
      <Menu
        show={showMenu}
        onToggle={(show) => setShowMenu(show)}
        placement="bottom stretch"
        withArrow={false}
        offsetY={-38}
        trigger={
          <Button display="block" color="primary-inverse">
            <div className="flex items-center justify-between">
              <Trans>Actions</Trans>
              <IconArrowOpenDownLine />
            </div>
          </Button>
        }
        shouldHideOnSelect={false}
        themeOverride={{ maxWidth: "auto" }}
      >
        <Menu.Item onClick={() => setShowChartOptions((e) => !e)}>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <IconStatsLine />
              <Trans>Change Chart</Trans>
            </div>
            <IconArrowOpenEndLine />
          </div>
        </Menu.Item>
        {showChartOptions &&
          allCharts.map((chart) => {
            const isDisabled = !charts.includes(chart);
            return (
              <Menu.Item
                value={chart}
                onClick={() => {
                  if (!isDisabled) {
                    setPinChartType(chart);
                    setShowMenu(false);
                    setShowChartOptions(false);
                  }
                }}
                key={chart}
                themeOverride={{
                  labelColor: isDisabled ? "#969da2" : "#2D3B45",
                  activeBackground: "#fff",
                  activeLabelColor: isDisabled ? "#969da2" : "#2D3B45",
                }}
              >
                <Tooltip renderTip={_(getOptionTooltip(charts, chart))}>
                  <div className="flex items-center gap-3 pl-8">
                    {renderChartIcon(chart, pinChartType)}
                    {chart}
                  </div>
                </Tooltip>
              </Menu.Item>
            );
          })}
        <Menu.Item
          onSelect={() => {
            setSqlTrayOpen(true);
            setShowMenu(false);
            setShowChartOptions(false);
          }}
        >
          <Flex alignItems="center" gap="small">
            <IconSQL />
            <Trans>Show SQL</Trans>
          </Flex>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleDownloadCSV();
            setShowMenu(false);
            setShowChartOptions(false);
          }}
        >
          <div className="flex items-center gap-3">
            <IconDownloadLine />
            <Text>
              <Trans>Download</Trans>
            </Text>
          </div>
        </Menu.Item>
        <Menu.Separator />
        <Menu.Item onClick={() => setDeleteChartModalOpen(true)}>
          <div className="flex items-center gap-3">
            <IconTrashLine />
            <Text>
              <Trans>Delete from pinboard</Trans>
            </Text>
          </div>
        </Menu.Item>
      </Menu>
      <SQLTray results={chartData} sqlTrayOpen={sqlTrayOpen} setSqlTrayOpen={setSqlTrayOpen} />
    </div>
  );
};
