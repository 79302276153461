import SendIcon from "@mui/icons-material/Send";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useEffect, useState } from "react";

import { QUESTIONS } from "../../Constants/constants";
import { useChatData } from "../../context/chat";
import { useUI } from "../../context/ui";
import { showWhiteBoard } from "../../helpers";
import { getRecentMessage } from "../../helpers/getRecentMessage";
import { Doowii } from "../../search/Doowii";
import * as s from "./SearchBar.s";
import { Suggestions } from "./Suggestions/Suggestions";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { QueryStatsOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { SAVANT } from "../../Constants/features";
import { useAuth } from "../../context";
import { ViewEnum } from "../../context/ui/ui.i";
import { Analytics } from "../../services/analytics/Analytics";
import ModelSelector from "../Navbar/ModelSelector";
import TipsModal from "./TipsModal/TipsModal";
import VoiceToText from "./VoiceToText/VoiceToText";

interface SearchBarProps {
  doowii: Doowii;
  query: string;
  setQuery: (query: string) => void;
  $standAlone?: boolean;
}

export const SearchBar: FC<SearchBarProps> = ({ doowii, query, setQuery, $standAlone }) => {
  const { _ } = useLingui();
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);
  const [qIndex, setQIndex] = useState(0);
  const { featureFlags } = useAuth();

  const { model } = useUI();

  const [tipsModalOpen, setTipsModalOpen] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);

  const { allResults, answer, currentResult: results, loading } = useChatData();
  const { dynamicCache } = useChatData();

  useEffect(() => {
    let timeout;

    if (tipsModalOpen) {
      Analytics.track("Tips modal opened");

      timeout = setTimeout(() => {
        Analytics.track("Tips modal opened for more than 5 seconds");
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [tipsModalOpen]);

  useEffect(() => {
    if (clearSearchInput) {
      setQuery("");
    }
  }, [clearSearchInput]);

  useEffect(() => {
    let stream_questions = QUESTIONS.map((question) => _(question));

    const pending = !dynamicCache || dynamicCache.length === 0;
    if (!pending) {
      stream_questions = dynamicCache.map((cache) => cache);
    }

    if (qIndex > stream_questions.length - 1) {
      setText("");
      setQIndex(0);
      setIndex(0);

      return;
    }

    if (index < stream_questions[qIndex].length) {
      setTimeout(() => {
        setText(text + stream_questions[qIndex][index]);
        setIndex(index + 1);
      }, 40);

      return;
    }

    if (index === stream_questions[qIndex].length) {
      setTimeout(() => {
        setIndex(0);
        setText("");
        setQIndex(qIndex + 1);
      }, 2500);

      return;
    }
  }, [index, qIndex, dynamicCache]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    if (query) {
      doowii.chat({
        query,
        model,
        index: allResults.length,
        recent_messages: getRecentMessage({ allResults, answer }),
      });
      setQuery("");
      setClearSearchInput(!clearSearchInput);
    }
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const generateQuestion = () => {
    Analytics.track("Generate question");
    let randomIndex = Math.floor(Math.random() * dynamicCache.length);
    setQuery(dynamicCache[randomIndex]);
  };

  const searchInputClassName = `search-input${
    (results as any)?.data?.length > 0 ? _(msg` has-results`) : ""
  }`;

  const showModelSelector = showWhiteBoard(allResults) && featureFlags?.includes(SAVANT);
  const isInitialView = !showWhiteBoard(allResults) && !loading;

  return (
    <>
      <Stack direction={"row"} justifyContent="center" alignItems="center" gap={2} mt={1}>
        {showModelSelector && <ModelSelector isBottom={true} />}

        <s.SearchBar
          className="SearchBar"
          onSubmit={handleSubmit}
          data-onboarding="initial-search"
          $standAlone={$standAlone}
        >
          <input
            type="text"
            placeholder={text}
            className={searchInputClassName}
            value={query}
            onChange={handleInputChange}
          />
          <VoiceToText setQuery={setQuery} loading={loading} />
          {!loading ? (
            <button type="submit">
              {model === ViewEnum.QUERY_MODEL ? <SendIcon /> : <QueryStatsOutlined />}
            </button>
          ) : (
            <CircularProgress size={17} sx={{ color: "#003A47" }} />
          )}
        </s.SearchBar>
      </Stack>

      {dynamicCache?.length !== 0 && !isInitialView && (
        <s.HelpersContainer>
          <s.Helpers onClick={() => setTipsModalOpen(true)}>
            <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
            <Trans>Tips on effective prompt composition</Trans>
          </s.Helpers>

          <s.Helpers onClick={generateQuestion}>
            {" "}
            {model === ViewEnum.QUERY_MODEL
              ? _(msg`Generate a question for me!`)
              : _(msg`Generate a prediction for me!`)}
          </s.Helpers>
        </s.HelpersContainer>
      )}

      {isInitialView && (
        <s.Helper onClick={() => setTipsModalOpen(true)}>
          <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
          <Trans>Tips on effective prompt composition</Trans>
        </s.Helper>
      )}

      {isInitialView && (
        <Suggestions
          doowii={doowii}
          setQuery={setQuery}
          clearSearchInput={clearSearchInput}
          setClearSearchInput={setClearSearchInput}
        />
      )}

      <TipsModal open={tipsModalOpen} setOpen={setTipsModalOpen} />
    </>
  );
};
