import { useAuth } from "../../../context/auth";
import Notifications from "./Notifications/Notifications";
import { SettingsMenu } from "./SettingsMenu/SettingsMenu";
import * as s from "./UserProfile.s";

export const UserProfile = () => {
  const { userDocument } = useAuth();

  const { firstName, lastName } = userDocument;
  const getInitials = (firstName = "", lastName = "") => {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  return (
    <s.UserProfile>
      <s.ProfilePicture>{getInitials(firstName, lastName)}</s.ProfilePicture>
      <s.UserName>{`${userDocument?.firstName} ${userDocument?.lastName}`}</s.UserName>
      <Notifications />
      <SettingsMenu />
    </s.UserProfile>
  );
};
