import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "firebase/firestore";

export default function InstructionDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"800px"} // md is approximately 960px wide
      fullScreen={fullScreen}
    >
      <DialogContent className="dialog-content" data-testid="dialog-content">
        <iframe
          title="Embedded HTML"
          src="/data_explore/doowii_data_explore.html"
          style={{ width: "100%", height: "95vh", border: "none" }}
        />
      </DialogContent>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
