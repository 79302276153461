import MUIButton from "@mui/material/Button";
import s from "styled-components";


export const Button = s(MUIButton)`
  background: #F5CC61 !important;
  text-transform: capitalize !important;
  padding: 14.25px 0 !important;
  line-height: 1 !important;
  width: 20% !important;
  color: #153947 !important;

  &:hover {
    background: #4EB5CC !important;
    color: white !important;
  }


  ${({ disabled }) =>
    disabled &&
    `
    color: #EBEBEB !important;
    opacity: 0.7 !important;
  `}
`;
