import { Trans } from "@lingui/macro";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Grid } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useAuth } from "../../context";
import { useChatData } from "../../context/chat";
import { useUI } from "../../context/ui";
import { getRecentMessage } from "../../helpers/getRecentMessage";
import { QuestionTypeEnum } from "../../search/Doowii.i";
import * as s from "./Chart.s";

export default function ErrorDisplay({ results, doowii }) {
  const { userDocument } = useAuth();
  const { allResults, answer } = useChatData();
  const { model } = useUI();

  const handleClick = () => {
    if (doowii) {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "RegenerateButtonClicked");
        scope.setTag("user_id", userDocument?.email);
        Sentry.captureMessage(
          `User: ${userDocument?.email} clicked regenerate question: ${results?.query}`
        );
      });
      doowii.chat({
        query: results?.query,
        model: model,
        getRecentMessage: getRecentMessage({ allResults, answer }),
        questionType: QuestionTypeEnum.REGENERATE,
      });
    } else {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "frontend-logic-bug");
        scope.setLevel("fatal");
        Sentry.captureMessage(`User accessing regenerate button without doowii instance.`);
      });
      console.error("doowii instance not found.");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: 0,
        padding: 0,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "90%", width: "80%", margin: "auto" }}
        gap={3}
      >
        <>
          {results?.error?.message ? (
            <h3 style={{ color: "rgb(39,54,70, 0.5)", textAlign: "left", fontSize: "110%" }}>
              {results.error?.message.split(" ").map((word, index) =>
                index === 0 ? (
                  <span
                    key={index}
                    style={{ fontWeight: "bold", color: "rgb(235, 29, 14, 0.8)", fontSize: "150%" }}
                  >
                    {word + " "}
                  </span>
                ) : (
                  <span key={index}>{word + " "}</span>
                )
              )}
            </h3>
          ) : (
            <>
              <h2 style={{ color: "rgb(39,54,70, 0.5)", textAlign: "left" }}>
                <span style={{ color: "rgb(235, 29, 14, 0.8)", fontSize: "130%" }}>
                  <Trans>Oops</Trans>
                </span>
                <Trans>, looks like there is an issue.</Trans>
              </h2>
              <h3 style={{ color: "rgb(39,54,70, 0.5)", textAlign: "left" }}>
                <Trans>Let's fix it! Please press the button below to try again!</Trans>
              </h3>
            </>
          )}
          <s.Button startIcon={<CachedIcon />} onClick={handleClick}>
            <Trans>Regenerate</Trans>
          </s.Button>
        </>
      </Grid>
    </Box>
  );
}
