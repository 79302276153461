import * as echarts from "echarts";
import ecStat from "echarts-stat";
import { useEffect, useRef, useState } from "react";
import { CHART_LIMIT, SCATTER_CHART } from "../../../Constants/constants";
import { fetchChartTransformedData } from "../../../api/retriever";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import { ChartLoadingSpinner } from "../ChartLoadingSpinner/ChartLoadingSpinner";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { ParentDocTypeEnum } from "../../../api/retriever.i";

interface ScatterChartProps {
  data: any[];
  height: number;
  width: number;
  isDemo?: boolean;
  selectedColumns: any;
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  totalRows: number;
}

export const ScatterChart = ({
  data,
  height,
  width,
  isDemo = false,
  selectedColumns,
  docId,
  parentDocId,
  parentDocType,
  totalRows,
}: ScatterChartProps) => {
  const { _ } = useLingui();
  const chartRef = useRef(null);

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transformedData, setTransformedData] = useState<any>({});
  const [xAxisLabel, setXAxisLabel] = useState("");
  const [yAxisLabel, setYAxisLabel] = useState("");

  const transformDataForScatterPlot = (data, selectedColumns) => {
    const keys = Object.keys(selectedColumns).sort((a, b) =>
      selectedColumns[a].type === "timestamp" ? -1 : selectedColumns[b].type === "number" ? 0 : 1
    );
    const additionalKeys = Object.keys(data[0] || {})
      .filter((key) => !keys.includes(key))
      .slice(0, 3);
    const types = keys.map((key) => selectedColumns[key].type);
    const isTimestampInvolved = types.includes("timestamp");
    let xAxisLabel = null;
    let yAxisLabel = null;

    const pointData = data.map((item) => {
      return keys.map((key) => {
        if (selectedColumns[key].type === "timestamp") {
          xAxisLabel = key;
          return new Date(item[key]).getTime();
        } else if (selectedColumns[key].type === "number") {
          if (xAxisLabel === null && !isTimestampInvolved) {
            xAxisLabel = key;
          } else {
            yAxisLabel = key;
          }
          const value = parseFloat(item[key]);
          return isNaN(value) ? null : value;
        } else {
          return item[key];
        }
      });
    });
    const toolTipData = data.map((item) => {
      return additionalKeys.map((key) => `${key}: ${item[key]}`).join("<br/>");
    });
    const unfilteredData = { pointData, toolTipData };

    // Filtering indices based on validity of pointData
    const validIndices = pointData.reduce((acc, point, index) => {
      if (!point.includes(null)) acc.push(index);
      return acc;
    }, []);

    // Applying filtering based on the collected valid indices
    const filteredPointData = validIndices.map((index) => unfilteredData.pointData[index]);
    const filteredToolTipData = validIndices.map((index) => unfilteredData.toolTipData[index]);

    // If timestamp is involved, sort the data based on the timestamp (assuming it's the first value in pointData)
    let transformedData;
    if (isTimestampInvolved) {
      const sortedIndices = validIndices.sort(
        (a, b) => filteredPointData[a][0] - filteredPointData[b][0]
      );

      // Reordering filteredPointData and filteredToolTipData based on sortedIndices
      const sortedPointData = sortedIndices.map((index) => filteredPointData[index]);
      const sortedToolTipData = sortedIndices.map((index) => filteredToolTipData[index]);

      // Updating transformedData with sorted arrays
      transformedData = { pointData: sortedPointData, toolTipData: sortedToolTipData };
    } else {
      // If not sorting by timestamp, just use the filtered data
      transformedData = { pointData: filteredPointData, toolTipData: filteredToolTipData };
    }
    return {
      transformedData,
      xAxisLabel: xAxisLabel,
      yAxisLabel: yAxisLabel,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setHasError(false);
      if (data?.length > 0) {
        setLoading(true);
        if (!isDemo && totalRows > CHART_LIMIT) {
          try {
            const response = await fetchChartTransformedData({
              doc_id: docId,
              parent_doc_id: parentDocId,
              parent_doc_type: parentDocType,
              columns: selectedColumns,
              chart_type: SCATTER_CHART,
            });

            if (response.data) {
              setTransformedData(response.data?.transformedData);
              setXAxisLabel(response.data?.xAxisLabel);
              setYAxisLabel(response.data?.yAxisLabel);
            } else {
              setHasError(true);
            }
          } catch (error) {
            console.error("Error fetching transformed data for scatter plot:", error);
            setHasError(true);
          } finally {
            setLoading(false);
          }
        } else {
          const { transformedData, xAxisLabel, yAxisLabel } = transformDataForScatterPlot(
            data,
            selectedColumns
          );
          setTransformedData(transformedData);
          setXAxisLabel(xAxisLabel);
          setYAxisLabel(yAxisLabel);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [data, selectedColumns, isDemo]);

  useEffect(() => {
    if (transformedData?.pointData?.length > 0) {
      try {
        // @ts-ignore
        echarts.registerTransform(ecStat.transform.regression);

        const xValues = transformedData?.pointData.map((item) => item[0]);
        const yValues = transformedData?.pointData.map((item) => item[1]);
        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);
        const existingInstance = echarts.getInstanceByDom(chartRef.current);

        if (existingInstance) {
          existingInstance.dispose();
        }
        const chart = echarts.init(chartRef.current);

        const options = {
          dataset: [
            {
              source: transformedData.pointData,
            },
            {
              fromDatasetIndex: 0,
              transform: {
                type: "ecStat:regression",
                config: { method: "linear" },
              },
            },
          ],
          grid: {
            left: "5%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },

          tooltip: {
            trigger: "item",
            showDelay: 0,
            hideDelay: 100,
            transitionDuration: 0.2,
            formatter: function (params) {
              const dataIndex = params.dataIndex;
              let additionalInfo = transformedData?.toolTipData[dataIndex];
              additionalInfo = additionalInfo.replace(/id:\s*\d+(<br\/>)?/, "");
              const xValue = params.value[0];
              const yValue = params.value[1];

              // eslint-disable-next-line lingui/no-unlocalized-strings
              return `
              <p style="color: #153947; font-size: 1rem;"><b>Details</b></p>
              <b>${xAxisLabel}</b>: ${xValue}
              <br/>
              <b>${yAxisLabel}</b>: ${yValue}
              <br/>
              ${additionalInfo}
              `;
            },
          },
          xAxis: {
            type: Object.keys(selectedColumns)
              .map((key) => selectedColumns[key].type)
              .includes("timestamp")
              ? "time"
              : "value",
            name: xAxisLabel || "test",
            nameLocation: "middle",
            nameGap: 20,

            min: minX,
            max: maxX,
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
          },
          yAxis: {
            min: minY,
            max: maxY,
            name: yAxisLabel || "test",
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 50,
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
          },
          series: [
            {
              name: _(msg`Data`),
              type: "scatter",
              encode: { x: 0, y: 1 },
              datasetIndex: 0,
            },
            {
              name: _(msg`Line of Best Fit`),
              type: "line",
              smooth: true,
              encode: { x: 0, y: 1 },
              datasetIndex: 1,
              symbolSize: 0.1,
              symbol: "circle",
              label: { show: false },
              lineStyle: {
                color: "#153947",
                width: 2,
                type: "dashed",
              },
            },
          ],
          dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0],
              start: 0,
              end: 100,
            },
            {
              type: "slider",
              xAxisIndex: [0],
              start: 0,
              end: 100,
            },
          ],
        };
        chart.setOption(options);

        return () => chart.dispose();
      } catch (error) {
        console.error("Error setting up scatter plot:", error);
        setHasError(true);
      }
    }
  }, [width, height, transformedData]);

  return (
    <div data-testid="scatter-chart">
      <div ref={chartRef} style={{ width: width || "100%", height: height || "500px" }}></div>
      {loading && <ChartLoadingSpinner />}
      {hasError && <ChartErrorState />}
    </div>
  );
};
