import CreatePinboardIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

import { usePinboard } from "../../context/pinboard";
import { Theme } from "../../styled";
import { DoowiiTooltip } from "../Tooltip/DoowiiTooltip";
import FavoriteBoardCreateModal from "./FavoriteBoardCreateModal";
import FavoriteBoardDeleteModal from "./FavoriteBoardDeleteModal";
import FavoriteBoardEditModal from "./FavoriteBoardEditModal";
import "./Favorites.css";
import * as s from "./Favorites.s";
import { FavoritesContent } from "./FavoritesContent/FavoritesContent";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

export const Favorites = () => {
  const { _ } = useLingui();
  const { boards, currBoardId, setCurrBoardId } = usePinboard();

  const [isPinboardCreateModalOpen, setIsPinboardCreateModalOpen] = useState(false);
  const [isPinboardSelectModalOpen, setIsPinboardSelectModalOpen] = useState(false);
  const [isPinboardEditModalOpen, setIsPinboardEditModalOpen] = useState(false);
  const [isPinboardDeleteModalOpen, setIsPinboardDeleteModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePinboardSelectOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePinboardSelectClose = () => {
    setAnchorEl(null);
  };

  const handlePinboardSelect = (boardId) => {
    setCurrBoardId(boardId);
    handlePinboardSelectClose();
  };

  const handleModalOpen = (openModal) => {
    setAnchorEl(null);
    openModal(true);
  };

  const getPinboardSelectList = () =>
    // @ts-ignore
    Array.from(boards).map(([key, board]) => {
      return (
        <MenuItem
          key={key}
          sx={{
            borderBottom: "2px solid rgba(44, 62, 80, 0.1)",
            backgroundColor: currBoardId === key ? "rgba(44, 62, 80, 0.05)" : "white",
            justifyContent: "space-between",
          }}
          onClick={() => handlePinboardSelect(key)}
        >
          <span className="dropdown-pinboard-select-name">{board.name}</span>
          <IconButton edge="start" aria-label="delete">
            {currBoardId === key ? (
              <RadioButtonCheckedIcon style={{ color: "#003A47" }} />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </IconButton>
        </MenuItem>
      );
    });

  return (
    <s.Favorites>
      <FavoriteBoardCreateModal
        open={isPinboardCreateModalOpen}
        setOpen={setIsPinboardCreateModalOpen}
      />
      <FavoriteBoardEditModal open={isPinboardEditModalOpen} setOpen={setIsPinboardEditModalOpen} />
      <FavoriteBoardDeleteModal
        open={isPinboardDeleteModalOpen}
        setOpen={setIsPinboardDeleteModalOpen}
      />

      <s.FavoritesHeader>
        <div className="flex">
          <Button
            id="pinboard-title-button"
            aria-controls={isPinboardSelectModalOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={isPinboardSelectModalOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handlePinboardSelectOpen}
            endIcon={<ExpandMoreIcon />}
            disabled={!boards.size}
            data-onboarding="select-pinboard-button"
          >
            <h3 id="pinboard-title">
              {boards.size ? (
                boards.get(currBoardId)?.name
              ) : (
                <span>
                  <Trans>No pinboards</Trans>
                </span>
              )}
            </h3>
          </Button>
          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handlePinboardSelectClose}
          >
            <div id="dropdown-pinboard-select">{getPinboardSelectList()}</div>
          </Menu>
          <div>
            {!!boards.size && (
              <DoowiiTooltip title={_(msg`Edit Pinboard name`)} placement="top">
                <IconButton
                  onClick={() => handleModalOpen(setIsPinboardEditModalOpen)}
                  sx={{ color: Theme.core.colors.darkBlue }}
                >
                  <EditIcon />
                </IconButton>
              </DoowiiTooltip>
            )}
            {!!boards.size && (
              <DoowiiTooltip title={_(msg`Delete Pinboard`)} placement="top">
                <IconButton
                  onClick={() => handleModalOpen(setIsPinboardDeleteModalOpen)}
                  sx={{ color: Theme.core.colors.darkBlue }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </DoowiiTooltip>
            )}
          </div>
        </div>
        <s.NewPinboardButton
          data-onboarding="new-pinboard"
          onClick={() => handleModalOpen(setIsPinboardCreateModalOpen)}
        >
          <CreatePinboardIcon />
          <Trans>New pinboard</Trans>
        </s.NewPinboardButton>
      </s.FavoritesHeader>

      <FavoritesContent setIsPinboardCreateModalOpen={setIsPinboardCreateModalOpen} />
    </s.Favorites>
  );
};
