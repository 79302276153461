import DeleteIcon from "@mui/icons-material/Delete";
import { DialogContent, DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { usePinboard } from "../../context/pinboard";
import { withSentry } from "../../helpers/wrapper";
import { Trans } from "@lingui/macro";

interface FavoriteBoardOptionsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function FavoriteBoardOptionsModal({
  open,
  setOpen,
}: FavoriteBoardOptionsModalProps) {
  const { currBoardId, deleteBoard } = usePinboard();

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemovePinboard = withSentry(async () => {
    await deleteBoard(currBoardId);
    handleClose();
  });

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle id="pinboard-modal-title">
          <Trans>Delete Pinboard</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Are you sure you want to delete this pinboard and all of its saved pins? This action
              can not be undone.
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemovePinboard} startIcon={<DeleteIcon />} variant="contained">
            <Trans>Delete</Trans>
          </Button>

          <Button onClick={handleClose}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
