import React from "react";
import { Container, Typography, Button, Stack } from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Trans } from "@lingui/macro";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013B48",
    },
    secondary: {
      main: "#3DA0C3",
    },
    info: {
      main: "#F5CC62",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: "bold",
          color: "#013B48",
        },
        body1: {
          color: "#3DA0C3",
        },
        body2: {
          color: "#F5CC62",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#3DA0C3",
            },
            "&:hover fieldset": {
              borderColor: "#F5CC62",
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F5CC62",
            },
          },
          "& .Mui-disabled": {
            color: "#013B48",
          },
        },
      },
    },
  },
});

export default function ContactDoowii() {
  const handleEmail = () => {
    window.location.href = `mailto:hello@doowii.io?subject=Request Access to Doowii&body=Hi team, I am interested in accessing Doowii and would love to know more about it. Please get in touch!`;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Stack
          spacing={2}
          sx={{ padding: 4, alignItems: "center", justifyContent: "center", height: "100vh" }}
        >
          <Typography variant="h5" textAlign="center">
            <Trans>Thank You for Your Interest in Doowii!</Trans>
          </Typography>
          <Typography variant="body1" textAlign="center">
            <Trans>
              We're excited to have you on board. For personalized access to our products, please
              reach out to us.
            </Trans>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEmail}
            sx={{ mt: 2, textTransform: "capitalize" }} // Adds margin top for spacing
          >
            <Trans>Contact Us at hello@doowii.io</Trans>
          </Button>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}
