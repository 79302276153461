import styled from "styled-components";

export const DataWhiteboard = styled.div`
  display: flex;
  flex: 1;
`;

export const Header = styled.div`
  font-size: 1.4em;
  font-weight: 500;
  color: #2c3e50;
  margin-top: 0;
  text-transform: capitalize;
  background-color: #f5f5f5;
  padding: 10px 10px 0 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  input {
    align-items: baseline;
    color: #2c3e50;
    font-family: "Outfit";
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    margin: 10px;
    -webkit-text-fill-color: #003a47;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SelectorAndButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
