import { CHART_SELECTOR } from "../Constants/features";
import { CanvasOld } from "../canvas-old/components/Canvas";
import { useAuth } from "../context";
import { Canvas } from "./components/Canvas";
export default function UIDecider() {
  const { featureFlags } = useAuth();

  if (featureFlags.includes(CHART_SELECTOR)) {
    return <Canvas />;
  } else {
    return <CanvasOld />;
  }
}
