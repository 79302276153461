import { Link } from "@mui/material";
import * as s from "./EmptyDisplay.s";
import { Trans } from "@lingui/macro";

export const EmptyDisplay = ({ actionLabel, action }) => {
  return (
    <s.EmptyDisplay>
      <h2>
        <Trans>It's looking a bit empty here...</Trans>
      </h2>
      <h3>
        <Trans>Get started by</Trans>
        <Link href="#" onClick={action}>
          {actionLabel}.
        </Link>
      </h3>
    </s.EmptyDisplay>
  );
};
