import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  BAR_CHART,
  DATA_EXPLORE,
  EMPTY_ROWS,
  ERROR,
  LINE_CHART,
  PIE_CHART,
  SAVANT_LINE,
  SCATTER_CHART,
  SQL_CHART,
  TABLE,
} from "../../Constants/constants";

import { savantErrorMessageMap } from "../../Constants/errorMessages";
import { useAuth } from "../../context";
import { useChart } from "../../context/chart";
import { useChatData } from "../../context/chat";
import { useUI } from "../../context/ui";
import { useSwrQuery } from "../../hooks/useSwrQuery";
import { fetchAndUpdateChartConfig } from "../../services/firebase";
import MemoizedBarChartH from "../Charts/BarChart/BarChartH";
import { DataTable } from "../Charts/DataTable/DataTable";
import MemoizedLineChartH from "../Charts/LineChart/LineChartH";
import MemoizedPieChartH from "../Charts/PieChart/PieChartH";
import PredictionLineChart from "../Charts/PredictionLineChart/PredictionLineChart";
import MemoizedScatterChartH from "../Charts/ScatterChart/ScatterChartH";
import DataExplore from "../DataExplore/DataExplore";
import { FullContainer } from "../FullContainer/FullContainer";
import { LoadingIndicator } from "../Loading/LoadingIndicator";
import EmptySqlDisplay from "./EmptySqlDisplay/EmptySqlDisplay";
import ErrorDisplay from "./ErrorDisplay";
import PredictionLoader from "./PredictionLoader/PredictionLoader";
import SavantErrorDisplay from "./SavantErrorDisplay";
import { SqlDisplay } from "./SqlDisplay/SqlDisplay";
import { useAlert } from "../../context/alert";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export default function ChartContent({ chartType, loadingText = "", doowii }) {
  const { _ } = useLingui();
  const {
    loading: chatLoading,
    currentResult,
    dataMap,
    updateResultsFields,
    currentThread,
  } = useChatData();
  const { showAlert } = useAlert();
  const { userDocument } = useAuth();
  const { selectedColumns, setSelectedColumns } = useChart();
  const { chatDrawerOpen } = useUI();
  const { allData, isError, isLoading, totalRows } = useSwrQuery({
    sql_query: currentResult?.sql,
    doc_id: currentResult?.id,
  });

  const showErrorAlert = useRef(true);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [chartConfig, setChartConfig] = useState({});
  const whiteboardRef = useRef(null);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  useEffect(() => {
    showErrorAlert.current = true;
  }, [currentResult?.sql]);

  useEffect(() => {
    let timeout;
    if (isError && showErrorAlert.current) {
      timeout = setTimeout(() => {
        showErrorAlert.current = false;
        showAlert({
          variant: "error",
          message: _(msg`Invalid SQL`),
        });
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isError]);

  useEffect(() => {
    const backwardCompatibilityRoutine = async () => {
      if (currentResult) {
        if (!currentResult.visualisation && currentResult.sql) {
          const chartConfig =
            currentResult.chartConfig ??
            (await fetchAndUpdateChartConfig(
              userDocument?.organization,
              currentThread,
              currentResult?.id,
              currentResult?.query,
              currentResult?.sql
            ));
          updateResultsFields({ chartConfig: chartConfig, visualisation: chartConfig?.suggestion });
        }
      }
    };
    backwardCompatibilityRoutine();
  }, [currentResult]);

  function updateSize() {
    if (whiteboardRef.current) {
      setHeight(whiteboardRef.current.getBoundingClientRect().height);
      setWidth(whiteboardRef.current.getBoundingClientRect().width);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      updateSize();
    }, 200);
  }, [chatDrawerOpen]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize(); // Set initial size

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    const tempConfig = {
      ...currentResult?.chartConfig,
      columns:
        Object.keys(selectedColumns).length > 0
          ? Object.keys(selectedColumns)
          : currentResult?.chartConfig?.columns,
      column_types: currentResult?.chartConfig?.column_types,
    };
    setChartConfig(tempConfig);
  }, [currentResult, selectedColumns]);

  const isDemo = (currentResult as any)?.data?.isDemo;

  useEffect(() => {
    if (chatLoading) {
      setShowProgress(true);
      setProgressValue(0);
      let intervalDuration = 400; // Initial duration

      let interval = setInterval(() => {
        setProgressValue((prevProgress) => {
          if (prevProgress >= 80) {
            intervalDuration = 3500; // Slow down once we hit 80 percent
            clearInterval(interval); // Clear the existing interval
            // Start a new interval with the slower pace
            interval = setInterval(() => {
              setProgressValue((slowProgress) => {
                const nextProgress = slowProgress + 1;
                return nextProgress <= 90 ? nextProgress : slowProgress;
              });
            }, intervalDuration);
          }

          const nextProgress = prevProgress + 1;
          return nextProgress <= 90 ? nextProgress : prevProgress;
        });
      }, intervalDuration);

      return () => clearInterval(interval);
    }
  }, [chatLoading]);

  useEffect(() => {
    if (chatLoading || !showProgress) return;

    if (isLoading) {
      let interval = setInterval(() => {
        setProgressValue((prevProgress) => {
          const nextProgress = prevProgress + (100 - prevProgress) * 0.1; // Dynamically adjust increment
          return nextProgress < 100 ? nextProgress : prevProgress;
        });
      }, 200);

      return () => clearInterval(interval);
    } else if (!isLoading && progressValue < 100) {
      setProgressValue(100);

      setTimeout(() => {
        setShowProgress(false);
      }, 100);
    }
  }, [isLoading, chatLoading, progressValue, isError]);

  return (
    <FullContainer ref={whiteboardRef}>
      {currentResult?.type === "PREDICTION" ? (
        <>
          {currentResult?.status === "processing" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                height: "100%",
              }}
            >
              <PredictionLoader />
            </div>
          )}{" "}
          {currentResult?.status === "success" && chartType === SAVANT_LINE && (
            <PredictionLineChart result={currentResult} height={height} width={width} />
          )}
          {currentResult?.status === "success" && chartType === TABLE && (
            <DataTable
              result={currentResult}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
          )}
          {currentResult?.status === "success" && chartType === SQL_CHART && (
            <SqlDisplay
              sql={currentResult?.sql ?? currentResult?.prediction_sql}
              originalSql={currentResult?.originalSql}
              height={height}
              canEdit={false}
            />
          )}
          {currentResult?.status === "error" && (
            <SavantErrorDisplay errorMessage={savantErrorMessageMap[currentResult?.error?.code]} />
          )}
        </>
      ) : (
        <>
          {showProgress && (
            <LoadingIndicator progressValue={progressValue} loadingText={loadingText} />
          )}
          {!showProgress && !chatLoading && (
            <>
              {chartType === TABLE && (
                <DataTable
                  result={currentResult}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              )}
              {chartType === PIE_CHART && (
                <MemoizedPieChartH
                  data={allData}
                  height={height}
                  width={width}
                  chartConfig={chartConfig}
                  loading={isLoading}
                  error={isError}
                  totalRows={totalRows}
                  title={currentResult?.title}
                  // selectedColumns={selectedColumns}
                  // sql={currentResult?.sql}
                  // totalRows={totalRows}
                />
              )}
              {chartType === BAR_CHART && (
                <MemoizedBarChartH
                  data={allData}
                  height={height}
                  width={width}
                  chartConfig={chartConfig}
                  loading={isLoading}
                  error={isError}
                  totalRows={totalRows}
                  title={currentResult?.title}

                  // isDemo={isDemo}
                  // selectedColumns={selectedColumns}
                  // sql={currentResult?.sql}
                  // sortModel={sortModel}
                />
              )}
              {chartType === LINE_CHART && (
                <MemoizedLineChartH
                  data={allData}
                  height={height}
                  width={width}
                  chartConfig={chartConfig}
                  loading={isLoading}
                  error={isError}
                  totalRows={totalRows}
                  title={currentResult?.title}

                  // isDemo={isDemo}
                  // selectedColumns={selectedColumns}
                  // sql={currentResult?.sql}
                  // sortModel={sortModel}
                />
              )}
              {chartType === SCATTER_CHART && (
                <MemoizedScatterChartH
                  data={allData}
                  height={height}
                  width={width}
                  chartConfig={chartConfig}
                  loading={isLoading}
                  error={isError}
                  totalRows={totalRows}

                  // isDemo={isDemo}
                  // selectedColumns={selectedColumns}
                  // sql={currentResult?.sql}
                />
              )}

              {chartType === SQL_CHART && (
                <SqlDisplay
                  sql={currentResult?.sql}
                  originalSql={currentResult?.originalSql}
                  height={height}
                  canEdit
                />
              )}
              {chartType === DATA_EXPLORE && (
                <DataExplore htmlFilePath={"/MathDemoDataExplore/MathDemoDataExplore.html"} />
              )}
              {chartType === EMPTY_ROWS && <EmptySqlDisplay />}
              {chartType === ERROR && <ErrorDisplay results={currentResult} doowii={doowii} />}
            </>
          )}
        </>
      )}
    </FullContainer>
  );
}
