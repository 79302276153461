import { DeleteOutline } from "@mui/icons-material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Stack, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";

import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { highlightSubstring } from "../../../helpers/utils";
import { withSentry } from "../../../helpers/wrapper";
import { updateTitleInThread } from "../../../services/firebase";
import * as s from "./Tab.s";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

export const Tab = ({ thread, index, deleteThreadClick }) => {
  const { _ } = useLingui();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(thread?.title);

  const { selectThread, currentThread, searchQuery, loading } = useChatData();
  const { userDocument } = useAuth();

  const handleCheckClick = withSentry(async () => {
    await updateTitleInThread(userDocument.organization, thread?.id, value);
    setEdit(false);
  });

  return (
    <div data-testid="tabs">
      <ListItem
        key={`thread-${index}`}
        disablePadding
        secondaryAction={
          currentThread === thread?.id && (
            <Stack direction="row">
              {edit ? (
                <>
                  {" "}
                  <Tooltip title={_(msg`Cancel`)} placement="top">
                    <IconButton edge="end" aria-label="cancel" onClick={() => setEdit(false)}>
                      <CloseIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={_(msg`Save`)} placement="top">
                    <IconButton edge="end" aria-label="check" onClick={handleCheckClick}>
                      <CheckIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title={_(msg`Edit thread title`)} placement="top">
                    <IconButton edge="end" aria-label="edit" onClick={() => setEdit(true)}>
                      <EditOutlinedIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={_(msg`Delete thread`)} placement="top">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteThreadClick(thread?.id)}
                      disabled={loading}
                    >
                      <DeleteOutline
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </IconButton>
                  </Tooltip>{" "}
                </>
              )}
            </Stack>
          )
        }
      >
        {edit ? (
          <div
            data-testid="thread-name-edit-field"
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 15,
              paddingRight: 50,
            }}
          >
            <TextField fullWidth value={value} onChange={(e) => setValue(e.target.value)} />
          </div>
        ) : (
          <Tooltip title={thread?.title} placement="right">
            <s.ListItemButton
              disabled={loading}
              selected={currentThread === thread?.id}
              onClick={() => {
                selectThread(thread?.id, thread?.resultId);
              }}
            >
              <ListItemIcon sx={{ marginRight: "-20px", minWidth: "40px" }}>
                <ChatOutlinedIcon sx={{ fontSize: 13 }} />
              </ListItemIcon>
              <ListItemText
                primary={thread?.title}
                secondary={highlightSubstring(thread?.question, searchQuery)}
              />
            </s.ListItemButton>
          </Tooltip>
        )}
      </ListItem>
    </div>
  );
};
