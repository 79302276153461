import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { DATA_EXPLORE } from "../../Constants/constants";
import { useChart } from "../../context/chart";
import { useChatData } from "../../context/chat";
import { usePinboard } from "../../context/pinboard";
import { Analytics } from "../../services/analytics/Analytics";
import ChartContent from "../Chart/ChartContent";
import ChartMenu from "../Chart/ChartMenu";
import { ChartTitle } from "../Chart/ChartTitle";
import { ChartSelector } from "../ChartSelector/ChartSelector";
import "./DataWhiteboard.css";
import * as s from "./DataWhiteboard.s";
import { LikeDislikeToggle } from "./Feedback/LikeDislikeToggle";
import { PinboardModal } from "./PinboardModal/PinboardModal";
import { WhiteboardFullScreen } from "./WhiteboardFullScreen/WhiteboardFullScreen";
import { Trans } from "@lingui/macro";

export const DataWhiteboard = ({ loading, loadingText, doowii }) => {
  const { boards, unpinFromBoard } = usePinboard();
  const {
    allResults,
    chatHistoryIds,
    preFetchFirstPageData,
    currentResult: results,
    selectResult: setResults,
  } = useChatData();
  const { charts } = useChart();
  const { dataMap } = useChatData();
  const [chartType, setChartType] = useState(null);
  const [openPinboardModal, setOpenPinboardModal] = useState(false);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);

  const handleNavigation = (direction) => {
    let index = allResults.findIndex((result) => result.id === results?.id);
    const newIndex = direction === "left" ? index - 1 : index + 1;
    setResults(allResults[newIndex]);

    if (direction === "left" && newIndex > 0) {
      preFetchFirstPageData(allResults[newIndex - 1]);
    }
  };

  useEffect(() => {
    if (fullScreenOpen) {
      Analytics.track("Result full screen");
    }
  }, [fullScreenOpen]);

  useEffect(() => {
    setChartType(results?.visualisation);
  }, [results?.id]);

  const renderChips = () => {
    return Array.from(boards.keys())
      .filter((key) => boards.get(key).pins.includes(results?.id))
      .map((key, index) => (
        <Chip
          key={index}
          label={boards.get(key).name}
          size="small"
          color={boards.get(key).color}
          onDelete={() => unpinFromBoard(results?.id, key as string)}
          data-testid="pinned-board-chip"
        />
      ));
  };

  return (
    <>
      <s.Header>
        <s.InputContainer>
          <Tooltip title={<Trans>"Back"</Trans>} placement="top">
            <div>
              <IconButton
                data-testid="back-navigation-button"
                sx={{
                  "&.Mui-disabled": {
                    color: "#c0c0c0",
                  },
                  color: "#003A47",
                }}
                onClick={() => handleNavigation("left")}
                disabled={loading || allResults[0]?.id === results?.id ? true : false}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={<Trans>"Next"</Trans>} placement="top">
            <div>
              <IconButton
                data-testid="forward-navigation-button"
                sx={{
                  "&.Mui-disabled": {
                    color: "#c0c0c0",
                  },
                  color: "#003A47",
                }}
                onClick={() => handleNavigation("right")}
                disabled={
                  loading || results?.id === allResults[allResults.length - 1]?.id ? true : false
                }
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </Tooltip>
          <ChartTitle
            results={results}
            docId={chatHistoryIds[allResults.findIndex((res) => res.id === results?.id)]}
            isFavView={false}
          />
        </s.InputContainer>
        <s.SelectorAndButtonsContainer>
          <ChartSelector
            result={results}
            chartType={chartType}
            setChartType={setChartType}
            charts={charts}
          />
          <ChartMenu
            results={results as any}
            handleRemove={() => {}}
            disabled={
              results?.visualisation === "EMPTY_ROWS" ||
              results?.visualisation === "ERROR" ||
              loading ||
              results?.status === "processing" ||
              results?.status === "error"
            }
            isDataExplore={chartType === DATA_EXPLORE}
            setOpenPinboardModal={setOpenPinboardModal}
            setFullScreenOpen={setFullScreenOpen}
          />
        </s.SelectorAndButtonsContainer>
      </s.Header>
      <s.DataWhiteboard>
        <ChartContent chartType={chartType} loadingText={loadingText} doowii={doowii} />
      </s.DataWhiteboard>
      <PinboardModal
        results={results}
        visualisation={chartType}
        charts={charts}
        openPinboardModal={openPinboardModal}
        setOpenPinboardModal={setOpenPinboardModal}
        dataMap={dataMap}
      />
      <Stack style={{ marginTop: 10 }} direction="row" spacing={1}>
        {renderChips()}
        <LikeDislikeToggle />
      </Stack>
      {fullScreenOpen && (
        <WhiteboardFullScreen
          handleClose={() => {
            setFullScreenOpen(false);
          }}
          results={results}
          openAddToPinboardModal={() => setOpenPinboardModal(true)}
          doowii={doowii}
        />
      )}
    </>
  );
};
