export const sortDataLocally = (sortModel, data) => {
  if (!sortModel || sortModel.length === 0) {
    return data;
  }

  const { field, sort } = sortModel[0];
  const sortedRows = Object.values(data || {}).sort((row1, row2) => {
    const value1 = row1[field];
    const value2 = row2[field];

    let comparison = 0;

    if (value1 === null || value2 === null) {
      if (value1 === value2) return 0;
      return value1 === null ? (sort === "asc" ? -1 : 1) : sort === "asc" ? 1 : -1;
    }

    if (typeof value1 === "number" && typeof value2 === "number") {
      comparison = value1 - value2;
    } else if (typeof value1 === "string" && typeof value2 === "string") {
      const numeric1 = !isNaN(parseFloat(value1));
      const numeric2 = !isNaN(parseFloat(value2));

      if (numeric1 && numeric2) {
        comparison = parseFloat(value1) - parseFloat(value2);
      } else {
        comparison = value1.localeCompare(value2);
      }
    }
    return sort === "asc" ? comparison : -comparison;
  });

  return sortedRows;
};
