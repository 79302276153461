/* eslint-disable lingui/no-unlocalized-strings */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { DEV_ACCOUNT } from "../../Constants/features";
import { getRelativeDateAndTime } from "../../canvas-new/components/ChatTab/ChatArea/ChatTile/SpeechBubble/BubbleTextArea/time-helper";
import { useAuth } from "../../context";
import { useChatData } from "../../context/chat";
import { Result } from "../../context/chat/chat.i";
import { useUI } from "../../context/ui";
import { getRecentMessage } from "../../helpers/getRecentMessage";
import { Doowii } from "../../search/Doowii";
import { QuestionTypeEnum } from "../../search/Doowii.i";
import * as s from "./SearchHistoryItem.s";
import { Trans } from "@lingui/macro";
import { useLocaleContext } from "../../context/locale";

interface SearchHistoryItemProps {
  index: number;
  item: string;
  last: boolean;
  timestamp: DateTime;
  result: Result;
  doowii: Doowii;
  single: boolean;
}

export const SearchHistoryItem: FC<SearchHistoryItemProps> = ({
  index,
  item,
  last,
  timestamp,
  result,
  single,
  doowii,
}) => {
  const {
    answer: answers,
    currentResult: results,
    selectResult: setResults,
    allResults,
    loading,
    streamLoading,
  } = useChatData();
  const { locale } = useLocaleContext();
  const { featureFlags } = useAuth();
  const { model } = useUI();

  const [openSuggestions, setOpenSuggestions] = useState(single);

  const relativeDate = getRelativeDateAndTime(timestamp, locale);

  const handleSearchHistoryClick = (i) => {
    if (allResults[i]?.visualisation !== "ERROR") {
      setResults(allResults[i]);
    }
  };

  const hasError = allResults?.[index]?.visualisation === "ERROR";
  const isActive = allResults?.findIndex((res) => res.id === results?.id) === index;

  const createMarkdownText = (markdownText) => {
    try {
      if (!markdownText) return null;

      return (
        <ReactMarkdown
          children={markdownText}
          rehypePlugins={[rehypeRaw]}
          components={{
            code: ({ children, className }) => (
              //@ts-ignore "text-wrap" style throwing error for some reason
              <code style={{ "text-wrap": "balance" }} className={className}>
                {children}
              </code>
            ),
            h3: ({ children }) => (
              <h3 style={{ margin: children !== "Summary" ? "15px 0" : "" }}>{children}</h3>
            ),
          }}
        />
      );
    } catch (e) {
      console.error(e);
      return markdownText;
    }
  };

  const showFollowUpPrompts = !!result?.follow_up_prompts?.length;

  return (
    <div key={index}>
      <s.RelativeDate>{relativeDate}</s.RelativeDate>
      {featureFlags.includes(DEV_ACCOUNT) && (
        <s.Duration>Sequalizer request duration: {allResults?.[index]?.latency}s</s.Duration>
      )}
      <s.QuestionContainer
        $hasError={hasError}
        $isActive={isActive}
        $loading={loading}
        onClick={() => !loading && handleSearchHistoryClick(index)}
        id={isActive ? "selected-chat" : ""}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%", fontWeight: 500 }}
        >
          <div>{item}</div>
          <div>
            <ArrowForwardIcon style={{ color: "#003A47" }} />
          </div>
        </Stack>
      </s.QuestionContainer>
      <s.RelativeDate></s.RelativeDate>
      <s.AnswerContainer>
        <s.RelativeDate $right>{relativeDate}</s.RelativeDate>
        <s.Answer>
          <s.AnswerText>
            {(loading || streamLoading) && last ? (
              <s.Typing />
            ) : (
              createMarkdownText(answers?.[index])
            )}
          </s.AnswerText>
          {showFollowUpPrompts && (
            <s.FollowUpQuestions>
              <h2 onClick={() => setOpenSuggestions(!openSuggestions)}>
                <Trans>Follow up with</Trans>{" "}
                {openSuggestions ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </h2>
              {openSuggestions &&
                result?.follow_up_prompts?.slice(0, 3)?.map((prompt) => (
                  <s.FollowUpQuestion
                    key={prompt}
                    $loading={loading}
                    onClick={() => {
                      if (loading) return;

                      // MAKE QUESTION
                      doowii.chat({
                        query: prompt,
                        model,
                        index: allResults.length,
                        recent_messages: getRecentMessage({ allResults, answer: answers }),
                        questionType: QuestionTypeEnum.REFINEMENT,
                      });
                    }}
                  >
                    {prompt}
                  </s.FollowUpQuestion>
                ))}
            </s.FollowUpQuestions>
          )}
        </s.Answer>
      </s.AnswerContainer>
    </div>
  );
};
