import styled from "styled-components";

export const SearchHistory = styled.div<{ $standAlone?: boolean }>`
  ${({ $standAlone }) => {
    if ($standAlone)
      return `
      max-width: 100%;
      align-items: center;

      @media (min-width: 768px) {
        width: 75%;

      }

    `;

    return "flex: 1";
  }};
  flex-direction: column;
  display: flex;
`;

export const Chat = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
`;

export const ScrollableDiv = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  padding-right: 4px;
  scrollbar-gutter: stable;
`;

export const InputContainer = styled.div<{ $standAlone?: boolean }>`
  padding-top: 6px;

  ${({ $standAlone }) => {
    if ($standAlone) {
      return `
      width: 75%;
      padding-right: 0;

      @media (max-width: 768px) {
        width: 100%;
      }
    `;
    }

    return `
    scrollbar-gutter: stable;
    overflow-y: auto;
    `;
  }}
`;
