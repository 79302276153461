import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { usePinboard } from "../../../context/pinboard";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { focusById } from "../../../helpers/actionById";
import { CreateModal } from "../UI/Modals/Create";
import { DeleteModal } from "../UI/Modals/Delete";
import { PinboardEmpty } from "./PinboardEmpty/PinboardEmpty";
import { PinboardGrid } from "./PinboardGrid/PinboardGrid";
import { selectNextBoard, selectPreviousBoard } from "./PinboardTab.helper";
import { SelectionAndSearch } from "./SelectionAndSearch/SelectionAndSearch";
import { filterSearch } from "./helpers";
import { Heading } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const PinboardTab = () => {
  const { _ } = useLingui();
  const {
    currBoardId,
    pinboardResults,
    boards,
    loading,
    setCurrBoardId,
    addNewBoard,
    deleteBoard,
  } = usePinboard();
  const { setView } = useUI();

  const [search, setSearch] = useState("");
  const [filteredPinboardResults, setFilteredPinboardResults] = useState<Pin[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const modalsClosed = !createModalOpen && !deleteModalOpen;
  useHotkeys("CTRL+SHIFT+1", () => setView(ViewEnum.CHAT_VIEW), { enabled: modalsClosed });
  useHotkeys("CTRL+1", () => focusById("pinboard-search-input"), { enabled: modalsClosed });
  useHotkeys("CTRL+2", () => setCreateModalOpen(true), { enabled: modalsClosed });
  useHotkeys("CTRL+3", () => selectPreviousBoard(currBoardId, boards, setCurrBoardId), {
    enabled: modalsClosed,
  });
  useHotkeys("CTRL+4", () => selectNextBoard(currBoardId, boards, setCurrBoardId), {
    enabled: modalsClosed,
  });

  const clearSearch = () => setSearch("");

  useEffect(() => {
    const filteredBoards = filterSearch(search, pinboardResults);

    setFilteredPinboardResults(filteredBoards);
  }, [search, pinboardResults]);

  const isEmpty = boards?.size === 0 ? true : false;

  return (
    <>
      <div className="chat-desktop:hidden">
        <Heading>
          <Trans>Pinboard</Trans>
        </Heading>
      </div>
      {isEmpty ? (
        <PinboardEmpty setCreateModalOpen={setCreateModalOpen} />
      ) : (
        <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
          <SelectionAndSearch
            search={search}
            setSearch={setSearch}
            hasData={!!pinboardResults.length}
            setCreateModalOpen={setCreateModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
          />
          <PinboardGrid
            loading={loading}
            currBoardId={currBoardId!}
            hasSearch={!!search.length}
            pinboardResults={pinboardResults}
            filteredPinboardResults={filteredPinboardResults}
            clearSearch={clearSearch}
          />
        </div>
      )}
      {createModalOpen && (
        <CreateModal
          open={createModalOpen}
          setOpen={setCreateModalOpen}
          name={_(msg`Pinboard`)}
          action={addNewBoard}
          maxLength={80}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          name={_(msg`Pinboard`)}
          action={() => (deleteBoard as any)(currBoardId)}
          text={_(
            msg`Are you sure you want to delete this pinboard and all of its saved pins? This action can not be undone.`
          )}
        />
      )}
    </>
  );
};
