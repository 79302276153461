import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";

import { toast } from "react-hot-toast";
import { usePinboard } from "../../context/pinboard";
import { withSentry } from "../../helpers/wrapper";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface FavoriteBoardOptionsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function FavoriteBoardOptionsModal({
  open,
  setOpen,
}: FavoriteBoardOptionsModalProps) {
  const { _ } = useLingui();
  const { updateBoardName, currBoardId, boards, deleteBoard } = usePinboard();

  const [title, setTitle] = useState(currBoardId ? boards.get(currBoardId)?.name : "");
  const [deleteTitle, setDeleteTitle] = useState("");
  const [isDeleteTitleIncorrect, setIsDeleteTitleIncorrect] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTitle(boards.get(currBoardId).name);
    setIsDeleteTitleIncorrect(false);
  };

  const handlePinboardTitleChange = withSentry(async () => {
    await updateBoardName(currBoardId, title);
    handleClose();
  });

  const handleRemovePinboard = withSentry(async () => {
    if (deleteTitle.toLowerCase() === boards.get(currBoardId).name.toLowerCase()) {
      await deleteBoard(currBoardId);
    } else {
      toast.error("Pinboard name does not match.");
    }
    handleClose();
  });

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle id="pinboard-modal-title">
          <Trans>Edit Pinboard Name</Trans>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            required
            fullWidth
            label={_(msg`New Pinboard Name`)}
            defaultValue={currBoardId ? boards.get(currBoardId).name : ""}
            onChange={(e) => setTitle(e.currentTarget.value)}
            variant="standard"
            // inputProps={{
            //   maxLength: PINBOARD_NAME_CHARACTER_LIMIT
            // }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePinboardTitleChange} variant="contained" color="primary">
            <Trans>Save</Trans>
          </Button>

          <Button onClick={handleClose}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
