import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  BAR_CHART,
  COMBINED_CHART,
  EMPTY_ROWS,
  ERROR,
  LINE_CHART,
  PIE_CHART,
  SAVANT_LINE,
  SCATTER_CHART,
  SQL_CHART,
  TABLE,
} from "../../../../../Constants/constants";
import { Pin } from "../../../../../context/pinboard/pinboard.i";
import { Chart } from "../../../../../helpers/Chart";
import ErrorDisplay from "../../../../Chart/ErrorDisplay";
import { SqlDisplay } from "../../../../Chart/SqlDisplay/SqlDisplay";
import { BarChart } from "../../../../Charts/BarChart/BarChart";
import { CombinedChart } from "../../../../Charts/CombinedChart";
import { DataTable } from "../../../../Charts/DataTable/DataTable";
import { LineChart } from "../../../../Charts/LineChart/LineChart";
import { PieChart } from "../../../../Charts/PieChart/PieChart";
import PredictionLineChart from "../../../../Charts/PredictionLineChart/PredictionLineChart";
import { ScatterChart } from "../../../../Charts/ScatterChart/ScatterChart";
import { FullContainer } from "../../../../FullContainer/FullContainer";
import MemoizedPieChartH from "../../../../Charts/PieChart/PieChartH";
import MemoizedBarChartH from "../../../../Charts/BarChart/BarChartH";
import MemoizedLineChartH from "../../../../Charts/LineChart/LineChartH";
import MemoizedScatterChartH from "../../../../Charts/ScatterChart/ScatterChartH";
import { useAuth } from "../../../../../context";
import { useSwrQuery } from "../../../../../hooks/useSwrQuery";

interface ChartProps {
  pin: Pin;
  selectedColumns: any;
  setSelectedColumns: any;
  chartType: string;
  setChartType: (chartType: string) => void;
  chatSummary: string;
}

export const FullScreenChart: FC<ChartProps> = ({
  pin,
  chartType,
  setChartType,
  selectedColumns,
  setSelectedColumns,
  chatSummary,
}) => {
  const [height, setHeight] = useState(300);
  const [width, setWidth] = useState(0);
  const [chartConfig, setChartConfig] = useState({});

  const { userDocument } = useAuth();
  const containerRef = useRef(null);
  const { allData, isError, isLoading, totalRows } = useSwrQuery({
    sql_query: pin?.sql,
    doc_id: pin?.id,
  });

  useEffect(() => {
    const tempConfig = {
      ...pin?.chartConfig,
      columns:
        Object.keys(selectedColumns).length > 0
          ? Object.keys(selectedColumns)
          : pin?.chartConfig?.columns,
      column_types: pin?.chartConfig?.column_types,
    };
    setChartConfig(tempConfig);
  }, [pin, selectedColumns]);

  function updateSize() {
    if (containerRef.current) {
      setHeight(containerRef.current.getBoundingClientRect().height);
      setWidth(containerRef.current.getBoundingClientRect().width);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize(); // Set initial size

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  }, [chatSummary]);

  return (
    <FullContainer ref={containerRef}>
      <>
        {chartType === SAVANT_LINE && (
          <PredictionLineChart result={pin} height={height} width={width} />
        )}

        {chartType === TABLE && (
          <DataTable
            result={pin}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            height={height}
          />
        )}
        {chartType === PIE_CHART && (
          <MemoizedPieChartH
            data={allData}
            height={height}
            width={width}
            chartConfig={chartConfig}
            loading={isLoading}
            error={isError}
            totalRows={totalRows}
            title={pin?.title}
          />
        )}
        {chartType === BAR_CHART && (
          <MemoizedBarChartH
            data={allData}
            height={height}
            width={width}
            chartConfig={chartConfig}
            loading={isLoading}
            error={isError}
            totalRows={totalRows}
            title={pin?.title}
          />
        )}
        {chartType === LINE_CHART && (
          <MemoizedLineChartH
            data={allData}
            height={height}
            width={width}
            chartConfig={chartConfig}
            loading={isLoading}
            error={isError}
            totalRows={totalRows}
            title={pin?.title}
          />
        )}
        {chartType === SQL_CHART && (
          <SqlDisplay sql={pin?.sql} originalSql={(pin as any)?.originalSql} height="300px" />
        )}
        {chartType === SCATTER_CHART && (
          <MemoizedScatterChartH
            data={allData}
            height={height}
            width={width}
            chartConfig={chartConfig}
            loading={isLoading}
            error={isError}
            totalRows={totalRows}
          />
        )}

        {/* The user cannot add empty sql and error chat data to pinboard. Keep those two 
        options for legacy pins that have already been added to the pin board.
        */}
        {chartType === EMPTY_ROWS && <ErrorDisplay results={pin} doowii={null} />}
        {chartType === ERROR && <ErrorDisplay results={pin} doowii={null} />}
      </>
    </FullContainer>
  );
};
