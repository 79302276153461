import styled from "styled-components";

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 14px;

  @media (min-width: 768px) {
    padding-top: 28px;
  }
`;
