import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";

import { CHART_LIMIT, LINE_CHART } from "../../../Constants/constants";
import { fetchChartTransformedData } from "../../../api/retriever";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import { ChartLoadingSpinner } from "../ChartLoadingSpinner/ChartLoadingSpinner";
import { sortDataLocally } from "../helpers";
import { transformDataForLineChart } from "./helpers";
import { ParentDocTypeEnum } from "../../../api/retriever.i";

interface LineChartProps {
  data: any;
  height: number;
  width: number;
  isDemo?: boolean;
  selectedColumns: any;
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  totalRows: number;
  sortModel?: Array<{ field: string; sort: string }>;
}

export const LineChart = ({
  data,
  height,
  width,
  isDemo = false,
  selectedColumns,
  docId,
  parentDocId,
  parentDocType,
  totalRows,
  sortModel,
}: LineChartProps) => {
  const chartRef = useRef(null);

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transformedData, setTransformedData] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      setHasError(false);

      if (!isDemo && totalRows > CHART_LIMIT) {
        setLoading(true);
        try {
          const response = await fetchChartTransformedData({
            doc_id: docId,
            parent_doc_id: parentDocId,
            parent_doc_type: parentDocType,
            columns: selectedColumns,
            chart_type: LINE_CHART,
          });

          if (response.data) {
            setTransformedData(response.data); // Assuming the actual data is in response.data
          } else {
            setHasError(true);
          }
        } catch (error) {
          console.error("Error fetching transformed data:", error);
          setHasError(true);
        }
      } else if (data?.length > 0) {
        const sortData = sortDataLocally(sortModel, data);
        setTransformedData(transformDataForLineChart(sortData, selectedColumns, isDemo));
      }
      setLoading(false);
    };

    fetchData();
  }, [data, selectedColumns, isDemo]);

  useEffect(() => {
    if (data?.length > 0) {
      const options = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: transformedData?.series?.map((s) => s.name),
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: transformedData?.categories,
        },
        yAxis: {
          type: "value",
          ...transformedData?.yAxisScale,
        },
        series: transformedData?.series,
      };

      const chart = echarts.init(chartRef.current);
      chart.setOption(options);

      return () => chart.dispose(); // Cleanup on unmount
    }
  }, [width, height, transformedData]);

  return (
    <div data-testid="line-chart">
      <div ref={chartRef} style={{ width: width || "100%", height: height || "500px" }}></div>
      {loading && <ChartLoadingSpinner />}
      {hasError && <ChartErrorState />}
    </div>
  );
};
