import { msg } from "@lingui/macro";

export const chatAndResultOnboarding = {
  id: "chatAndResultOnboarding",
  steps: [
    {
      element: `[data-onboarding="chart-selector"]`,
      title: msg`Visualize Your Insights`,
      intro: msg`Feeling visual? This spot lets you choose how to see your data. Select your columns, pick your chart, tweak the headers, and watch your data dance.`,
    },
    {
      element: `[data-onboarding="sql-chart-button"]`,
      title: msg`SQL Savvy`,
      intro: msg`Got a knack for SQL? Click here to tweak your queries in our editor. It's your data, play with it and see what new insights you uncover.`,
    },
    {
      element: `[data-onboarding="chart-menu"]`,
      intro: msg`Charts galore! Add them to your pinboard, download, go full screen, or clear the deck. Your data, your rules.`,
    },
    {
      element: `[data-onboarding="drawer-button"]`,
      title: msg`Chat History in a Click`,
      intro: msg`A tidy drawer for your chat history. Toggle this to stroll down memory lane or revisit past insights.`,
    },
    {
      element: `[data-onboarding="chat-list"]`,
      title: msg`Edit, Delete, Repeat`,
      intro: msg`Your chat history, curated by you. Rename, remove, or replay chats to your heart's content.`,
    },
  ],
};
