import styled from "styled-components";

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfilePicture = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid #2c3e50;
  background-color: #ebebeb;
  color: #153a48;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  user-select: none;
`;

export const UserName = styled.span`
  display: none;
  font-weight: bold;
  color: #2c3e50;

  @media (min-width: 768px) {
    display: block;
  }
`;
