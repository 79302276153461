import { Button } from "@mui/material";
import styled from "styled-components";

export const AddNew = styled(Button)`
  margin-top: 10px !important;
`;

export const InputAndButtons = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 5px;
  margin-top: 10px;
`;
