import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

import * as s from "./Modal.s";
import { Trans } from "@lingui/macro";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  disabled?: boolean;
  actions?: ReactNode;
  children: ReactNode;
}

export const Modal: FC<ModalProps> = ({
  open,
  handleClose,
  title,
  actions,
  disabled,
  children,
}) => {
  return (
    <>
      {createPortal(
        <s.Backdrop open={open} onClick={handleClose}>
          <s.Modal onClick={(e) => e.stopPropagation()}>
            <s.TitleAndClose>
              <h2>{title}</h2>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </s.TitleAndClose>
            <s.Content>{children}</s.Content>
            <s.Footer>
              {actions}
              <Button onClick={handleClose} disabled={disabled} variant="contained">
                <Trans>Close</Trans>
              </Button>
            </s.Footer>
          </s.Modal>
        </s.Backdrop>,
        document.getElementById("modal-root") as HTMLElement
      )}
    </>
  );
};
