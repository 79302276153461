import { Button } from "@mui/material";
import styled from "styled-components";

export const Favorites = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FavoritesHeader = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid;
  border-bottom-color: rgba(44, 62, 80, 0.1);
  padding-bottom: 3px;
`;

export const NewPinboardButton = styled(Button)`
  background: #153947 !important;
  padding: 12.25px !important;
  line-height: 1 !important;
  border-radius: 4px !important;
  color: white !important;
  text-transform: none !important;
  gap: 10px !important;

  &:hover {
    background: ${({ theme }) => theme.core.colors.lightBlue} !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #EBEBEB !important;
    opacity: 0.7 !important;
  `}
`;
