import {
  DocumentData,
  QuerySnapshot,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../../../context";
import { firebaseTimestampToLuxon } from "../../../../helpers/time";
import { db } from "../../../../services/firebase";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

let debounceTimer;

function debounceNotification(callback, wait) {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(callback, wait);
}
export default function useNotification() {
  const { _ } = useLingui();
  const { userDocument } = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (userDocument && userDocument.organization) {
      const notificationCollectionRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "notifications"
      );

      const notificationQuery = query(
        notificationCollectionRef,
        where("created_by", "==", userDocument.id)
      );
      const unsubscribe = onSnapshot(
        notificationQuery,
        (snapshot: QuerySnapshot<DocumentData>): void => {
          const updatedNotifications = snapshot.docs
            .map((doc) => {
              return doc.data().status === "unread" ? { ...doc.data() } : null;
            })
            .filter((notification) => notification !== null)
            .sort((a, b) => b.timestamp - a.timestamp);

          const nowMillis = DateTime.now().toMillis();
          let showToast = false;
          snapshot.docs.forEach((doc) => {
            const data = doc.data();

            if (
              data.status === "unread" &&
              nowMillis - firebaseTimestampToLuxon(data.timestamp).toMillis() < 3000
            ) {
              showToast = true;
            }
          });

          if (showToast) {
            debounceNotification(() => {
              toast.success(
                _(
                  msg`Your prediction job has been completed! Check out the results when you're ready!`
                )
              );
            }, 1000);
          }

          setNotifications(updatedNotifications);
        }
      );
      return () => unsubscribe();
    }
  }, [userDocument]);

  const updateNotificationStatus = async (chat_id: string) => {
    try {
      const notificationDocRef = doc(
        db,
        "organizations",
        userDocument.organization,
        "notifications",
        chat_id
      );

      await updateDoc(notificationDocRef, {
        status: "read",
      });
    } catch (error) {
      console.error("Error updating notification status: ", error);
    }
  };

  const deleteNotificationByChatId = async (chat_id: string) => {
    try {
      const notificationDocRef = doc(
        db,
        "organizations",
        userDocument.organization,
        "notifications",
        chat_id
      );

      await deleteDoc(notificationDocRef);
    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  return { notifications, setNotifications, updateNotificationStatus, deleteNotificationByChatId };
}
