import styled from "styled-components";

export const Backdrop = styled.div<{ open?: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  display: ${({ open }) => (open ? "flex" : "none")};
  z-index: 600;
`;

export const Modal = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin: auto;
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 90%;
    height: 90%;
  }
`;

export const TitleAndClose = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

  h2 {
    flex: 1;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 10px;
`;
