import { Check, Delete, Edit } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-hot-toast";
import { FAILED, SUCCESS, UPLOADED } from "../../Constants/constants";
import { useIntegration } from "../../context/integrations";
import { withSentry } from "../../helpers/wrapper";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

export default function Row({ source, index, openDeleteModal }) {
  const { _ } = useLingui();
  const [name, setName] = React.useState(source?.name ? source?.name : "N/A");
  const [edit, setEdit] = React.useState(false);
  const textStyle = {
    textAlign: "left",
    color: "black",
    fontSize: "0.9rem",
    padding: "0.8rem",
  };
  const { updateSourceName } = useIntegration();

  const handleEdit = withSentry(async () => {
    const status = await updateSourceName(source?.id, name);
    if (status.success) {
      toast.success(_(msg`Source name updated successfully`));
    } else {
      toast.error(_(msg`Error updating source name, please try again!`));
    }
    setEdit(false);
  });

  const renderChip = (status) => {
    if (status === SUCCESS)
      return (
        <Chip data-testid="success-chip" label={_(msg`Success`)} color="success" size="small" />
      );
    if (status === UPLOADED)
      return (
        <Chip data-testid="uploaded-chip" label={_(msg`Uploaded`)} color="warning" size="small" />
      );
    if (status === FAILED)
      return <Chip data-testid="failed-chip" label={_(msg`Failed`)} color="error" size="small" />;
    return <Chip data-testid="error-chip" label={_(msg`Error`)} color="secondary" size="small" />;
  };
  return (
    <>
      <Box
        data-testid="row"
        sx={{
          width: "100%",
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F5F5F5",
          padding: "0.5rem",
          borderRadius: "7px",
        }}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={1} sx={textStyle}>
            {edit ? (
              <TextField
                data-testid="source-name-input"
                id={`source-name-${index}`}
                label={_(msg`Name`)}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            ) : (
              name
            )}
          </Grid>
          <Grid item xs={2} sx={textStyle}>
            {source?.source_type}
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              textAlign: "left",
              color: "#8B8989",
              fontSize: "0.9rem",
              padding: "0.8rem",
              overflow: "auto",
            }}
          >
            <Stack>
              <a rel="noreferrer" href={source?.source} target="_blank" style={{ color: "black" }}>
                {source?.source}
              </a>
            </Stack>
          </Grid>
          <Grid item xs={1} sx={textStyle}>
            {renderChip(source?.sync_status)}
          </Grid>
          <Grid item xs={2} sx={textStyle}>
            {source?.synced_at.toDate().toLocaleString()}
          </Grid>

          <Grid item xs={1}>
            <Stack direction="row" justifyContent={"flex-start"}>
              {edit ? (
                <IconButton onClick={handleEdit} data-testid="confirm-source-name-button">
                  <Check />
                </IconButton>
              ) : (
                <IconButton data-testid="edit-source-name-button" onClick={() => setEdit(true)}>
                  <Edit />
                </IconButton>
              )}
              <IconButton onClick={openDeleteModal} data-testid="delete-source-button">
                <Delete />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
