import styled from "styled-components";

export const EmptyDisplay = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(39, 54, 70, 0.5);
  font-size: 27px;
  gap: 10px;

  h3 {
    font-size: "18px";
  }
`;
