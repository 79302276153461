import { JoinInnerOutlined, QueryStatsOutlined } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import { MouseEvent, useEffect, useState } from "react";

import { useChatData } from "../../context/chat";
import { useUI } from "../../context/ui";
import { ViewEnum } from "../../context/ui/ui.i";
import { Analytics } from "../../services/analytics/Analytics";
import * as s from "./Navbar.s";
import { Trans } from "@lingui/macro";

interface StyledMenuProps extends MenuProps {
  isBottom?: boolean;
}

const StyledMenu = styled(({ isBottom, ...props }: StyledMenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme, isBottom }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: isBottom ? 130 : 150,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function ModelSelector({ isBottom }: { isBottom?: boolean }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { model, setModel } = useUI();
  const { loading } = useChatData();
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = () => {
    isQuery ? setModel(ViewEnum.PREDICT_MODEL) : setModel(ViewEnum.QUERY_MODEL);
    handleClose();
  };
  const isQuery = model === ViewEnum.QUERY_MODEL;

  useEffect(() => {
    if (open) {
      Analytics.track("DrawSQL Opened");
    }
  }, [open]);

  return (
    <div>
      <s.ModelSelectorButton
        id="model-selector-button"
        aria-controls={open ? "model-selector-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        disabled={loading}
        onClick={handleClick}
        isBottom={isBottom}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={isQuery ? <JoinInnerOutlined /> : <QueryStatsOutlined />}
      >
        {model}
      </s.ModelSelectorButton>
      <StyledMenu
        id="model-selector-menu"
        MenuListProps={{
          "aria-labelledby": "model-selector-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        isBottom={isBottom}
      >
        <MenuItem onClick={handleSelect} disableRipple>
          {isQuery ? <QueryStatsOutlined /> : <JoinInnerOutlined />}
          {isQuery ? <Trans>Predict</Trans> : <Trans>Query</Trans>}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
