import React from "react";
import { useResize } from "../../../hooks/useResize";
import { ChartType } from "../../types";
import { BarChart } from "../../../components/Charts/BarChart/BarChart";
import { LineChart } from "../../../components/Charts/LineChart/LineChart";
import { PieChart } from "../../../components/Charts/PieChart/PieChart";
import { ScatterChart } from "../../../components/Charts/ScatterChart/ScatterChart";
import { ChartProps } from "../ChartBuilder/ChartBuilder";

const ChartRenderer: React.FC<{ chartProps: ChartProps }> = ({ chartProps }) => {
  const { componentRef, width, height } = useResize();

  const renderChart = () => {
    if (width === 0 || height === 0) return null;

    const props = { ...chartProps, width, height };

    switch (chartProps.chartType) {
      case ChartType.BAR_CHART:
        return <BarChart {...props} />;
      case ChartType.LINE_CHART:
        return <LineChart {...props} />;
      case ChartType.PIE_CHART:
        return <PieChart {...props} />;
      case ChartType.SCATTER_CHART:
        return <ScatterChart {...props} />;
      default:
        return null;
    }
  };

  return (
    <div ref={componentRef} className="flex flex-col flex-grow overflow-hidden">
      {renderChart()}
    </div>
  );
};

export default ChartRenderer;
