import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useChatData } from "../../../../context/chat";
import NotificationList from "./NotificationList";
import useNotification from "./useNotification";
import { plural } from "@lingui/macro";

export default function Notifications() {
  const { loading } = useChatData();
  const [anchorEl, setAnchorEl] = useState(null);
  const { notifications } = useNotification();

  const notificationOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const translatedTitle = plural(notifications.length, {
    one: "1 unread notification",
    other: "# unread notifications",
  });
  return (
    <>
      <Tooltip title={translatedTitle} placement="bottom">
        <div>
          <IconButton
            data-onboarding="open-settings-button"
            data-testid="settings-button"
            aria-controls="simple-menu"
            aria-haspopup="true"
            disabled={loading}
            onClick={handleClick}
          >
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsActiveOutlinedIcon
                sx={{
                  color: notificationOpen ? "#3CA0C3" : "#153946",
                  "&:hover": {
                    color: "#3CA0C3",
                  },
                  fontSize: "2rem",
                }}
              />
            </Badge>
          </IconButton>
        </div>
      </Tooltip>

      <NotificationList
        anchorEl={anchorEl}
        open={notificationOpen}
        handleClose={() => setAnchorEl(null)}
      />
    </>
  );
}
