import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { toast } from "react-hot-toast";
import { DEMO_ACCOUNT } from "../../Constants/features";
import { useAuth } from "../../context/auth";
import { useIntegration } from "../../context/integrations";
import { withSentry } from "../../helpers/wrapper";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#15394680",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  padding: "2rem",
};

const CustomInput = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: error ? "red" : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    marginBottom: "-0.7rem",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#153946",
    },
  },
}));

// eslint-disable-next-line lingui/no-unlocalized-strings
const DEFAULT_SOURCE = "Google Sheets";

export default function AddIntegrationsModal({ open, setOpen, isSourcesEmpty }) {
  const { _ } = useLingui();
  const [source, setSource] = useState(DEFAULT_SOURCE);
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const { featureFlags } = useAuth();
  const [error, setError] = useState({ name: false, link: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { createSource } = useIntegration();
  var unsubscribe = () => {};

  const handleChange = (event) => {
    setSource(event.target.value);
  };

  const setErrorResponse = (
    message = _(msg`Error adding source. Please try again!`),
    type = "link"
  ) => {
    type === "name" ? setError({ name: true, link: false }) : setError({ name: false, link: true });
    setErrorMessage(message);
    setLoading(false);
  };

  const validateURL = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%_.~+]*)*" + // path
        "(\\?[;&amp;a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_=]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const validateLink = withSentry(async (link, source) => {
    if (name === "") {
      setErrorResponse(_(msg`Please provide a name for the source.`), "name");
      return false;
    }
    switch (source) {
      case "Google Sheets":
        if (!validateURL(link)) {
          setErrorResponse(_(msg`Please provide a valid Google Sheets url.`));
          return false;
        }
        return true;
      case "Canvas":
        if (!validateURL(link)) {
          setErrorResponse(_(msg`Please provide a valid Canvas url.`));
          return false;
        }
        return true;
      default:
        console.error(`Unexpected source provided: ${source}`);
    }
  });

  const handleAdd = withSentry(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!(await validateLink(link, source))) {
      return;
    }

    const collection_name = featureFlags.includes(DEMO_ACCOUNT) ? "demo_sources" : "sources";
    try {
      await createSource(collection_name, name, link, "GOOGLE_SHEETS");
      toast.success(_(msg`Source added successfully! Ingestion will finish shortly.`));
      setLoading(false);
      setOpen(false);
    } catch (e) {
      if (e.message === "Source already exists!") {
        setErrorResponse(_(msg`Error adding source. Source already exists!`));
      } else {
        setErrorResponse(_(msg`We meet some unexpected error.Please try again!`));
      }
      throw e;
    }
  });

  const clear = () => {
    unsubscribe();
    setSource(DEFAULT_SOURCE);
    setName("");
    setLink("");
    setLoading(false);
    setError({ name: false, link: false });
    setErrorMessage("");
  };

  useEffect(() => {
    return () => {
      clear();
    };
  }, [open]);
  return (
    <div>
      <Modal
        data-testid="add-source-modal"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        components={{
          Backdrop: CustomBackdrop,
        }}
      >
        <Box sx={style}>
          <Stack direction="column">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#153946",
                fontSize: "1.5625rem",
                fontWeight: 800,
              }}
            >
              <Trans>Integrate New Source</Trans>
            </Typography>
            {!isSourcesEmpty && featureFlags.includes(DEMO_ACCOUNT) ? (
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{ mt: 2 }}
                style={{ fontSize: "0.75rem", fontWeight: 300 }}
              >
                <Trans>
                  For demo accounts, we currently support only one file; the new file will replace
                  the previously uploaded one.
                </Trans>
              </Typography>
            ) : (
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{ mt: 2 }}
                style={{ fontSize: "0.9375rem", fontWeight: 300 }}
              >
                <Trans>
                  Connect with new data sources to take advantage of our AI-driven analytics.
                </Trans>
              </Typography>
            )}
            <br />
            <FormControl variant="standard" sx={{ mb: 2 }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  "&.Mui-focused": {
                    color: "#153946",
                  },
                }}
                id="demo-simple-select-label"
              >
                <Trans>Data Source</Trans>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={source}
                onChange={handleChange}
                input={<CustomInput />}
              >
                <MenuItem value={DEFAULT_SOURCE}>
                  <Trans>Google Sheets</Trans>
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" data-testid="add-source-name-field">
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  "&.Mui-focused": {
                    color: "#153946",
                  },
                }}
                error={error.name}
              >
                <Trans>Source name:</Trans>
              </InputLabel>
              <CustomInput
                id="add-integration-name"
                onChange={(e) => setName(e.target.value)}
                error={error.name}
              />

              {error.name && <p style={{ color: "red", fontSize: "0.8rem" }}>{errorMessage}</p>}
            </FormControl>
            <br />
            <FormControl variant="standard">
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  "&.Mui-focused": {
                    color: "#153946",
                  },
                }}
                error={error.link}
              >
                <Trans>Link:</Trans>
              </InputLabel>
              <CustomInput
                id="bootstrap-input"
                onChange={(e) => setLink(e.target.value)}
                error={error.link}
              />

              {error.link && <p style={{ color: "red", fontSize: "0.8rem" }}>{errorMessage}</p>}
            </FormControl>{" "}
            <br />
            <Stack direction="row" justifyContent={"center"} spacing={4}>
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                sx={{
                  border: "2px solid #8B8989",
                  color: "#8B8989",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  minWidth: "5.75rem",
                }}
              >
                <Trans>Cancel</Trans>
              </Button>

              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={handleAdd}
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "0.875rem",
                    background: "#4EB5CC",
                    minWidth: "5.75rem",
                  }}
                >
                  <Trans>Add</Trans>
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#4EB5CC",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
