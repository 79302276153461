import styled from "styled-components";
interface ChatDrawerButtonProps {
  $chatDrawerOpen?: boolean;
}
export const ChatView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex: 2;
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex: 3;
`;

export const ChatDrawerButton = styled.div<ChatDrawerButtonProps>`
  display: ${({ $chatDrawerOpen }) => ($chatDrawerOpen ? "none" : "flex")};
  position: fixed;
  top: 50%;
  left: 15px;
  transform: translateY(-50%) rotate(90deg);
  transform-origin: left;
  transition: all 0.2s;
  z-index: 500;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  align-items: center;
  background-color: ${({ $chatDrawerOpen }) => ($chatDrawerOpen ? "#e5e7eb" : "#013B48")};
  color: ${({ $chatDrawerOpen }) => ($chatDrawerOpen ? "#1E1E1E" : "white")};
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:hover {
    opacity: 0.7;
  }

  svg {
    font-size: 24px;
  }

  @media (min-width: 768px) {
    display: flex;
    padding: 0 5px;
    left: ${({ $chatDrawerOpen }) => ($chatDrawerOpen ? "248px" : "8px")};

    svg {
      font-size: 15px;
    }
  }
`;

export const ChatHistoryText = styled.div`
  margin-left: 5px;
  display: none;
  user-select: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;
