import introJS from "intro.js";
import { i18n } from "@lingui/core";

import * as tourSteps from "./tour-steps";

const setShown = (id: string) => {
  localStorage.setItem(`introjs-${id}`, "true");
};

const alreadyShown = (id: string) => {
  return localStorage.getItem(`introjs-${id}`);
};

export const showTour = (id: string) => {
  if (!tourSteps[id]) {
    console.error("No tour steps found for", id);
    return;
  }

  const steps = tourSteps[id].steps;

  if (alreadyShown(id)) return;

  setShown(id);

  const translatedSteps = steps.map((step) => ({
    ...step,
    title: i18n._(step.title),
    intro: i18n._(step.intro),
  }));

  introJS()
    .onbeforechange(async (element) => {
      if (element.getAttribute("data-onboarding") !== "chat-list") return;

      const drawerButton = document.querySelector(
        '[data-onboarding="drawer-button"]'
      ) as HTMLButtonElement;

      if (drawerButton.getAttribute("data-onboarding-isopen") === "true") return;

      return new Promise((resolve) => {
        drawerButton.click();

        setTimeout(resolve, 500);
      });
    })
    .setOptions({ steps: translatedSteps, tooltipClass: "doowii-intro", disableInteraction: true })
    .start();
};

export const showInitialScreenOnboarding = () => {
  showTour("initialScreenOnboarding");
};

export const showChatAndResultOnboarding = () => {
  showTour("chatAndResultOnboarding");
};

export const showPinboardOnboarding = () => {
  showTour("pinboardOnboarding");
};
