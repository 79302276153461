import { FC, useEffect, useState } from "react";

import { LOGO_TEXT, PREDICTION_LOGO_TEXT } from "../../../../Constants/constants";
import { useUI } from "../../../../context/ui";
import { ViewEnum } from "../../../../context/ui/ui.i";
import { showInitialScreenOnboarding } from "../../../../helpers/onboarding";
import { Doowii } from "../../../../search/Doowii";
import { SearchHistory } from "../../../SearchHistory/SearchHistory";
import * as s from "./InitialChat.s";

interface InitialChatProps {
  doowii: Doowii;

  loadingText: string;
}

export const InitialChat: FC<InitialChatProps> = ({
  doowii,

  loadingText,
}) => {
  const { model } = useUI();
  useEffect(() => {
    setTimeout(() => {
      showInitialScreenOnboarding();
    }, 500);
  }, []);

  const [query, setQuery] = useState("");

  return (
    <s.InitialChat>
      <h1>{model === ViewEnum.QUERY_MODEL ? LOGO_TEXT : <PREDICTION_LOGO_TEXT />}</h1>

      <SearchHistory doowii={doowii} query={query} setQuery={setQuery} $standAlone />
    </s.InitialChat>
  );
};
