import { View } from "@instructure/ui";
import cx from "classnames";
import useBreakpoint from "../../../../../../../../hooks/useBreakpoint";

interface UserTextAreaProps {
  text: string;
  loading: boolean;
  selected: boolean;
  onSelectChat: () => void;
}

export const UserTextArea = ({ text, loading, selected, onSelectChat }: UserTextAreaProps) => {
  const isClickable = !loading && !selected;
  const { isChatDesktop } = useBreakpoint();

  return (
    <View
      as="div"
      role="button"
      className={`relative flex min-h-12 w-full grow justify-between rounded-lg border bg-[#E6F1F8] p-3 text-left ${cx(
        {
          "chat-desktop:cursor-pointer": isClickable,
          "cursor-wait": loading,
          "chat-desktop:border-[#C7CDD1]": !selected,
          "chat-desktop:border-[#0374B5]": selected,
          "chat-desktop:border-2": selected,
        }
      )}`}
      tabIndex={0}
      aria-pressed={selected}
      aria-disabled={loading}
      disabled={loading}
      onClick={isChatDesktop ? onSelectChat : undefined}
    >
      <div className="grow">{text}</div>
      <div
        className={`ml-2 mt-1 hidden h-5 w-5 shrink-0 rounded-full border bg-white chat-desktop:block ${cx(
          {
            "border-[6px]": selected,
            "border-[#C7CDD1]": !selected,
            "border-[#394B57]": selected,
          }
        )}`}
      />
    </View>
  );
};
