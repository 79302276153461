import {
  DocumentData,
  QueryDocumentSnapshot,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import { PIN_PRE_FETCH_SIZE, TABLE } from "../../Constants/constants";
import { fetchPageData } from "../../api/retriever";
import { withSentry } from "../../helpers/wrapper";
import { Analytics } from "../../services/analytics/Analytics";
import { db } from "../../services/firebase";
import { useAlert } from "../alert";
import { useAuth } from "../auth";
import { TableColumns } from "../chart/chart.i";
import { useChatData } from "../chat";
import {
  Board,
  CreatePin,
  DataMap,
  Pin,
  PinboardContextI,
  VisualizationTypesEnum,
} from "./pinboard.i";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { ParentDocTypeEnum } from "../../api/retriever.i";

const initialState: PinboardContextI = {
  boards: {},
  currBoardId: null,
  loading: false,
  pinboardResults: [],
  setBoards: () => {},
  setCurrBoardId: () => {},
  setPinboardResults: () => {},
  addNewBoard: async (name: string) => "",
  deleteBoard: async () => {},
  updateBoardName: async () => {},
  fetchPinboardResultsForBoard: async () => {},
  fetchNextPagePinboardResultsForBoard: async () => {},
  pinToBoard: async () => {},
  unpinFromBoard: async () => {},
  updatePinTitle: async () => {},
  updatePinVisualization: async () => {},
};

const PinboardContext = createContext<PinboardContextI>(initialState);
export const usePinboard = () => useContext(PinboardContext);

export const PinboardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [boards, setBoards] = useState<Map<string, Board>>(new Map());
  const [currBoardId, setCurrBoardId] = useState<string | null>(null);
  const [pinboardResults, setPinboardResults] = useState<Pin[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [loading, setLoading] = useState(false);

  const { userDocument } = useAuth();
  const { dataMap, setDataMap }: any = useChatData();
  const dataMapRef = useRef(dataMap);
  const { showAlert } = useAlert();

  const pinsUnsubscribeRef = useRef<ReturnType<typeof onSnapshot>>();
  const pageSize = 50;

  useEffect(() => {
    setLoading(true);

    const userId = userDocument?.id;
    if (!userId) {
      setLoading(false);
      return;
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const createdByQuery = query(pinboardsCollection, where("created_by", "==", userId));
    const canEditQuery = query(pinboardsCollection, where("can_edit", "array-contains", userId));

    const mergeAndUpdateBoards = (docSnap: QueryDocumentSnapshot<any>) => {
      const updatedBoards = new Map([...boards, [docSnap.id, docSnap.data()]]);
      setBoards((prevBoards) => new Map([...prevBoards, [docSnap.id, docSnap.data()]]));

      if (updatedBoards.size > 0) {
        setCurrBoardId((prevCurrBoardId) =>
          prevCurrBoardId === null ? updatedBoards.keys().next().value : prevCurrBoardId
        );
      } else {
        setCurrBoardId(null);
      }
    };

    const unsubscribeCreatedBy = onSnapshot(createdByQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    const unsubscribeCanEdit = onSnapshot(canEditQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    setLoading(false);

    // Cleanup the listeners on component unmount
    return () => {
      unsubscribeCreatedBy();
      unsubscribeCanEdit();
    };
  }, [userDocument]);

  useEffect(() => {
    // Whenever the current board ID changes, fetch the results for that board
    setPinboardResults([]);
    fetchPinboardResultsForBoard();

    // Cleanup the listener on component unmount or when the board ID changes
    return () => {
      if (pinsUnsubscribeRef.current) {
        pinsUnsubscribeRef.current();
      }
    };
  }, [currBoardId]);

  // Update the ref whenever the dataMap changes
  useEffect(() => {
    dataMapRef.current = dataMap;
  }, [dataMap]);

  const getRandomChipColor = () => {
    const colorThemeArray = [
      "default",
      "primary",
      "secondary",
      "error",
      "info",
      "success",
      "warning",
    ];
    return colorThemeArray[Math.floor(Math.random() * colorThemeArray.length)];
  };

  const addNewBoard = withSentry(async (name: string) => {
    if ([...boards.values()].some((board) => board.name.toLowerCase() === name.toLowerCase())) {
      throw new Error("Pinboard name already exists!");
    }
    // Add new board logic here
    if (!userDocument) {
      throw new Error("User not found!");
    }
    const userId = userDocument.id;
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const userDocRef = doc(organizationRef, "users", userId);

    const uuid = uuidV4();

    const pinboardData: CreatePin = {
      created_by: userId,
      can_edit: [],
      can_view: [],
      pins: [],
      name: name,
      id: uuid,
      created_at: serverTimestamp(),
      last_updated: serverTimestamp(),
      color: getRandomChipColor(),
    };

    try {
      await setDoc(doc(pinboardsCollection, uuid), pinboardData);
      await updateDoc(userDocRef, {
        pinboards: arrayUnion(uuid),
      });
      setCurrBoardId(uuid);
      console.info("Pinboard created successfully!");
      Analytics.track("Pinboard Created");
    } catch (error) {
      console.error("Error creating pinboard:", error);
      throw error;
    }

    return uuid;
  });

  const deleteBoard = withSentry(async (boardId: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const usersCollection = collection(organizationRef, "users");

    const pinboardDocRef = doc(pinboardsCollection, boardId);

    try {
      // Fetch the pinboard document
      const pinboardDocSnap = await getDoc(pinboardDocRef);

      if (!pinboardDocSnap.exists()) {
        console.error("Pinboard not found!");
        return;
      }

      const pinboardData = pinboardDocSnap.data();
      const allUsersToUpdate = [...pinboardData.can_edit, ...pinboardData.can_view];
      allUsersToUpdate.push(pinboardData.created_by);

      // Iterate over users and remove the pinboard UUID from their pinboards array
      const userUpdates = allUsersToUpdate.map(async (userId) => {
        const userDocRef = doc(usersCollection, userId);
        return updateDoc(userDocRef, {
          pinboards: arrayRemove(boardId),
        });
      });

      // Wait for all user updates to complete
      await Promise.all(userUpdates);
      const pinsCollectionRef = collection(pinboardDocRef, "pins");
      const pinsSnapshot = await getDocs(pinsCollectionRef);

      const batch = writeBatch(db);

      pinsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete the pinboard after deleting all pins
      batch.delete(pinboardDocRef);

      // Commit the batch
      await batch.commit();

      const newBoards = new Map(boards);
      newBoards.delete(boardId);
      setBoards(newBoards);

      if (newBoards.size > 0) {
        setCurrBoardId(newBoards.keys().next().value);
      } else {
        setCurrBoardId(null);
      }

      console.info("Pinboard deleted and UUIDs removed from user's pinboards arrays!");
      Analytics.track("Pinboard Deleted");
    } catch (error) {
      console.error("Error deleting pinboard and updating users:", error);
      throw error;
    }
  });

  const updateBoardName = withSentry(async (boardId: string, name: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);

    try {
      await updateDoc(pinboardDocRef, { name });
      console.info("Pinboard name updated successfully!");
      showAlert({ variant: "success", message: _(msg`Pinboard name updated successfully!`) });

      Analytics.track("Pinboard Renamed");
    } catch (error) {
      console.error("Error updating pinboard name:", error);
      showAlert({
        variant: "error",
        message: _(msg`Failed to update Pinboard name. Please try again later.`),
      });

      throw error;
    }
  });

  const pinToBoard = withSentry(async (result: Pin, boardId: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
    const pinRef = doc(pinboardDocRef, "pins", result.id);
    try {
      result?.sql === "demo_question" || result?.sql === "demo question"
        ? await setDoc(pinRef, {
            query: result?.query,
            sql: result?.sql,
            originalSql: result?.originalSql ?? result?.sql,
            title: result?.title,
            visualisation: result?.visualisation,
            visualisationArray: [],
            data: result?.data,
            timestamp: new Date(),
            id: result.id,
            can_view: [],
            can_edit: [],
            created_at: new Date(),
            last_updated: new Date(),
            type: "query",
            status: "success",
          })
        : await setDoc(pinRef, {
            query: result?.query,
            sql: result?.sql ?? "",
            originalSql: result?.originalSql ?? result?.sql ?? "",
            title: result?.title,
            visualisation: result?.visualisation,
            visualisationArray: result?.visualisationArray,
            selectedColumns: result?.visualisation === TABLE ? {} : result?.selectedColumns ?? {},
            timestamp: new Date(),
            id: result.id,
            can_view: [],
            can_edit: [],
            created_at: new Date(),
            last_updated: new Date(),
            type: result?.type || "QUERY",
            status: result?.status || "success",
            chartConfig: result?.chartConfig || {},
          });
      await updateDoc(pinboardDocRef, { pins: arrayUnion(result.id) });

      Analytics.track("Pinboard Pinned");
    } catch (error) {
      console.error("Error pinning result:", error);
      throw error;
    }
  });

  const unpinFromBoard = withSentry(async (resultId: string, boardId: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
    const pinRef = doc(pinboardDocRef, "pins", resultId);

    try {
      // Remove the resultId from the pins array in the pinboards document
      await updateDoc(pinboardDocRef, { pins: arrayRemove(resultId) });

      // Delete the corresponding document in the pins subcollection
      await deleteDoc(pinRef);
      Analytics.track("Pinboard Unpinned");
    } catch (error) {
      console.error("Error unpinning result:", error);
      throw error;
    }
  });

  const createPinResult = (
    pin: Pin,
    dataArray: { headers: any[]; rows: any[] } = { headers: [], rows: [] },
    loading = true
  ) => {
    return {
      ...pin,
      data: dataArray,
      dataMap: pin.data as any,
      loading: loading,
    };
  };

  const fetchAndSetPins = withSentry(async (pins: Pin[]) => {
    const demoPins: Pin[] = pins
      .filter((pin) => pin?.sql === "demo_question" || pin?.sql === "demo question")
      .map((pin) => createPinResult(pin, pin?.data, false));

    const constructedPins: Pin[] = pins
      .filter((pin) => pin?.sql !== "demo_question" && pin?.sql !== "demo question")
      .map((pin) => createPinResult(pin));

    setPinboardResults([...constructedPins, ...demoPins]);
    setLoading(false);
    preFetchPinData(constructedPins);
  });

  const preFetchPinData = withSentry(async (pins: Pin[]) => {
    pins.forEach((pin) => {
      if (!dataMapRef.current[pin.id]) {
        fetchPageData({
          page_size: PIN_PRE_FETCH_SIZE,
          page_number: 0,
          doc_id: pin?.id,
          parent_doc_id: currBoardId,
          parent_doc_type: ParentDocTypeEnum.PINBOARD,
          retrieval_type: pin?.type === "PREDICTION" ? "prediction" : "query",
          user_defined_query: pin?.originalSql && pin?.sql !== pin?.originalSql,
        }).then(({ data, headers }) => {
          const formattedData = data.rows?.reduce((acc, row, index) => {
            return { [index]: row, ...acc };
          }, {});

          setDataMap((prev: DataMap) => {
            return {
              ...prev,
              [pin.id]: {
                rows: {
                  ...prev[pin.id]?.rows,
                  ...formattedData,
                },
                pageInfo: {
                  totalRowCount: data.totalRows,
                  hyperlinkColumns: data.hyperlink_columns,
                  headers,
                  dataTypes: data.data_types,
                },
              },
            };
          });
        });
      }
    });
  });

  const fetchPinboardResultsForBoard = withSentry(async () => {
    // Ensure we have a board ID to fetch results for
    if (!currBoardId) return;

    if (!userDocument) {
      throw new Error("User not found!");
    }

    setLoading(true);
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", currBoardId);
    const pinsCollection = query(
      collection(pinboardDocRef, "pins"),
      orderBy("created_at", "asc"),
      limit(pageSize)
    );

    // Cleanup any previous listener
    if (pinsUnsubscribeRef.current) {
      pinsUnsubscribeRef.current();
    }

    // Set up a real-time listener for the pins subCollection
    pinsUnsubscribeRef.current = onSnapshot(pinsCollection, async (snapshot) => {
      const fetchedResults: Pin[] = [];
      snapshot.forEach((docSnap) => {
        fetchedResults.push(docSnap.data() as Pin);
      });

      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      await fetchAndSetPins(fetchedResults);
    });
  });

  const fetchNextPagePinboardResultsForBoard = withSentry(async () => {
    if (!currBoardId || !lastVisible) return;

    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", currBoardId);
    const pinsCollectionQuery = query(
      collection(pinboardDocRef, "pins"),
      orderBy("created_at", "asc"),
      startAfter(lastVisible),
      limit(pageSize)
    );
    const snapshot = await getDocs(pinsCollectionQuery);

    const fetchedResults: Pin[] = [];
    snapshot.forEach((docSnap) => {
      fetchedResults.push(docSnap.data() as Pin);
    });
    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    await fetchAndSetPins([...pinboardResults, ...fetchedResults]);
  });
  // Add other relevant functions for your use case

  const updatePinTitle = withSentry(async (pinboardId: string, pinId: string, title: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }

      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
      const pinRef = doc(pinboardDocRef, "pins", pinId);
      await updateDoc(pinRef, { title: title });

      console.info("Pin title updated successfully!");
      showAlert({ variant: "success", message: _(msg`Pin title updated successfully!`) });
    } catch (error) {
      console.error("Error updating pin title:", error);
      showAlert({
        variant: "error",
        message: _(msg`Error updating pin title! Please try again later.`),
      });
      throw error;
    }
  });

  const updatePinVisualization = withSentry(
    async (
      pinboardId: string,
      pinId: string,
      selectedColumns: TableColumns,
      visualisation: VisualizationTypesEnum
    ) => {
      try {
        if (!userDocument) {
          throw new Error("User not found!");
        }

        let _selectedColumns = visualisation === TABLE ? {} : selectedColumns;

        const organizationRef = doc(db, "organizations", userDocument.organization);
        const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
        const pinRef = doc(pinboardDocRef, "pins", pinId);
        await updateDoc(pinRef, { selectedColumns: _selectedColumns, visualisation });

        console.info("Pin visualisation updated successfully!");
        showAlert({ variant: "success", message: _(msg`Pin visualisation updated successfully!`) });

        await fetchPinboardResultsForBoard();
      } catch (error) {
        console.error("Error updating pin visualisation:", error);
        showAlert({
          variant: "error",
          message: _(msg`Error updating pin visualisation! Please try again later.`),
        });
        throw error;
      }
    }
  );

  return (
    <PinboardContext.Provider
      value={{
        loading,
        boards,
        setBoards,
        currBoardId,
        setCurrBoardId,
        pinboardResults,
        setPinboardResults,
        addNewBoard,
        deleteBoard,
        updateBoardName,
        fetchPinboardResultsForBoard,
        fetchNextPagePinboardResultsForBoard,
        pinToBoard,
        unpinFromBoard,
        updatePinTitle,
        updatePinVisualization,
      }}
    >
      {children}
    </PinboardContext.Provider>
  );
};
