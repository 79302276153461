import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import { useState } from "react";

import { Tooltip } from "@mui/material";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { useUI } from "../../../context/ui";
import { showWhiteBoard } from "../../../helpers";
import { Doowii } from "../../../search/Doowii";
import { Analytics } from "../../../services/analytics/Analytics";
import { LoadingPage } from "../../LoadingPage/LoadingPage";
import { ChatAndResult } from "./ChatAndResult/ChatAndResult";
import * as s from "./ChatView.s";
import { InitialChat } from "./InitialChat/InitialChat";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const ChatView = () => {
  const [loadingText, setLoadingText] = useState("");
  const { chatDrawerOpen, setChatDrawerOpen } = useUI();

  const { userDocument, isCanvasAccount, featureFlags } = useAuth();
  const {
    setCurrentResult: setResults,
    setSearchHistory,
    allResults,
    setAllResults,
    setAnswer,
    loadingThread,
    loading,
    setLoading,
    setStreamLoading,
    currentThread,
    threads,
  } = useChatData();
  const { model } = useUI();
  const { i18n } = useLingui();

  const doowii = new Doowii(
    i18n,
    setSearchHistory,
    setResults,
    setLoading,
    setAllResults,
    setAnswer,
    setLoadingText,
    setStreamLoading,
    userDocument,
    currentThread,
    model,
    allResults,
    isCanvasAccount,
    threads,
    featureFlags
  );

  const shouldShowWhiteboard = showWhiteBoard(allResults) || loading;
  const isInitialView = !shouldShowWhiteboard;

  if (loadingThread) return <LoadingPage />;

  const toggleChatDrawer = () => {
    Analytics.track("Toggle chat drawer", { open: !chatDrawerOpen });
    setChatDrawerOpen(!chatDrawerOpen);
  };

  return (
    <>
      <Tooltip
        title={
          chatDrawerOpen ? <Trans>Close chat history</Trans> : <Trans>Open chat history</Trans>
        }
        placement="bottom"
      >
        <s.ChatDrawerButton
          $chatDrawerOpen={chatDrawerOpen}
          onClick={toggleChatDrawer}
          data-onboarding="drawer-button"
          data-onboarding-isopen={chatDrawerOpen}
        >
          {chatDrawerOpen ? <CloseIcon /> : <HistoryIcon />}
          <s.ChatHistoryText>
            <Trans>Chat history</Trans>
          </s.ChatHistoryText>
        </s.ChatDrawerButton>
      </Tooltip>
      {isInitialView ? (
        <InitialChat doowii={doowii} loadingText={loadingText} />
      ) : (
        <ChatAndResult doowii={doowii} loading={loading} loadingText={loadingText} />
      )}
    </>
  );
};
