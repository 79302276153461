import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { CircularProgress } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { green, red } from "@mui/material/colors";
import { useEffect, useState } from "react";

import { DISSATISFIED, SATISFIED } from "../../../Constants/constants";
import { useChatData } from "../../../context/chat";
import { withSentry } from "../../../helpers/wrapper";
import FeedbackModal from "./FeedbackModal";
import * as s from "./LikeDislikeToggle.s";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const LikeDislikeToggle = () => {
  const { _ } = useLingui();
  const [value, setValue] = useState(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [updatingFeedback, setUpdatingFeedback] = useState(false);

  const { loading, currentResult } = useChatData();
  const { updateSatisfaction } = useChatData();

  const handleChange = withSentry(async (event, newValue) => {
    setValue(newValue);
    if (!newValue) updateSatisfaction(newValue);
    if (newValue) setFeedbackOpen(true);
  });

  useEffect(() => {
    const satisfied = currentResult?.satisfied as {
      like: string | boolean;
      feedback: string;
    };

    //This is to handle the case when the user has already given feedback with the old SATISFIED  value
    if (satisfied?.like === SATISFIED || satisfied?.like === true) {
      setValue(SATISFIED);
      return;
    }
    if (satisfied?.like === DISSATISFIED || satisfied?.like === false) {
      setValue(DISSATISFIED);
      return;
    }
    setValue(null);
  }, [currentResult]);

  return (
    <s.LikeDislikeToggle>
      <Trans>Did we get it right?</Trans> &nbsp;
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label={_(msg`text alignment`)}
        sx={{
          "& .MuiToggleButton-root": {
            height: "1rem",
            width: "2rem",
          },
        }}
        disabled={loading || updatingFeedback}
      >
        <ToggleButton value={SATISFIED} aria-label="satisfied">
          {value === SATISFIED ? (
            <ThumbUpAltIcon sx={{ fontSize: "1rem", color: green[500] }} />
          ) : (
            <ThumbUpOffAltIcon sx={{ fontSize: "1rem", color: green[500] }} />
          )}
        </ToggleButton>
        <ToggleButton value={DISSATISFIED} aria-label="disatisfied">
          {value === DISSATISFIED ? (
            <ThumbDownAltIcon sx={{ fontSize: "1rem", color: red[500] }} />
          ) : (
            <ThumbDownOffAltIcon sx={{ fontSize: "1rem", color: red[500] }} />
          )}
        </ToggleButton>
      </ToggleButtonGroup>
      {updatingFeedback && (
        <>
          {" "}
          &nbsp;
          <CircularProgress size={15} />
        </>
      )}
      <FeedbackModal
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        likeFlag={value === SATISFIED}
        setUpdatingFeedback={setUpdatingFeedback}
      />
    </s.LikeDislikeToggle>
  );
};
