import { FormControl, InputLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { sendInvitationEmail } from "../../services/webserver/emails";
import { Trans } from "@lingui/macro";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#15394680",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  padding: "2rem",
};

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "100%",
    paddingLeft: "10px",
    marginBottom: "-0.7rem",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#153946",
    },
  },
}));

export default function ResendEmailModal({ open, setOpen, currUserInspected }) {
  const handleClose = () => setOpen(false);
  const handleResendEmail = () => {
    sendInvitationEmail(currUserInspected?.email, currUserInspected?.invitationId);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        components={{
          Backdrop: CustomBackdrop,
        }}
      >
        <Box sx={style}>
          <Stack direction="column">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#153946",
                fontSize: "1.5625rem",
                fontWeight: 800,
              }}
            >
              <Trans>Resend Invitation</Trans>
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{
                fontSize: "0.9375rem",
                fontWeight: 300,
                color: "#153946",
              }}
            >
              <Trans>
                Confirm email address below. User will receive an email from pilot@doowii.io to
                setup an account.
              </Trans>
            </Typography>
            <br />
            <FormControl variant="standard">
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  "&.Mui-focused": {
                    color: "#153946",
                  },
                  fontWeight: 600,
                }}
              >
                <Trans>Email:</Trans>
              </InputLabel>
              <CustomInput
                disabled
                id="bootstrap-input"
                placeholder={`${currUserInspected?.email}`}
              />
            </FormControl>{" "}
            <br />
            <br />
            <Stack direction="row" justifyContent={"center"} spacing={4}>
              <Button
                variant="outlined"
                sx={{
                  border: "2px solid #8B8989",
                  color: "#8B8989",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  minWidth: "5.75rem",
                }}
                onClick={handleClose}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  background: "#4EB5CC",
                  minWidth: "5.75rem",
                }}
                onClick={handleResendEmail}
              >
                <Trans>Send</Trans>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
