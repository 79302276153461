import ToggleB from "@mui/material/ToggleButton";
import ToggleButtonG from "@mui/material/ToggleButtonGroup";
import styled from "styled-components";

export const ToggleButtonGroup = styled(ToggleButtonG)``;

export const ToggleButton = styled(ToggleB)`
  text-transform: capitalize !important;
  gap: 5px;
`;
