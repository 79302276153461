import MUIListItemButton from "@mui/material/ListItemButton";
import styled from "styled-components";

export const ListItemButton = styled(MUIListItemButton)`
  padding-top: 4px !important;
  padding-bottom: 4px !important;

  .MuiListItemText-primary {
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
