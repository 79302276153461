import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { Tooltip } from "@mui/material";
import { LOGO_TEXT, PREDICTION_LOGO_TEXT } from "../../Constants/constants";
import { SAVANT } from "../../Constants/features";
import { useAuth } from "../../context";
import { useChatData } from "../../context/chat";
import { useUI } from "../../context/ui";
import { ViewEnum } from "../../context/ui/ui.i";
import { showNavLogo, showWhiteBoard } from "../../helpers/utils";
import ModelSelector from "./ModelSelector";
import * as s from "./Navbar.s";
import { ToggleButtons } from "./ToggleButtons/ToggleButtons";
import { UserProfile } from "./UserProfile/UserProfile";
import { Trans } from "@lingui/macro";

export const NavBar = () => {
  const { featureFlags } = useAuth();
  const { newChat, allResults, loading } = useChatData();
  const { chatDrawerOpen, view, setView, model } = useUI();

  const showNewChatButton = !chatDrawerOpen;

  const showModelSelector = !showWhiteBoard(allResults) && featureFlags?.includes(SAVANT);

  const toNewChat = () => {
    if (view !== ViewEnum.CHAT_VIEW) {
      setView(ViewEnum.CHAT_VIEW);
    }

    newChat();
  };

  return (
    <s.Navbar>
      <s.ButtonsContainer>
        {showNewChatButton && (
          <Tooltip title={<Trans>New chat</Trans>}>
            <div>
              <s.NewChatButton onClick={toNewChat} disabled={loading}>
                <PostAddOutlinedIcon />
              </s.NewChatButton>
            </div>
          </Tooltip>
        )}
        {showModelSelector && <ModelSelector isBottom={false} />}
        <ToggleButtons />
      </s.ButtonsContainer>
      {showNavLogo(view, allResults) && (
        <s.DesktopLogo>
          {model === ViewEnum.QUERY_MODEL ? LOGO_TEXT : <PREDICTION_LOGO_TEXT />}
        </s.DesktopLogo>
      )}
      <s.ResponsiveGroup>
        <s.MobileLogo>
          {" "}
          {model === ViewEnum.QUERY_MODEL ? LOGO_TEXT : <PREDICTION_LOGO_TEXT />}
        </s.MobileLogo>
        <UserProfile />
      </s.ResponsiveGroup>
    </s.Navbar>
  );
};
