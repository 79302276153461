import styled from "styled-components";

export const UserManagement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 10px;
  width: 100%;

  @media (min-width: 1030px) {
    padding: 20px 10vw 0;
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 7px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  p {
    color: #8b8989;
    font-size: 0.9rem;
  }

  @media (min-width: 768px) {
    gap: 20px;

    p {
      font-size: 1rem;
    }
  }
`;
