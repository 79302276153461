import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Clear";
import Edit from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";

import { useChatData } from "../../context/chat";
import { usePinboard } from "../../context/pinboard";
import { withSentry } from "../../helpers/wrapper";
import { Trans } from "@lingui/macro";

export const ChartTitle = ({ results, docId, isFavView }) => {
  const [title, setTitle] = useState(results?.title);
  const [allowEdit, setAllowEdit] = useState(false);

  const { updatePinTitle, currBoardId } = usePinboard();
  const { updateTitle, searchHistory, loading } = useChatData();

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    results?.title ? setTitle(results?.title) : setTitle(searchHistory[searchHistory.length - 1]);
  }, [results]);

  const editAction = withSentry(async () => {
    if (allowEdit) {
      if (isFavView) {
        await updatePinTitle(currBoardId, docId, title);
      } else {
        await updateTitle(title);
      }
      setAllowEdit(false);
    } else {
      setAllowEdit(true);
    }
  });
  return (
    <TextField
      data-testid="chart-title-textfield"
      className="favorite-board-header"
      value={title}
      onChange={handleChange}
      disabled={!allowEdit}
      sx={{ width: "100%", margin: "0 10px" }}
      InputProps={{
        endAdornment: (
          <InputAdornment id="favorite-board-header-btns" position="end">
            <Tooltip title={allowEdit ? <Trans>Save</Trans> : <Trans>Edit</Trans>} placement="top">
              <div>
                <IconButton
                  onClick={editAction}
                  data-testid="chart-title-action"
                  disabled={loading}
                >
                  {!allowEdit ? (
                    <Edit className="favorite-board-header-btn" data-testid="chart-title-edit" />
                  ) : (
                    <SaveIcon
                      className="favorite-board-header-btn"
                      data-testid="chart-title-save"
                    />
                  )}
                </IconButton>
              </div>
            </Tooltip>
            {allowEdit && (
              <Tooltip title={<Trans>Cancel</Trans>} placement="top">
                <div>
                  <IconButton
                    onClick={() => {
                      setAllowEdit(false);
                      setTitle(results?.title);
                    }}
                    data-testid="chart-title-action"
                    disabled={loading}
                  >
                    <CancelIcon className="favorite-board-header-btn" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </InputAdornment>
        ),
        id: "favorite-board-header-input",
      }}
      variant="standard"
    >
      <Edit />
    </TextField>
  );
};
