import Button, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

export const Navbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
  }
`;

export const ResponsiveGroup = styled.div`
  display: flex;
  width: 100%;
  order: -1;
  justify-content: space-between;

  @media (min-width: 768px) {
    justify-content: initial;
    width: auto;
    order: 0;
  }
`;

export const NavLogo = styled.h1`
  margin: 5px;
`;

export const DesktopLogo = styled(NavLogo)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const MobileLogo = styled(NavLogo)`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const NewChatButton = styled(IconButton)`
  background: #153947 !important;
  padding: 12.25px !important;
  line-height: 1 !important;
  border-radius: 4px !important;
  color: white !important;

  &:hover {
    background: #4eb5cc !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #EBEBEB !important;
    opacity: 0.7 !important;
  `}
`;
interface ModelSelectorButtonProps extends ButtonProps {
  isBottom?: boolean; // Make isTop optional or required based on your needs
}

export const ModelSelectorButton = styled(Button)<ModelSelectorButtonProps>`
  line-height: 2.6 !important;
  background: #153947 !important;
  color: white !important;
  &:hover {
    background: #4eb5cc !important;
  }
  text-transform: capitalize !important;
  min-width: 150px !important;
  text-align: left !important;

  ${({ disabled }) =>
    disabled &&
    `
  color: #EBEBEB !important;
  opacity: 0.7 !important;
`}
  ${({ isBottom }) =>
    isBottom &&
    `
    line-height: 34px !important;
    min-width: 80px !important;
  `}
`;
