import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import clsx from "clsx";

import { looksLikeDate } from "../../../helpers";
import { DataTableHeader } from "./DataTableHeader";

export const determineColumnType = (columnName, rows) => {
  for (const row of rows) {
    const value = row[columnName];
    if (value !== null && value !== "" && value !== undefined) {
      if (!isNaN(value) && !isNaN(parseFloat(value))) return "number";
      if (looksLikeDate(value)) return "timestamp";
      return "string";
    }
  }
  return "string";
};

export const determineColumns = (result, pageInfo, selectedColumns, handleColumnHeaderClick) => {
  if (result?.data?.isDemo === "PREDICTION") {
    return (
      result?.data?.headers?.map((header) => {
        return {
          field: header,
          headerName: header,
          flex: 1,
          headerClassName: "column-header",
          renderCell: (params) => {
            return params.field === "2023" ? (
              <div>
                <span>{params.value}</span>{" "}
                {params.value > params.row["2022"] ? (
                  <ArrowUpward sx={{ color: "green", fontSize: "0.8rem" }} />
                ) : (
                  <ArrowDownward sx={{ color: "red", fontSize: "0.8rem" }} />
                )}
              </div>
            ) : (
              params.value
            );
          },
        };
      }) || []
    );
  }

  if (result?.data?.isDemo === "HIGHLIGHT") {
    return (
      result?.data?.headers?.map((header) => {
        return {
          field: header,
          headerName: header,
          flex: 1,
          headerClassName: "column-header",
          cellClassName: (params) => {
            if (params.value == null || params.field === "School") {
              return "";
            }

            return clsx("row", {
              lowest: params.value === result.data.ethnicityScores[header][0],
              lower: params.value === result.data.ethnicityScores[header][1],
              low: params.value === result.data.ethnicityScores[header][2],
              highest:
                params.value ===
                result.data.ethnicityScores[header][result.data.ethnicityScores[header].length - 1],
              higher:
                params.value ===
                result.data.ethnicityScores[header][result.data.ethnicityScores[header].length - 2],
              high:
                params.value ===
                result.data.ethnicityScores[header][result.data.ethnicityScores[header].length - 3],
            });
          },
        };
      }) || []
    );
  }

  return (
    pageInfo.headers?.map((header, index, array) => {
      const isLastColumn = index === array.length - 1;

      return {
        field: header,
        headerName: header,
        headerClassName:
          header in (selectedColumns || []) ? "column-header-selected" : "column-header",
        cellClassName: (params) => {
          return clsx("row", {
            selected: Object.keys(selectedColumns || []).includes(params.field),
          });
        },
        renderHeader: (params) => (
          <DataTableHeader column={params.colDef} handleColumnSelect={handleColumnHeaderClick} />
        ),
        width: isLastColumn ? undefined : 150,
        flex: isLastColumn ? 1 : undefined,
        minWidth: isLastColumn ? 200 : 100,
      };
    }) || []
  );
};

export const handleColumnResize = (params, columnWidthsRef) => {
  const field = params?.colDef?.headerName;
  const width = params?.width;

  columnWidthsRef.current[field] = width;
};

export const handleColumnOrderChange = (params, columnOrderRef) => {
  columnOrderRef.current.push({
    field: params?.column?.field,
    targetIndex: params?.targetIndex,
    oldIndex: params?.oldIndex,
  });
};

export const applyColumnWidthsFromRef = (columns, columnWidthsRef) => {
  return columns.map((col) => {
    const storedWidth = columnWidthsRef.current[col.field];
    if (storedWidth) {
      return { ...col, width: storedWidth };
    }
    return col;
  });
};

function moveElementInArray(array, fromIndex, toIndex) {
  const element = array.splice(fromIndex, 1)[0];
  array.splice(toIndex, 0, element);
}

export const applyColumnOrderFromRef = (columns, columnOrderRef) => {
  columnOrderRef.current.forEach((change) => {
    const actualOldIndex = columns.findIndex((col) => col.field === change.field);
    if (actualOldIndex === -1) {
      return;
    }

    if (actualOldIndex !== change.targetIndex) {
      moveElementInArray(columns, actualOldIndex, change.targetIndex);
    }
  });
  return columns;
};
