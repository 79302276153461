import * as Sentry from "@sentry/react";

import { post } from "./base";

export const fetchEmbeddings = async (question) => {
  try {
    const response = await post("/embeddings", {
      question: question,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching embeddings", error);
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      Sentry.captureException(error);
    });
  }
};

export const fetchTitleFromServer = async (questions) => {
  try {
    const response = await post("/fetch_title", {
      questions: questions,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching thread title", error);

    // Set the level to warning to avoid reporting this low-urgency error to PagerDuty
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      Sentry.captureException(error);
    });
  }
};

export const updateChartConfig = async (chartConfig, userRequest, sqlQuery) => {
  try {
    const response = await post("/update_chart_config", {
      current_config: chartConfig,
      user_request: userRequest,
      sql_query: sqlQuery,
    });

    const updatedConfig = response.data.updated_config;
    const suggestedQuestions = response.data.suggested_questions;
    return { updatedConfig, suggestedQuestions };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const isChartingQuestion = async (question) => {
  try {
    const response = await post("/is_chart_type", {
      question: question,
    });
    return response.data;
  } catch (error) {
    console.error("Error checking if question is charting", error);
    Sentry.captureException(error);
  }
};
