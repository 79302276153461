import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Theme } from "../../../styled";
import * as s from "./EmptySqlDisplay.s";
import giraffe from "./giraffe.png";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const EmptySqlDisplay = () => {
  const { _ } = useLingui();
  return (
    <s.Container>
      <s.ContentWrapper>
        <s.Image src={giraffe} alt={_(msg`Chatting with Doowii`)} width={250} />
        <s.MessageWrapper>
          <s.MessageContent>
            <span>
              <Trans>
                Hi there! 👋 It looks like there's no data to display here just yet. Not to worry!
                Click the
              </Trans>
              {
                <AutoStoriesIcon
                  sx={{
                    color: Theme.core.colors.darkBlue,
                  }}
                />
              }{" "}
              <Trans>button above to explore the data schema.</Trans>
            </span>{" "}
            <br />
            <br />
            <Trans>
              If you have any questions or need some guidance, I'm here to help. Let's embark on
              this journey together!
            </Trans>
          </s.MessageContent>
        </s.MessageWrapper>{" "}
      </s.ContentWrapper>
    </s.Container>
  );
};

export default EmptySqlDisplay;
