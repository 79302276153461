import { post } from "./base";

export const reportNegativeFeedback = async (question: string, question_id: string) => {
  try {
    const response = await post("/negative_feedback", {
        question: question,
        question_id: question_id,
    });
    return response;
  } catch (error) {
    console.error("Error sending feedback", error);
    throw error;
  }
};
