import { Search } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { usePinboard } from "../../../context/pinboard";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { showPinboardOnboarding } from "../../../helpers/onboarding";
import { Analytics } from "../../../services/analytics/Analytics";
import { Loading } from "../../LoadingSpinner/Loading";
import { EmptyDisplay } from "../EmptyDisplay/EmptyDisplay";
import { FavoriteChart } from "../FavoriteChart/FavoriteChart";
import { FavoriteFullScreenModal } from "./FavoriteFullScreenModal/FavoriteFullScreenModal";
import * as s from "./FavoritesContent.s";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

export const FavoritesContent = ({ setIsPinboardCreateModalOpen }) => {
  const { _ } = useLingui();
  const { boards, currBoardId, pinboardResults, loading } = usePinboard();
  const [pinboardFilterQuery, setPinboardFilterQuery] = useState("");

  useEffect(() => {
    return () => {
      setPinboardFilterQuery("");
    };
  }, [currBoardId]);

  useEffect(() => {
    if (pinboardResults.length) {
      setTimeout(() => {
        showPinboardOnboarding();
      }, 300); // let the UI accommodate before highlighting
    }
  }, [pinboardResults]);

  const { setView } = useUI();

  const [fullscreenModalPinId, setFullscreenModalPinId] = useState<string | null>(null);

  useEffect(() => {
    if (fullscreenModalPinId) {
      Analytics.track("Pinboard full screen");
    }
  }, [fullscreenModalPinId]);

  if (loading) return <Loading />;

  if (boards.size === 0 || currBoardId == null)
    return (
      <EmptyDisplay
        actionLabel={_(msg`adding a new pinboard`)}
        action={() => setIsPinboardCreateModalOpen(true)}
      />
    );

  if (!pinboardResults || pinboardResults.length === 0)
    return (
      <EmptyDisplay
        actionLabel={_(msg`adding chats to this pinboard`)}
        action={() => setView(ViewEnum.CHAT_VIEW)}
      />
    );

  const currentPinboardName = boards.get(currBoardId)?.name;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          padding: "12px 24px",
        }}
        width={"100%"}
      >
        <TextField
          data-onboarding="pinboard-search-filter"
          fullWidth
          id="pinboard-search"
          label={_(msg`Search Pins within ${currentPinboardName}`)}
          variant="standard"
          value={pinboardFilterQuery}
          onChange={(e) => setPinboardFilterQuery(e.target.value)}
        />
        <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
      </Box>

      <s.FavoritesContent>
        {pinboardResults
          .filter((item) => item?.title?.toLowerCase().includes(pinboardFilterQuery.toLowerCase()))
          .map((pin, idx) => {
            const invalidPin =
              !pin || pin.visualisation === "ERROR" || pin.visualisation === "EMPTY_ROWS";

            if (invalidPin) return null;

            return (
              <FavoriteChart
                key={idx}
                pin={pin}
                handleOpenModal={() => setFullscreenModalPinId(pin.id)}
              />
            );
          })}
      </s.FavoritesContent>
      {fullscreenModalPinId && (
        <FavoriteFullScreenModal
          pinId={fullscreenModalPinId}
          handleClose={() => setFullscreenModalPinId(null)}
        />
      )}
    </>
  );
};
