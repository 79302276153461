import "firebase/firestore";
import { useEffect, useState } from "react";

import { ThemeProvider } from "styled-components";
import { ConversationKey, DEFAULT_PINBOARD } from "../../Constants/constants";
import { useAuth } from "../../context";
import { AlertProvider } from "../../context/alert";
import { ChartProvider } from "../../context/chart";
import { ChatDataProvider } from "../../context/chat";
import { PinboardProvider } from "../../context/pinboard";
import { useUI } from "../../context/ui";
import { ConversationMgr } from "../../helpers/slidingWindow";
import { Theme } from "../../styled";
import InstructionDialog from "../Instructions/InstructionDialog";
import { NavBar } from "../Navbar/Navbar";
import { Register } from "../Register/Register";
import { ChatDrawer } from "../Threads/ChatDrawer";
import { Main } from "./Main";
import "./MainPage.css";
import * as s from "./MainPage.s";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

/**
 * TODO: Move this to registration flow
 * Board map stores <key, value> pairs of <boardId, boardObject>
 * boardObject contains the following fields:
 * - id: string
 * - name: string
 * - boards: array of result id's
 * - color: string
 */
let defaultPinboard = new Map();
defaultPinboard.set(DEFAULT_PINBOARD, {
  id: 0,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  name: "Default", // didn't translate because not used anywhere?
  pins: [],
  color: "default",
});

export const MainPage = () => {
  const { _ } = useLingui();
  const [instructionOpen, setInstructionOpen] = useState(false);

  const { userDocument } = useAuth();
  const { chatDrawerOpen } = useUI();

  useEffect(() => {
    return () => {
      const sqlMessage = {
        role: "system",
        content: _(msg`You are an AI assistant trained to generate SQL.`),
      };
      /**
       * need to call clear and re-populate conversations
       */
      ConversationMgr.get(ConversationKey.SQL).clear();
      ConversationMgr.get(ConversationKey.SQL).add(sqlMessage);
    };
  }, []);

  if (userDocument === null) return <Register />;

  if (userDocument?.registration.status === "disabled")
    return (
      <p>
        <Trans>Your account has been deleted. Please contact your admin</Trans>
      </p>
    );

  return (
    <ThemeProvider theme={Theme}>
      <AlertProvider>
        <ChatDataProvider>
          <ChartProvider>
            <PinboardProvider>
              <s.MainPage open={chatDrawerOpen}>
                <NavBar />
                <ChatDrawer />
                <Main />
              </s.MainPage>
              <div id="modal-root" />
              <InstructionDialog open={instructionOpen} setOpen={setInstructionOpen} />
            </PinboardProvider>
          </ChartProvider>
        </ChatDataProvider>
      </AlertProvider>
    </ThemeProvider>
  );
};
