import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopIcon from "@mui/icons-material/Stop";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Analytics } from "../../../services/analytics/Analytics";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const VoiceToText = ({ setQuery, loading }) => {
  const { _ } = useLingui();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    Analytics.track("Voice to Text");
    setQuery(transcript);
  }, [transcript, setQuery]);

  useEffect(() => {
    if (loading) {
      SpeechRecognition.stopListening();
    }
  }, [loading]);

  const startListening = () => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  const determineTitle = () => {
    if (!browserSupportsSpeechRecognition) {
      return _(
        msg`Voice Search is not supported in this browser. Use Google Chrome for the best experience!`
      );
    } else if (loading) {
      return _(msg`Please wait for the search to complete before starting a new search.`);
    } else if (listening) {
      return _(msg`Stop Listening`);
    } else {
      return _(msg`Start Listening`);
    }
  };

  return (
    <Tooltip title={determineTitle()} followCursor>
      <button
        type="button"
        onClick={listening ? stopListening : startListening}
        disabled={loading || !browserSupportsSpeechRecognition}
      >
        {listening ? <StopIcon /> : <KeyboardVoiceIcon />}
      </button>
    </Tooltip>
  );
};

export default VoiceToText;
