import React, { useState, useMemo, useEffect } from "react";
import { ChartType } from "../../types";
import { Button, Checkbox, CheckboxGroup, Text } from "@instructure/ui";
import { usePinboard } from "../../../context/pinboard";
import { useUI } from "../../../context/ui";
import { ParentDocTypeEnum } from "../../../api/retriever.i";
import { ViewEnum } from "../../../context/ui/ui.i";
import { useChatData } from "../../../context/chat";
import { Chart } from "../../../helpers/Chart";
import { canvas } from "@instructure/ui-themes";
import { TableColumnType } from "../../../context/chart/chart.i";
import ChartRenderer from "../ChartRenderer/ChartRenderer";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  getChartTitle,
  getColumnLabel,
  renderChartIcon,
  CanvasDataType,
  getAvailableDataTypes,
  availableChartsForCanvas,
} from "./helpers";
import { determineColumnType } from "../DataTable/helpers";
import { IconArrowOpenStartLine } from "@instructure/ui-icons";
import { Result } from "../../../context/chat/chat.i";
import { Pin, VisualizationTypesEnum } from "../../../context/pinboard/pinboard.i";

export interface ChartColumnType extends TableColumnType {
  length: number;
  name: string;
  type: CanvasDataType;
  isSelected: boolean;
}

export interface ChartProps {
  data: any;
  selectedColumns: Record<string, TableColumnType>;
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  sortModel: any;
  totalRows: number;
  chartType: ChartType;
}

interface ChartBuilderProps {
  result: Result | Pin;
  didCreateChart?: (chartProps: ChartProps) => void;
}

export const allCanvasCharts = [
  ChartType.BAR_CHART,
  ChartType.PIE_CHART,
  ChartType.LINE_CHART,
  ChartType.SCATTER_CHART,
];

export const ChartBuilder: React.FC<ChartBuilderProps> = ({ result, didCreateChart }) => {
  const { _ } = useLingui();
  const [columns, setColumns] = useState<ChartColumnType[]>([]);
  const [selectedChart, setSelectedChart] = useState<ChartType | null>(null);
  const [availableCharts, setAvailableCharts] = useState<ChartType[]>([]);
  const [pageData, setPageData] = useState([]);
  const [showChart, setShowChart] = useState(false);

  const { currBoardId } = usePinboard();
  const { dataMap, currentThread } = useChatData();
  const { view } = useUI();
  const parentDocId = view === ViewEnum.CHAT_VIEW ? currentThread : currBoardId;
  const parentDocType =
    view === ViewEnum.CHAT_VIEW ? ParentDocTypeEnum.THREAD : ParentDocTypeEnum.PINBOARD;
  const sortModel = JSON.parse(localStorage.getItem(`sortModel-${result.id}`) || "[]");

  useEffect(() => {
    const resultObject = dataMap?.[result?.id];
    if (resultObject) {
      const rows = Object.values(resultObject.rows);
      const dataTypes = resultObject.pageInfo.dataTypes;
      const totalRowCount = resultObject.pageInfo.totalRowCount;
      const selectedColumns = (result as Pin)?.selectedColumns;
      const allColumns: ChartColumnType[] = Object.entries(dataTypes).map(
        ([columnName, columnType]) => ({
          length: totalRowCount,
          name: columnName,
          // TODO: use chart config instead of this
          type: determineColumnType(columnName, rows),
          isSelected: selectedColumns?.[columnName] ? true : false,
        })
      );
      setColumns(allColumns);
      const charts = availableChartsForCanvas(allColumns);
      setAvailableCharts(charts);

      const graphData = Chart.buildFromPin(result as Pin, dataMap, totalRowCount);
      setPageData(graphData);

      if ((result as Pin)?.visualisation !== VisualizationTypesEnum.TABLE) {
        //TODO: Standardize chart types
        setSelectedChart((result as Pin)?.visualisation as unknown as ChartType);
        setShowChart(true);
      } else {
        setSelectedChart(null);
        setShowChart(false);
      }
    }
  }, [dataMap, result]);

  const handleChartSelect = (chart: ChartType) => {
    if (chart !== selectedChart) {
      setSelectedChart(chart);
      setColumns((cols) => cols.map((col) => ({ ...col, isSelected: false })));
    }
  };

  const handleColumnSelect = (selectedValues: string[]) => {
    setColumns((cols) =>
      cols.map((col) =>
        selectedValues.includes(col.name)
          ? { ...col, isSelected: true }
          : { ...col, isSelected: false }
      )
    );
  };

  const selectedColumns = useMemo(() => columns.filter((col) => col.isSelected), [columns]);

  const availableColumns = useMemo(() => {
    if (!selectedChart) return columns;

    const selectedTypes = selectedColumns.map((col) => col.type);
    if (selectedTypes.length === 2) {
      return selectedColumns;
    }
    const availableDataTypes = getAvailableDataTypes({ selectedTypes, chart: selectedChart });
    return columns.filter((col) => availableDataTypes.includes(col.type));
  }, [columns, selectedChart, selectedColumns]);

  const chartProps: ChartProps = useMemo(() => {
    let totalRows = 0;
    const selectedColumnObjects: ChartProps["selectedColumns"] = selectedColumns.reduce(
      (acc, col) => {
        totalRows = col.length;
        acc[col.name] = {
          type: col.type,
          name: col.name,
          length: col.length,
        };
        return acc;
      },
      {}
    );

    console.log(result, "Sjpw ,e");

    return {
      data: pageData,
      selectedColumns: selectedColumnObjects,
      docId: result.id,
      parentDocId,
      parentDocType,
      sortModel,
      totalRows,
      chartType: selectedChart,
    };
  }, [pageData, selectedColumns, parentDocId, parentDocType, sortModel]);

  if (showChart) {
    return (
      <div className="flex flex-grow flex-col pt-6">
        <div className="mb-4 w-fit">
          <Button
            withBackground={false}
            color="primary"
            renderIcon={<IconArrowOpenStartLine />}
            onClick={() => setShowChart(false)}
            themeOverride={{
              borderWidth: "0",
            }}
          >
            <Trans>Chart Builder</Trans>
          </Button>
        </div>
        <ChartRenderer chartProps={chartProps} />
      </div>
    );
  }

  return (
    <div className="flex max-h-32 flex-col px-4 pt-6">
      <Text weight="bold">
        <Trans>STEP 1: Select an applicable data visualization</Trans>
      </Text>
      <div className="my-4 flex flex-wrap gap-2">
        {allCanvasCharts.map((chart) => (
          <Button
            key={chart}
            onClick={() => handleChartSelect(chart)}
            className={selectedChart === chart ? "selected" : ""}
            renderIcon={renderChartIcon({ type: chart })}
            disabled={!availableCharts.includes(chart)}
            themeOverride={{
              secondaryBorderColor:
                selectedChart === chart ? canvas.colors.border.borderBrand : "auto",
              borderWidth: "0.1rem",
            }}
          >
            {_(getChartTitle(chart))}
          </Button>
        ))}
      </div>

      {selectedChart && (
        <>
          <CheckboxGroup
            name="columns"
            description={
              <Text weight="bold">
                <Trans>STEP 2: Select the applicable data types</Trans>
              </Text>
            }
            value={selectedColumns.map((col) => col.name)}
            onChange={(values) => handleColumnSelect(values as string[])}
          >
            {columns.map((column) => {
              return (
                <Checkbox
                  key={column.name}
                  disabled={!column.isSelected && !availableColumns.includes(column)}
                  label={getColumnLabel(column)}
                  value={column.name}
                />
              );
            })}
          </CheckboxGroup>
          <div className="my-4 w-fit">
            <Button
              onClick={() => {
                setShowChart(true);
                if (didCreateChart) {
                  didCreateChart(chartProps);
                }
              }}
              disabled={selectedColumns.length < 2}
            >
              <Trans>Generate</Trans>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
