import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useAlert } from "../../../context/alert";
import { useChatData } from "../../../context/chat";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

export default function FeedbackModal({ open, setOpen, likeFlag, setUpdatingFeedback }) {
  const { _ } = useLingui();
  const { updateSatisfaction } = useChatData();
  const handleClose = () => {
    // @ts-ignore
    updateSatisfaction(likeFlag, "", !likeFlag);
    setOpen(false);
  };

  const handleSubmission = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const feedback = formJson.feedback;
    try {
      setUpdatingFeedback(true);
      await updateSatisfaction(likeFlag, feedback, !likeFlag);
      showAlert({ variant: "success", message: _(msg`Thanks for your feedback!.`) });
    } catch (error) {
      showAlert({ variant: "error", message: _(msg`Error updating feedback, please try again!.`) });
    } finally {
      setUpdatingFeedback(false);
      setOpen(false);
    }
  };

  const { showAlert } = useAlert();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmission,
      }}
    >
      <DialogTitle>
        <Trans>Feedback (Optional)</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            Could you please provide us with your feedback? That would be very helpful for us to
            improve our service.
          </Trans>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="feedback"
          label={_(msg`Feedback`)}
          fullWidth
          multiline
          rows={2}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
        <Button type="submit">
          <Trans>Save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
