import { CloseButton, Heading, Modal, Text } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";

interface ItemLineProps {
  shortcut: string;
  description: string;
}

const ItemLine: FC<ItemLineProps> = ({ shortcut, description }) => {
  return (
    <li className="flex border-t border-[#C7CDD1] py-1">
      <div className="w-32">
        <Text weight="bold" size="small">
          {shortcut}
        </Text>
      </div>
      <div className="mr-8">
        <Text size="small">{description}</Text>
      </div>
    </li>
  );
};

interface KeyboardShortcutsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const KeyboardShortcutsModal: FC<KeyboardShortcutsModalProps> = ({ open, setOpen }) => {
  const { _ } = useLingui();
  const onClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onDismiss={onClose}
      size="auto"
      label={_(msg`Modal Dialog: Keyboard Shortcuts`)}
      shouldCloseOnDocumentClick
    >
      <Modal.Header>
        <CloseButton
          placement="end"
          offset="medium"
          onClick={onClose}
          screenReaderLabel={_(msg`Close`)}
          size="medium"
        />
        <Heading margin="0 medium 0 0">
          <Trans>Keyboard Shortcuts</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Text weight="bold" size="small">
          <Trans>Insights Chat Tab</Trans>
        </Text>
        <ul className="mb-4 mt-2">
          <ItemLine shortcut="CTRL+SHIFT+2" description={_(msg`Select "Pinboard" tab`)} />
          <ItemLine shortcut="CTRL+1" description={_(msg`Focus chat input`)} />
          <ItemLine shortcut="CTRL+2" description={_(msg`Create new chat`)} />
          <ItemLine shortcut="CTRL+3" description={_(msg`Show previous chat`)} />
          <ItemLine shortcut="CTRL+4" description={_(msg`Show next chat`)} />
          <ItemLine shortcut="CTRL+5" description={_(msg`Add result to pinboard`)} />
        </ul>
        <Text weight="bold" size="small">
          <Trans>Pinboard Tab</Trans>
        </Text>
        <ul className="mb-4 mt-2">
          <ItemLine shortcut="CTRL+SHIFT+1" description={_(msg`Select "Insight Chats" tab`)} />
          <ItemLine shortcut="CTRL+1" description={_(msg`Focus search input`)} />
          <ItemLine shortcut="CTRL+2" description={_(msg`Create new pinboard`)} />
          <ItemLine shortcut="CTRL+3" description={_(msg`Show previous pinboard`)} />
          <ItemLine shortcut="CTRL+4" description={_(msg`Show next pinboard`)} />
        </ul>
      </Modal.Body>
    </Modal>
  );
};
