import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { Fragment } from "react";
import { useChatData } from "../../../../context/chat";
import { useUI } from "../../../../context/ui";
import { ViewEnum } from "../../../../context/ui/ui.i";
import { withSentry } from "../../../../helpers/wrapper";
import useNotification from "./useNotification";
import { Delete } from "@mui/icons-material";
import { Trans } from "@lingui/macro";

export default function NotificationList({ anchorEl, open, handleClose }) {
  const { notifications, updateNotificationStatus } = useNotification();
  const { selectThread } = useChatData();
  const { setView } = useUI();

  const handleViewChat = withSentry(async (notification) => {
    const { chat_id, thread_id } = notification;
    await selectThread(thread_id, chat_id);
    setView(ViewEnum.CHAT_VIEW);

    await updateNotificationStatus(chat_id);
    handleClose();
  });

  const clearAll = withSentry(async () => {
    for (let notification of notifications) {
      await updateNotificationStatus(notification.chat_id);
    }
  });
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ p: 1, background: "#003a47", width: "100%" }}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography variant="h6" color={"white"} padding="0.25rem 0 0 0.25rem">
            <Trans>Notifications</Trans>
          </Typography>
          <div>
            <Tooltip title={<Trans>Clear All Notifications</Trans>} placement="right">
              <IconButton onClick={clearAll}>
                <Delete sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </div>
        </Stack>
      </Box>
      <List sx={{ width: 400 }}>
        {notifications.length > 0 ? (
          <>
            {notifications.map((notification, index) => (
              <Fragment key={notification.chat_id}>
                <ListItem key={notification.chat_id}>
                  <Typography variant="body1">
                    <Trans>Great news! We've got the answer for:</Trans> &nbsp;
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleViewChat(notification);
                      }}
                      underline="hover"
                      sx={{ color: "#3ca0c3", fontWeight: "bold" }}
                    >
                      {notification.query}
                    </Link>
                    . <Trans>Go check it out!</Trans>
                  </Typography>
                </ListItem>

                {index !== notifications.length - 1 && <Divider />}
              </Fragment>
            ))}
          </>
        ) : (
          <ListItem>
            <Trans>You're all caught up with notifications!</Trans>
          </ListItem>
        )}
      </List>
    </Popover>
  );
}
