import { Box, Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DEMO_ACCOUNT, INVESTOR_ACCOUNT } from "../../Constants/features";
import { useAuth } from "../../context";
import { useIntegration } from "../../context/integrations";
import AddIntegrationsModal from "./AddIntegrationsModal";
import DeleteIntegationsModal from "./DeleteIntegrationsModal";
import Header from "./Header";
import Row from "./Row";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";

export default function Integrations() {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);
  const { sources } = useIntegration();
  const [latestDocument, setLatestDocument] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  const { featureFlags } = useAuth();
  const isDemoAccount = featureFlags.includes(DEMO_ACCOUNT);
  const isInvestorAccount = featureFlags.includes(INVESTOR_ACCOUNT);
  const HEADERS = [
    msg`Name`,
    msg`Data Source`,
    msg`Authorized Access`,
    msg`Status`,
    msg`Last Updated`,
    msg`Actions`,
  ];

  const sourcesIsEmpty = sources === undefined || sources === null || sources.length === 0;

  useEffect(() => {
    if (sourcesIsEmpty) return;
    setLatestDocument(
      sources.reduce((latest, current) => {
        const latestTimestamp =
          latest.created_at.seconds * 1000000000 + latest.created_at.nanoseconds;
        const currentTimestamp =
          current.created_at.seconds * 1000000000 + current.created_at.nanoseconds;
        return currentTimestamp > latestTimestamp ? current : latest;
      })
    );
  }, [sources]);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSourceToDelete(null);
  };

  const handleOpenDeleteModal = (source) => {
    setDeleteModalOpen(true);
    setSourceToDelete(source);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          paddingLeft: "10vw",
          paddingRight: "10vw",
          paddingTop: "5vw",
        }}
      >
        {" "}
        <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
          <Stack direction="column" justifyContent="space-between" alignItems={"flex-start"}>
            <span
              style={{
                color: "#153946",
                fontSize: "1.5625rem",
                font: "Inter",
                fontWeight: 900,
              }}
            >
              <Trans>Data Integrations</Trans>
            </span>

            {isDemoAccount && isInvestorAccount && (
              <span
                style={{
                  color: "#153946",
                  fontSize: "0.75rem",
                  font: "Inter",
                  fontWeight: 300,
                }}
              >
                <Trans>Sorry, this feature is not available in the demo version of Doowii.</Trans>
              </span>
            )}
          </Stack>

          <Button
            data-testid="open-integrations-modal-button"
            disabled={isDemoAccount && isInvestorAccount}
            sx={{
              background: "#4EB5CC",
              fontWeight: "700",
              textTransform: "capitalize",
              "&:hover": { background: "#4EB5CC" },
            }}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <Trans>Add More Integrations</Trans>
          </Button>
        </Stack>
        <br />
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#F5F5F5",
            padding: "0.5rem",
            borderRadius: "7px",
            position: "sticky",
            top: 0,
          }}
        >
          <Grid container>
            {HEADERS.map((header, i) => {
              return <Header key={`header-${i}`} header={_(header)} />;
            })}
          </Grid>
        </Box>
        <br />
        {!sourcesIsEmpty ? (
          isDemoAccount && latestDocument ? (
            <Row
              key={latestDocument.source}
              source={latestDocument}
              index={0}
              openDeleteModel={() => handleOpenDeleteModal(latestDocument)}
            />
          ) : (
            sources.map((source, index) => (
              <Row
                key={source.source}
                source={source}
                index={index}
                openDeleteModal={() => handleOpenDeleteModal(source)}
              />
            ))
          )
        ) : (
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F5F5F5",
              padding: "0.5rem",
              borderRadius: "7px",
              minHeight: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: "#8B8989", fontSize: "1rem" }}>
              <Trans>No data to display yet.</Trans>
            </div>
            <br />
            <div style={{ color: "#8B8989", fontSize: "1rem" }}>
              <Trans>Click on Add More Integrations to get started.</Trans>
            </div>
          </Box>
        )}
        <br />
        <Box sx={{ minHeight: "30vh" }} />
      </Grid>
      <AddIntegrationsModal open={open} setOpen={setOpen} isSourcesEmpty={sourcesIsEmpty} />
      <DeleteIntegationsModal
        open={deleteModalOpen}
        closeModal={handleCloseDeleteModal}
        source={sourceToDelete}
      />
    </>
  );
}
