import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";

import { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface LoadingIndicatorProps {
  progressValue: number;
  loadingText: string;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#003a47" : "#308fe8",
  },
}));

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ progressValue, loadingText }) => {
  const { _ } = useLingui();
  const [onboardingText, setOnboardingText] = useState<string>("");
  const onboardingTexts = [
    msg`Don't forget to pin the results you'd like to access later. Just open the chart menu, click 'Add to Pinboard', and the results will be saved to the Pinboard for easy access.`,
    msg`Simply tap the new chat button above the chat window to kick off a new conversation! We can't wait to see what you'll chat about next.`,
    msg`To download your results as a CSV file, just click the three dots and hit the 'Download CSV' button. It's that easy!`,
    msg`Ready to see the chart? Just select the columns you want to visualize, then choose your desired chart type. Let's get started!`,
    msg`Help us improve!  After reviewing the results, please provide feedback with a thumbs up or thumbs down.`,
  ];
  useEffect(() => {
    const index = Math.floor(progressValue / 20);
    if (index < onboardingTexts.length) {
      setOnboardingText(_(onboardingTexts[index]));
    }
  }, [progressValue]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 700, textAlign: "start" }}>
        {" "}
        <p className="loading-spinner-text">{loadingText}</p>
        <LinearProgressWithLabel value={progressValue} />
        <br />
        <p className="loading-tips-text">{onboardingText}</p>
      </Box>
    </div>
  );
};
