import { post } from "./base";

export const getVisualizationSuggestion = async (sql: string, user_request: string) => {
  try {
    const response = post("/suggest_visualization", {
      sql_query: sql,
      user_request: user_request,
    });
    return response;
  } catch (error) {
    console.error("Error getting visualization suggestion", error);
  }
};
