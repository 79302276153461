import { collection, getDocs, query, where } from "firebase/firestore";
import { ReactNode, createContext, useCallback, useContext, useState } from "react";

import { db } from "../../services/firebase";
import { useAuth } from "../auth"; // Import the useAuth hook
import { User, UsersContextI } from "./users.i.js";

const initialState: UsersContextI = {
  organizationUsers: [],
  setOrganizationUsers: () => {},
  disabledUsers: [],
  clearUsersData: () => {},
  fetchUsersInOrganization: () => {},
  fetchingOrganizationUsers: false,
};

export const OrganizationUsersContext = createContext(initialState);

export default function useOrganizationUsers() {
  const { userDocument } = useAuth();
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const [disabledUsers, setDisabledUsers] = useState<User[]>([]);
  const [fetchingOrganizationUsers, setFetchingOrganizationUsers] = useState(false);

  const fetchUsersInOrganization = useCallback(async () => {
    if (!userDocument || !userDocument.organization) return;

    setFetchingOrganizationUsers(true);
    try {
      const usersCollectionRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "users"
      );
      const orgUsersQuery = query(
        usersCollectionRef,
        where("organization", "==", userDocument.organization),
        where("registration.status", "!=", "disabled")
      );
      const orgUsersSnapshot = await getDocs(orgUsersQuery);

      const invitationsCollectionRef = collection(db, "invitations");
      const orgInvitationsQuery = query(
        invitationsCollectionRef,
        where("organization", "==", userDocument.organization)
      );
      const orgInvitationsSnapshot = await getDocs(orgInvitationsQuery);

      const disabledCollectionRef = collection(db, "deleted");
      const disabledUsersQuery = query(
        disabledCollectionRef,
        where("organization", "==", userDocument.organization)
      );
      const disabledUsersSnapshot = await getDocs(disabledUsersQuery);

      // Merge users and invitations
      const data: User[] = [
        ...orgUsersSnapshot.docs.map((doc) => ({
          ...(doc.data() as User),
        })),
        ...orgInvitationsSnapshot.docs.map((doc) => ({
          ...(doc.data() as User),
          firstName: "N/A",
          lastName: "",
          LSO: "N/A",
          registration: { status: "pending", date: doc.data().timeAdded },
        })),
      ];

      const disabledData: User[] = [
        ...disabledUsersSnapshot.docs.map((doc) => ({ ...(doc.data() as User) })),
      ];

      setOrganizationUsers(data);
      setDisabledUsers(disabledData);
    } catch (e) {
      console.error(e);
    } finally {
      setFetchingOrganizationUsers(false);
    }
  }, [userDocument]);

  const clearUsersData = useCallback(() => {
    setOrganizationUsers([]);
    setDisabledUsers([]);
  }, []);

  return {
    organizationUsers,
    setOrganizationUsers,
    disabledUsers,
    clearUsersData,
    fetchUsersInOrganization,
    fetchingOrganizationUsers,
  };
}

export function OrganizationUsersProvider({ children }: { children: ReactNode }) {
  const organizationUsers = useOrganizationUsers();

  return (
    <OrganizationUsersContext.Provider value={organizationUsers}>
      {children}
    </OrganizationUsersContext.Provider>
  );
}

export const useOrgUsers = () => useContext(OrganizationUsersContext);
