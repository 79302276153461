import { msg } from "@lingui/macro";

export const pinboardOnboarding = {
  id: "pinboardOnboarding",
  steps: [
    {
      element: `[data-onboarding="select-pinboard-button"]`,
      title: msg`Pinboard selection`,
      intro: msg`Switch between your saved pinboards here. Each one is a unique journey through your data explorations.`,
    },
    {
      element: `[data-onboarding="new-pinboard"]`,
      title: msg`New Beginnings`,
      intro: msg`Feeling creative? Start a new pinboard and embark on a fresh data discovery journey. Blank canvases await!`,
    },
    {
      element: `[data-onboarding="pinboard-search-filter"]`,
      title: msg`Find with Ease`,
      intro: msg`Search your pins by name here. It's like a quick treasure hunt for your most valued insights.`,
    },
    {
      element: `[data-onboarding="open-fullscreen"]`,
      title: msg`See the Bigger Picture`,
      intro: msg`Go full screen with this pin for an up-close look at your data. Perfect for those detailed dives.`,
    },
    {
      element: `[data-onboarding="chart-menu"]`,
      title: msg`... and more!`,
      intro: msg`Menu options are here to spice up your experience: 'Return to Chat' for a chat comeback, 'Download CSV' for data on the go, and 'Delete Pin' to tidy up.`,
    },
  ],
};
