import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";

import { BAR_CHART, CHART_LIMIT } from "../../../Constants/constants";
import { fetchChartTransformedData } from "../../../api/retriever";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import { ChartLoadingSpinner } from "../ChartLoadingSpinner/ChartLoadingSpinner";
import { sortDataLocally } from "../helpers";
import { transformData } from "./helpers";
import { ParentDocTypeEnum } from "../../../api/retriever.i";

interface BarChartProps {
  data: any;
  width: number;
  height: number;
  isDemo?: boolean;
  selectedColumns: any;
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  totalRows: number;
  sortModel?: Array<{ field: string; sort: string }>;
}

export const BarChart = ({
  data,
  isDemo = false,
  selectedColumns,
  docId,
  parentDocId,
  parentDocType,
  totalRows,
  width,
  height,
  sortModel,
}: BarChartProps) => {
  const chartRef = useRef();

  const [transformedData, setTransformedData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  console.log(totalRows, CHART_LIMIT);

  useEffect(() => {
    const fetchData = async () => {
      if (!isDemo && totalRows > CHART_LIMIT) {
        setLoading(true);
        try {
          const response = await fetchChartTransformedData({
            doc_id: docId,
            parent_doc_id: parentDocId,
            parent_doc_type: parentDocType,
            columns: selectedColumns,
            sort_model: sortModel,
            chart_type: BAR_CHART,
          });

          if (response.data) {
            setTransformedData(response.data); // Assuming the actual data is in response.data
          } else {
            setHasError(true);
          }
        } catch (error) {
          console.error("Error fetching transformed data:", error);
          setHasError(true);
        }
      } else if (data?.length > 0) {
        const sortData = sortDataLocally(sortModel, data);
        const transformedData = transformData(isDemo, selectedColumns, sortData);
        setTransformedData(transformedData);
      }
      setLoading(false);
    };

    fetchData();
  }, [data, selectedColumns]);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    if (transformedData.categories && transformedData.seriesData) {
      const isHorizontal = transformedData?.categories?.length > 5;

      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        legend: {
          data: transformedData?.seriesData?.map((series) => series.name),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            id: "dataZoomXslider",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
          {
            id: "dataZoomXinside",
            type: "inside",
            xAxisIndex: [0],
            filterMode: "filter",
          },
          {
            id: "dataZoomYslider",
            type: "slider",
            yAxisIndex: [0],
            filterMode: "empty",
          },
        ],

        xAxis: {
          type: isHorizontal ? "value" : "category",
          data: isHorizontal ? null : transformedData?.categories,
        },
        yAxis: {
          type: isHorizontal ? "category" : "value",
          data: isHorizontal ? transformedData?.categories : null,
          axisLabel: {
            width: 200,
            overflow: "truncate",
            ellipsis: "...",
          },
        },
        series: transformedData?.seriesData,
      };

      if (!isDemo && !transformedData?.shouldStack) {
        // Adjust the series configuration for grouping
        option.series?.forEach((series) => {
          series.stack = null;
        });
      }

      myChart.setOption(option);
      myChart.resize();
    }
    return () => myChart.dispose(); // Cleanup on unmount
  }, [width, height, transformedData]);

  return (
    <div data-testid="bar-chart" style={{ width, height }}>
      <div ref={chartRef} style={{ width, height }}></div>
      {loading && <ChartLoadingSpinner />}
      {hasError && <ChartErrorState />}
    </div>
  );
};
