import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { toast } from "react-hot-toast";
import { useIntegration } from "../../context/integrations";
import { withSentry } from "../../helpers/wrapper";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#15394680",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  padding: "2rem",
};

export default function DeleteIntegationsModal({ open, closeModal, source }) {
  const { _ } = useLingui();
  const { deleteSource } = useIntegration();
  const handleDelete = withSentry(async () => {
    const deleteStatus = await deleteSource(source?.id);
    if (deleteStatus.success) {
      toast.success(_(msg`Source deleted successfully`));
    } else {
      toast.error(_(msg`Error deleting source, please try again!`));
    }
    closeModal();
  });

  return (
    <div>
      <Modal
        data-testid="delete-source-modal"
        open={open}
        onClose={closeModal}
        aria-labelledby="delete-source-modal"
        aria-describedby={_(msg`modal to delete source`)}
        components={{
          Backdrop: CustomBackdrop,
        }}
      >
        <Box sx={style}>
          <Stack direction="column">
            <Typography
              id="delete-source-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#153946",
                fontSize: "1.5625rem",
                fontWeight: 800,
              }}
            >
              <Trans>Delete {source?.name}?</Trans>
            </Typography>

            <Typography
              id="delete-source-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{ fontSize: "0.9375rem", fontWeight: 300 }}
            >
              <Trans>
                This will delete the source and <b>all</b> data ingested from it.
              </Trans>
            </Typography>

            <br />
            <Stack direction="row" justifyContent={"center"} spacing={4}>
              <Button
                variant="outlined"
                onClick={closeModal}
                sx={{
                  border: "2px solid #8B8989",
                  color: "#8B8989",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  minWidth: "5.75rem",
                }}
              >
                <Trans>Cancel</Trans>
              </Button>

              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  data-testid="delete-source-button-modal"
                  variant="contained"
                  onClick={handleDelete}
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "0.875rem",
                    background: "#4EB5CC",
                    minWidth: "5.75rem",
                  }}
                >
                  <Trans>Delete</Trans>
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
