import ClearIcon from "@mui/icons-material/Clear";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { IconButton, ListItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { useState } from "react";

import { useChatData } from "../../context/chat";
import { useUI } from "../../context/ui";
import * as s from "./ChatDrawer.s";
import DeleteThreadModal from "./DeleteThreadModal";
import { Tab } from "./Tab/Tab";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const ChatDrawer = () => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState(null);

  const { chatDrawerOpen, setChatDrawerOpen } = useUI();
  const { filteredThreads, newChat, searchQuery, setSearchQuery, loading } = useChatData();

  const deleteThreadClick = (thread) => {
    setThreadToDelete(thread);
    setOpen(true);
  };

  const closeModal = () => {
    setThreadToDelete(null);
    setOpen(false);
  };

  return (
    <>
      <s.Drawer variant="persistent" anchor="left" open={chatDrawerOpen}>
        <s.DrawerHeader>
          <s.Button
            variant="contained"
            startIcon={<PostAddOutlinedIcon />}
            onClick={newChat}
            data-testid="new-chat-button"
            disabled={loading}
          >
            <Trans>New Chat</Trans>
          </s.Button>
          <s.CloseButton
            data-testid="chat-drawer-close-button"
            onClick={() => setChatDrawerOpen(false)}
          >
            <ClearIcon />
          </s.CloseButton>
        </s.DrawerHeader>
        <Divider />
        <List data-onboarding="chat-list">
          {!(filteredThreads.length === 0 && searchQuery.length === 0) && (
            <ListItem>
              <s.InputBase
                data-testid="search-input-chat-drawer"
                value={searchQuery}
                placeholder={_(msg`Search`)}
                inputProps={{ "aria-label": _(msg`search through chats`) }}
                onChange={(e) => setSearchQuery(e.target.value)}
                endAdornment={
                  searchQuery.length > 0 && (
                    <IconButton
                      data-testid="search-clear-icon-button"
                      onClick={() => setSearchQuery("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  )
                }
              />
            </ListItem>
          )}
          {filteredThreads.length === 0 ? (
            <s.EmptyMessage>
              {searchQuery.length === 0
                ? _(msg`Start chatting to see your old chats here!`)
                : _(msg`No results found :(`)}
            </s.EmptyMessage>
          ) : (
            filteredThreads.map((thread, index) => (
              <Tab
                key={index}
                thread={thread}
                index={index}
                deleteThreadClick={deleteThreadClick}
              />
            ))
          )}
        </List>
      </s.Drawer>
      <DeleteThreadModal open={open} closeModal={closeModal} threadId={threadToDelete} />
    </>
  );
};
