import styled from "styled-components";

export const MainPage = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  position: relative;
  padding: 12px;
  margin-left: 0;

  @media (min-width: 768px) {
    padding: 24px;

    ${({ open }) => open && `margin-left: 240px;`};
  }
`;
