import React from "react";
import { Box, Grid } from "@mui/material";
import SadGiraffeSavant from "../../assets/sad_girrafe_savant";
import { Trans } from "@lingui/macro";
// Define props type
interface SimpleErrorDisplayProps {
  errorMessage?: string;
}

const SavantErrorDisplay: React.FC<SimpleErrorDisplayProps> = ({ errorMessage }) => {
  return (
    <Box
      sx={{
        width: "100%",
        margin: 0,
        padding: 0,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "90%" }}
      >
        <div>
          <SadGiraffeSavant />
        </div>
        {errorMessage ? (
          <h3 style={{ color: "rgb(235, 29, 14, 0.8)", textAlign: "center", fontSize: "120%" }}>
            {errorMessage}
          </h3>
        ) : (
          <h3 style={{ color: "rgb(235, 29, 14, 0.8)", textAlign: "center", fontSize: "120%" }}>
            <Trans>An unknown error occurred.</Trans>
          </h3>
        )}
      </Grid>
    </Box>
  );
};

export default SavantErrorDisplay;
