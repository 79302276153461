import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

// MUI
import ChatIcon from "@mui/icons-material/Chat";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { downloadCSV } from "../../helpers/downloadCSV";

import { CHAT_VIEW } from "../../Constants/constants";

import { useAuth } from "../../context/auth";
import { useChatData } from "../../context/chat";
import { usePinboard } from "../../context/pinboard";
import { Pin } from "../../context/pinboard/pinboard.i";
import { useUI } from "../../context/ui";
import { ViewEnum } from "../../context/ui/ui.i";
import { withSentry } from "../../helpers/wrapper";
import useNotification from "../Navbar/UserProfile/Notifications/useNotification";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { ParentDocTypeEnum } from "../../api/retriever.i";

const buttonStyle = {
  "&.Mui-disabled": {
    color: "#c0c0c0",
  },
  color: "#003A47",
};

interface ChartMenuProps {
  results: Pin;
  handleRemove: any;
  disabled?: boolean;
  setOpenPinboardModal: (value: boolean) => void;
  handleOpenModal?: () => void;
  setFullScreenOpen?: (value: boolean) => void;
  isDataExplore?: boolean;
}

export default function ChartMenu({
  results,
  handleRemove,
  setOpenPinboardModal,
  disabled,
  handleOpenModal,
  setFullScreenOpen,
  isDataExplore = false,
}: ChartMenuProps) {
  const { _ } = useLingui();
  const { view, setView } = useUI();
  const { userDocument } = useAuth();
  const { navigateToChatFromPin, currentResult, deleteChatFromThread, dataMap, currentThread } =
    useChatData();
  const { boards, unpinFromBoard, currBoardId } = usePinboard();
  const { deleteNotificationByChatId } = useNotification();
  //Popover for dropdown menu
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const parentDocId = view === ViewEnum.CHAT_VIEW ? currentThread : currBoardId;
  const parentDocType =
    view === ViewEnum.CHAT_VIEW ? ParentDocTypeEnum.THREAD : ParentDocTypeEnum.PINBOARD;

  const openMenu = Boolean(anchorElMenu);

  const handleDownloadCSV: any = withSentry(async () => {
    await downloadCSV({
      doc_id: results?.id,
      parent_doc_id: parentDocId,
      parent_doc_type: parentDocType,
      filename: `${results?.title}.csv`,
      currentResult: results,
      dataMap,
    });
  });

  const handleAddPinboard = () => {
    setOpenPinboardModal(true);
    handleCloseMenu();
  };

  const handleMenuSelected = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleOpenFullscreen = () => {
    setFullScreenOpen(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleDelete = () => {
    handleRemove();
    handleCloseMenu();
  };

  const handleChatDelete = async () => {
    if (!window.confirm(_(msg`Are you sure you want to delete this chat?`))) return;

    for (const [board, details] of boards.entries()) {
      if (details.pins.includes(currentResult?.id)) {
        await unpinFromBoard(currentResult?.id, board);
      }
    }
    await deleteChatFromThread();
    if (currentResult?.type === "PREDICTION") {
      await deleteNotificationByChatId(currentResult?.id);
    }
  };

  const handleViewChat: any = withSentry(async () => {
    handleCloseMenu();
    await navigateToChatFromPin(results?.id);
    setView(ViewEnum.CHAT_VIEW);
  });

  const isDemo = (currentResult as any)?.data?.isDemo;

  const DropdownMenuPopover = () => (
    <Menu
      id="basic-menu"
      anchorEl={anchorElMenu}
      open={openMenu}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {view === ViewEnum.CHAT_VIEW && [
        <MenuItem
          disabled={disabled || isDataExplore}
          onClick={() => handleAddPinboard()}
          data-testid="add-to-pinboard"
          key="add-to-pinboard"
        >
          <ListItemIcon sx={buttonStyle}>
            <DashboardCustomizeIcon />
          </ListItemIcon>
          <Typography>
            <Trans>Add to pinboard</Trans>
          </Typography>
        </MenuItem>,
        <MenuItem disabled={disabled} onClick={handleOpenFullscreen} key="fullscreen">
          <ListItemIcon sx={buttonStyle}>
            <FullscreenIcon />
          </ListItemIcon>
          <Typography>
            <Trans>Full screen</Trans>
          </Typography>
        </MenuItem>,
      ]}
      <MenuItem
        disabled={disabled || isDemo}
        onClick={handleDownloadCSV}
        data-testid="download-csv"
      >
        <ListItemIcon sx={buttonStyle}>
          <FileDownloadOutlinedIcon />
        </ListItemIcon>
        <Typography>
          <Trans>Download CSV</Trans>
        </Typography>
      </MenuItem>

      {view === ViewEnum.CHAT_VIEW && (
        <MenuItem disabled={disabled} onClick={handleChatDelete} data-testid="download-csv">
          <ListItemIcon sx={buttonStyle}>
            <DeleteIcon />
          </ListItemIcon>
          <Typography>
            <Trans>Delete Chat</Trans>
          </Typography>
        </MenuItem>
      )}

      {view !== CHAT_VIEW && [
        <MenuItem
          disabled={disabled}
          onClick={handleViewChat}
          data-testid="view-chat"
          key="view-chat"
        >
          <ListItemIcon sx={buttonStyle}>
            <ChatIcon />
          </ListItemIcon>
          <Typography>
            <Trans>View chat</Trans>
          </Typography>
        </MenuItem>,
        <MenuItem disabled={disabled} onClick={handleDelete} data-testid="delete" key="delete">
          <ListItemIcon sx={buttonStyle}>
            <DeleteIcon />
          </ListItemIcon>
          <Typography>
            <Trans>Delete</Trans>
          </Typography>
        </MenuItem>,
      ]}
    </Menu>
  );

  return (
    <Stack direction="row" spacing={2}>
      {handleOpenModal && (
        <Tooltip title={<Trans>Show fullscreen</Trans>}>
          <IconButton onClick={() => handleOpenModal()} data-onboarding="open-fullscreen">
            <FullscreenIcon />
          </IconButton>
        </Tooltip>
      )}
      <IconButton
        id="basic-button"
        data-onboarding="chart-menu"
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleMenuSelected}
      >
        <MoreHorizIcon />
      </IconButton>

      <DropdownMenuPopover />
    </Stack>
  );
}
