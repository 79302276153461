import BarChartIcon from "@mui/icons-material/BarChartSharp";
import PieChartIcon from "@mui/icons-material/PieChartSharp";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import TableChartIcon from "@mui/icons-material/TableChartSharp";
import TimelineIcon from "@mui/icons-material/TimelineSharp";

import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useRef } from "react";
import {
  BAR_CHART,
  EMPTY_ROWS,
  ERROR,
  LINE_CHART,
  PIE_CHART,
  SAVANT_LINE,
  SCATTER_CHART,
  SQL_CHART,
  TABLE,
} from "../../Constants/constants";
import { ChartType } from "../../context/chart/chart.i";
import { useChatData } from "../../context/chat";
import { Result } from "../../context/chat/chat.i";
import { Pin } from "../../context/pinboard/pinboard.i";
import { Analytics } from "../../services/analytics/Analytics";
import { Theme } from "../../styled";
import { Trans } from "@lingui/macro";

interface ChartSelectorProps {
  result: Result | Pin;
  chartType: string;
  setChartType: (value: string) => void;
  charts: ChartType[];
  isPinboard?: boolean;
}

export const ChartSelector = ({
  result,
  chartType,
  setChartType: _setChartType,
  charts,
  isPinboard = false,
}: ChartSelectorProps) => {
  const headerRef = useRef(null);
  const { loading, currentResult } = useChatData();
  const setChartType = (value: string) => {
    Analytics.track("Chart Selector", { chartType: value });
    _setChartType(value);
  };

  const disableChartButton = (pin, chart) => {
    if (pin?.data?.isDemo) {
      return chart !== pin?.visualisation;
    }

    return !charts.includes(chart) && pin?.chartConfig?.suggestion !== chart;
  };

  const isActive = (chart) => {
    return chart === chartType;
  };

  return (
    <Box
      ref={headerRef}
      sx={{ overflow: "hidden", border: "2px solid #f5f5f5" }}
      data-testid="chart-selector"
      data-onboarding="chart-selector"
    >
      <Stack direction="row" justifyContent="flex-end">
        {result?.type === "PREDICTION" ? (
          <>
            <Tooltip title={<Trans>Show table</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="table-chart-button"
                  disabled={
                    loading || result?.status === "error" || result?.status === "processing"
                  }
                  onClick={() => setChartType(TABLE)}
                  sx={{
                    color: isActive(TABLE)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <TableChartIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={<Trans>Show Line Chart</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="line-chart-button"
                  disabled={
                    loading || result?.status === "error" || result?.status === "processing"
                  }
                  onClick={() => setChartType(SAVANT_LINE)}
                  sx={{
                    color: isActive(SAVANT_LINE)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <TimelineIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={<Trans>Show Prediction SQL</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="table-chart-button"
                  data-onboarding="sql-chart-button"
                  onClick={() => setChartType(SQL_CHART)}
                  disabled={
                    loading || result?.status === "error" || result?.status === "processing"
                  }
                  sx={{
                    color: isActive(SQL_CHART)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <span style={{ fontSize: 14 }}>
                    <Trans>SQL</Trans>
                  </span>
                </IconButton>
              </div>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={<Trans>Show table</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="table-chart-button"
                  disabled={
                    loading ||
                    disableChartButton(result, TABLE) ||
                    chartType === ERROR ||
                    chartType === EMPTY_ROWS ||
                    (result as Pin)?.visualisation === EMPTY_ROWS ||
                    (result as Pin)?.visualisation === ERROR
                  }
                  onClick={() => setChartType(TABLE)}
                  sx={{
                    color: isActive(TABLE)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <TableChartIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={<Trans>Show Bar Chart</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="bar-chart-button"
                  disabled={loading || disableChartButton(result, BAR_CHART)}
                  onClick={() => setChartType(BAR_CHART)}
                  sx={{
                    color: isActive(BAR_CHART)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <BarChartIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={<Trans>Show Pie Chart</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="pie-chart-button"
                  disabled={loading || disableChartButton(result, PIE_CHART)}
                  onClick={() => setChartType(PIE_CHART)}
                  sx={{
                    color: isActive(PIE_CHART)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <PieChartIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={<Trans>Show Line Chart</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="line-chart-button"
                  disabled={loading || disableChartButton(result, LINE_CHART)}
                  onClick={() => setChartType(LINE_CHART)}
                  sx={{
                    color: isActive(LINE_CHART)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <TimelineIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={<Trans>Show Scatter Plot</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="scatter-chart-button"
                  disabled={loading || disableChartButton(result, SCATTER_CHART)}
                  onClick={() => setChartType(SCATTER_CHART)}
                  sx={{
                    color: isActive(SCATTER_CHART)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <ScatterPlotIcon />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title={<Trans>Show SQL</Trans>} placement="top">
              <div>
                <IconButton
                  data-testid="table-chart-button"
                  data-onboarding="sql-chart-button"
                  onClick={() => setChartType(SQL_CHART)}
                  disabled={loading || result?.sql === ""}
                  sx={{
                    color: isActive(SQL_CHART)
                      ? Theme.core.colors.lightBlue
                      : Theme.core.colors.darkBlue,
                  }}
                >
                  <span style={{ fontSize: 14 }}>
                    <Trans>SQL</Trans>
                  </span>
                </IconButton>
              </div>
            </Tooltip>
          </>
        )}
      </Stack>
    </Box>
  );
};
