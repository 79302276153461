import styled from "styled-components";

export const ChatAndResult = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 30px;
  flex-direction: column;

  @media (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  height: calc(100vh - 200px);

  @media (min-width: 768px) {
    height: auto;
    flex: 2;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  height: calc(100vh - 200px);
  flex-direction: column;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 0px;
    padding-left: 12px;
    height: auto;
    flex: 3;
  }
`;
