import { Button, CircularProgress, FormControl, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import { useEffect, useState } from "react";
import { DEMO_ACCOUNT, SANDBOX_ACCOUNT } from "../../Constants/features";
import { useAuth } from "../../context";
import { useAlert } from "../../context/alert";
import { withSentry } from "../../helpers/wrapper";
import { fetchInvitationByOrgId, sendPwdResetEmailWithFirebase } from "../../services/firebase";
import DeleteModal from "../UserManagement/DeleteModal";
import * as s from "./Settings.s";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderRadius: "7px",
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #8B8989",
    fontSize: 16,
    width: "100%",
    flexBasis: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#153946",
    },
  },
}));

const headerStyle = {
  color: "#153946",
  fontSize: "1rem",
  font: "Inter",
  fontWeight: 900,
};

export const Settings = () => {
  const { userDocument, fetchUserDocument, setUserDocument, featureFlags, updateUserField } =
    useAuth();
  const { _ } = useLingui();
  const { showAlert } = useAlert();

  const [editName, setEditName] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [pwdEmailSent, setPwdEmailSent] = useState(false);
  const [organization, setOrganization] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingPassword, setSendingPassword] = useState(false);

  useEffect(() => {
    setFirstName(userDocument?.firstName);
    setLastName(userDocument?.lastName);
    setOrganization(userDocument?.organization);
  }, []);

  const handleUpdate = withSentry(async () => {
    if (!editName) {
      setEditName(true);

      return;
    }

    setLoading(true);
    try {
      if (firstName && firstName !== userDocument?.firstName) {
        await updateUserField("firstName", firstName);
      }

      if (lastName && lastName !== userDocument?.lastName) {
        await updateUserField("lastName", lastName);
      }

      const updateUserDoc = withSentry(async () => {
        const ud = await fetchUserDocument(userDocument?.id);
        setUserDocument(ud);
      });

      showAlert({
        variant: "success",
        message: _(msg`User updated successfully!`),
      });

      setTimeout(() => updateUserDoc(), 1000);
    } catch (e) {
      showAlert({
        variant: "error",
        message: _(msg`Error updating user name.`),
      });
      console.error(e);
      throw e;
    } finally {
      setLoading(false);
      setEditName(false);
    }
  });

  useEffect(() => {
    const fetchOrg = withSentry(async () => {
      const org = await fetchInvitationByOrgId(userDocument?.organization);
      if (org) {
        setOrganization(org?.id);
      }
    });
    fetchOrg();
  }, []);

  const handlePasswordUpdate = withSentry(async () => {
    if (pwdEmailSent) return;

    setSendingPassword(true);
    try {
      await sendPwdResetEmailWithFirebase(userDocument?.email);
      setPwdEmailSent(true);
      showAlert({
        variant: "success",
        message: _(msg`Password reset email sent to ${userDocument?.email}`),
      });
    } catch (e) {
      showAlert({
        variant: "error",
        message: _(msg`Error sending password reset email to ${userDocument?.email}`),
      });
      console.error(e);
      throw e;
    } finally {
      setSendingPassword(false);
    }
  });

  const onEnterOnInput = (ev) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      handleUpdate();
    }
  };

  return (
    <s.Settings>
      <s.HeadingText>
        <Trans>My Settings</Trans>
      </s.HeadingText>
      <s.TitleAndEdit>
        <span style={headerStyle}>
          <Trans>Basic Info</Trans>
        </span>
      </s.TitleAndEdit>
      <s.Combo>
        <s.Half>
          <FormControl variant="standard" sx={{ minWidth: "100%" }}>
            <CustomInput
              id="bootstrap-input"
              placeholder={_(msg`First Name`)}
              disabled={!editName || loading}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onKeyDown={onEnterOnInput}
            />
          </FormControl>
        </s.Half>
        <s.Half>
          <FormControl variant="standard" sx={{ minWidth: "100%" }}>
            <CustomInput
              id="bootstrap-input"
              placeholder={_(msg`Last Name`)}
              disabled={!editName || loading}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onKeyDown={onEnterOnInput}
            />
          </FormControl>
        </s.Half>
        <Button
          onClick={handleUpdate}
          variant={editName ? "contained" : "outlined"}
          disabled={loading}
          startIcon={loading ? <CircularProgress size="14px" /> : null}
        >
          {editName ? _(msg`Save`) : _(msg`Edit`)}
        </Button>
        {editName && (
          <Button onClick={() => setEditName(false)} variant="outlined" disabled={loading}>
            <Trans>Cancel</Trans>
          </Button>
        )}
      </s.Combo>

      <s.TitleAndEdit>
        <span style={headerStyle}>
          <Trans>Email</Trans>
        </span>
      </s.TitleAndEdit>
      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
        <CustomInput id="bootstrap-input" placeholder={userDocument?.email} disabled={true} />
      </FormControl>
      <s.TitleAndEdit>
        <span style={headerStyle}>
          <Trans>Org</Trans>
        </span>
      </s.TitleAndEdit>
      <FormControl variant="standard" sx={{ minWidth: "100%" }}>
        <CustomInput
          id="bootstrap-input"
          placeholder={organization}
          disabled={true}
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
        />
      </FormControl>
      <s.InputHeading>
        <span style={headerStyle}>
          <Trans>Password</Trans>
        </span>
      </s.InputHeading>
      <s.Half>
        <Button
          startIcon={sendingPassword ? <CircularProgress size="14px" /> : null}
          disabled={pwdEmailSent || sendingPassword}
          onClick={handlePasswordUpdate}
          variant="contained"
        >
          <Trans>Change Password</Trans>
        </Button>
      </s.Half>
      <s.PasswordChangeMessage>
        <Trans>You will receive an email to reset your password.</Trans>
      </s.PasswordChangeMessage>
      {!featureFlags.includes(SANDBOX_ACCOUNT) && !featureFlags.includes(DEMO_ACCOUNT) && (
        <>
          <s.InputHeading>
            <span
              style={{
                color: "#153946",
                fontSize: "0.9375rem",
                font: "Inter",
                fontWeight: 900,
              }}
            >
              <Trans>Delete Account</Trans>
            </span>
          </s.InputHeading>
          <Typography
            id="modal-modal-description"
            variant="caption"
            component={"div"}
            style={{
              fontSize: "0.9375rem",
              fontWeight: 300,
              color: "#153946",
            }}
          >
            <Trans>Revoke access to doowii and clear all data stored for your account.</Trans>
          </Typography>
          <s.Half>
            <Button
              data-testid="delete-account-button"
              variant="contained"
              color="error"
              sx={{
                color: "white",
                textTransform: "capitalize",
                fontSize: "0.875rem",
                minWidth: "5.75rem",
              }}
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              <Trans>Delete</Trans>
            </Button>
          </s.Half>
        </>
      )}
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        currUserInspected={userDocument}
      />
    </s.Settings>
  );
};
