import { Send } from "@mui/icons-material";

import * as s from "./ChatSuggestion.s";

export const ChatSuggestion = ({ question, chat, loading }) => {
  return (
    <s.ChatSuggestion
      data-testid="chat-suggestion-button"
      variant="outlined"
      onClick={() => chat(question)}
      disabled={loading}
    >
      {question}
      <s.ActionOverlay>
        <Send />
      </s.ActionOverlay>
    </s.ChatSuggestion>
  );
};
