import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";

import * as s from "./TableRow.s";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { headerFields } from "./UserManagement";

export const TableRow = ({
  user,
  index,
  setDeleteModalOpen,
  setEditModalOpen,
  setEmailModalOpen,
  setCurrUserInspected,
}) => {
  const { _ } = useLingui();
  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
    setCurrUserInspected(user);
  };

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
    setCurrUserInspected(user);
  };

  const handleEmailModalOpen = () => {
    setEmailModalOpen(true);
    setCurrUserInspected(user);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F5F5F5",
        padding: "0.5rem",
        borderRadius: "7px",
      }}
    >
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <s.ResponsiveBodyGrid
          $headerField={headerFields.name}
          item
          sx={{ textAlign: "left", color: "#8B8989", fontSize: "0.9rem" }}
        >
          {`${user.firstName} ${user.lastName}`}
        </s.ResponsiveBodyGrid>
        <s.ResponsiveBodyGrid
          item
          $headerField={headerFields.email}
          sx={{ textAlign: "left", color: "#8B8989", fontSize: "0.9rem" }}
        >
          {user.email}
        </s.ResponsiveBodyGrid>

        <s.ResponsiveBodyGrid
          $headerField={headerFields.joinedOn}
          item
          sx={{ textAlign: "left", color: "#8B8989", fontSize: "0.9rem" }}
        >
          {user?.registration?.status === "pending"
            ? _(msg`Invited on ${user?.registration?.date}`)
            : user?.registration?.date}
        </s.ResponsiveBodyGrid>
        <s.ResponsiveBodyGrid
          $headerField={headerFields.lastLoggedIn}
          item
          sx={{ textAlign: "left", color: "#8B8989", fontSize: "0.9rem" }}
        >
          {`${user?.LSO}`}
        </s.ResponsiveBodyGrid>
        <s.ResponsiveBodyGrid
          item
          $headerField={headerFields.action}
          sx={{ textAlign: "left", color: "#8B8989", fontSize: "0.9rem" }}
        >
          <Stack direction={"row"} justifyContent={"flex-start"}>
            <Tooltip title={_(msg`Delete`)} placement="left">
              <IconButton onClick={handleDeleteModalOpen}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={user?.registration?.status === "pending" ? _(msg`Email`) : _(msg`Edit`)}
              placement="right"
            >
              <IconButton
                onClick={() =>
                  user?.registration?.status === "pending"
                    ? handleEmailModalOpen()
                    : handleEditModalOpen()
                }
              >
                {user?.registration?.status === "pending" ? <MailIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>
          </Stack>
        </s.ResponsiveBodyGrid>
      </Grid>
    </Box>
  );
};
