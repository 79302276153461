import styled from "styled-components";

export const Timestamps = styled.div`
  display: flex;
  font-size: 13px;
  gap: 5px;
`;

export const Question = styled.div`
  margin: 24px 0;
`;

export const ChartContainer = styled.div`
  position: relative;
  flex: 1;
  min-height: 300px;
  display: flex;
  flex-direction: column;
`;
