import Logout from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { Divider, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DEMO_ACCOUNT,
  DEV_ACCOUNT,
  HAS_INTEGRATIONS,
  SANDBOX_ACCOUNT,
  DOES_NOT_HAVE_MANAGE_USERS,
} from "../../../../Constants/features";
import { useAuth } from "../../../../context";
import { useChatData } from "../../../../context/chat";
import { useUI } from "../../../../context/ui";
import { ViewEnum } from "../../../../context/ui/ui.i";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

const StyledMenu: any = styled<any>((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "#707070",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      "&:hover": {
        backgroundColor: "#F5CC61",
        color: "black",
      },
    },
    "& .Mui-disabled": {
      opacity: 1,
    },
  },
}));

export const SettingsMenu = () => {
  const { _ } = useLingui();
  const { setView } = useUI();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rotate, setRotate] = useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const { userDocument, signOut, featureFlags } = useAuth();
  const { loading } = useChatData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setRotate(!rotate);
  };

  const navigateToUserManagement = () => {
    setView(ViewEnum.USER_MANAGEMENT_VIEW);
    handleClose();
  };

  const navigateToSettings = () => {
    setView(ViewEnum.SETTINGS_VIEW);
    handleClose();
  };

  const navigateToIntegrations = () => {
    setView(ViewEnum.INTEGRATIONS_VIEW);
    handleClose();
  };

  const navigateToCacheValidator = () => {
    navigate("/cache-validator");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRotate(!rotate);
  };
  const isSandboxAccount = featureFlags.includes(SANDBOX_ACCOUNT);
  const isDemoAccount = featureFlags.includes(DEMO_ACCOUNT);
  const isDevAccount = featureFlags.includes(DEV_ACCOUNT);
  const hasIntegrations = featureFlags.includes(HAS_INTEGRATIONS);
  const hideManageUsers = featureFlags.includes(DOES_NOT_HAVE_MANAGE_USERS);

  return (
    <div>
      <Tooltip title={_(msg`Settings`)} placement="bottom">
        <div>
          <IconButton
            data-onboarding="open-settings-button"
            data-testid="settings-button"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              transform: rotate ? "rotate(-90deg)" : "rotate(0)",
            }}
            disabled={loading}
          >
            <SettingsIcon
              sx={{
                color: open ? "#3CA0C3" : "#153946",
                "&:hover": {
                  color: "#3CA0C3",
                },
                fontSize: "2rem",
              }}
            />
          </IconButton>
        </div>
      </Tooltip>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open && !loading}
        onClose={handleClose}
      >
        {isDemoAccount && (
          <div>
            <MenuItem disabled={true}>
              <span className="user-name">
                {`${userDocument?.firstName} ${userDocument?.lastName}`}
              </span>
            </MenuItem>
            <Divider />
          </div>
        )}

        <MenuItem onClick={navigateToSettings} data-testid="my-settings-button">
          <Trans>My settings</Trans>
        </MenuItem>

        {!isSandboxAccount && !hideManageUsers && (
          <MenuItem onClick={navigateToUserManagement} data-testid="user-management-button">
            <Trans>Manage users</Trans>
          </MenuItem>
        )}
        {!isSandboxAccount && hasIntegrations && (
          <MenuItem onClick={navigateToIntegrations} data-testid="integrations-button">
            <Trans>Integrations</Trans>
          </MenuItem>
        )}

        {isDevAccount && (
          <MenuItem onClick={navigateToCacheValidator} data-testid="cache-validator-button">
            <Trans>Cache Validator</Trans>
          </MenuItem>
        )}
        <Box style={{ height: "5rem" }}></Box>
        <MenuItem onClick={signOut} data-testid="logout-button">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Trans>Log out</Trans>
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
