import { Skeleton } from "@mui/material";

import { useChatData } from "../../../context/chat";
import { useUI } from "../../../context/ui";
import { QuestionTypeEnum } from "../../../search/Doowii.i";
import { ChatSuggestion } from "./ChatSuggestion";
import * as s from "./Suggestions.s";

export const Suggestions = ({ doowii, setQuery, clearSearchInput, setClearSearchInput }) => {
  const { dynamicCache, fetchingSuggestions, loading } = useChatData();
  const { model } = useUI();

  const handleChatSuggestion = (chat) => {
    if (loading) return;

    if (chat) {
      doowii.chat({
        query: chat,
        index: 0,
        model,
        recent_messages: [],
        questionType: QuestionTypeEnum.SUGGESTION,
      });
      setQuery("");
      setClearSearchInput(!clearSearchInput);
    }
  };

  return (
    <s.Suggestions data-onboarding="suggestions">
      {fetchingSuggestions &&
        Array.from({ length: 4 }).map((_, i) => {
          return (
            <Skeleton
              key={i}
              sx={{ borderRadius: 5 }}
              variant="rectangular"
              width="100%"
              height={120}
            />
          );
        })}
      {!fetchingSuggestions &&
        dynamicCache
          .slice(0, 4)
          .map((question, index) => (
            <ChatSuggestion
              key={`chat-suggestion-${index}`}
              question={question}
              chat={handleChatSuggestion}
              loading={loading}
            />
          ))}
    </s.Suggestions>
  );
};
