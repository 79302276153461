import React from "react";
import { Box, Grid } from "@mui/material";

export default function Header({ header }) {
  const determineColumnWidth = () => {
    if (header === "Name") {
      return 1;
    } else if (header === "Data Source") {
      return 2;
    } else if (header === "Authorized Access") {
      return 5;
    } else if (header === "Status") {
      return 1;
    } else if (header === "Last Updated") {
      return 2;
    } else if (header === "Actions") {
      return 1;
    }
  };
  return (
    <>
      <Grid item xs={determineColumnWidth()}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            color: "#153946",
            fontWeight: 800,
            fontSize: "1rem",
            padding: "0.8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            {header}{" "}
          </Box>
        </Box>
      </Grid>
    </>
  );
}
