import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../context/auth";
import { useOrgUsers } from "../../context/users";
import { withSentry } from "../../helpers/wrapper";
import { auth, disableAccount } from "../../services/firebase";
import { Trans } from "@lingui/macro";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "#15394680",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  padding: "2rem",
};

export default function DeleteModal({
  open,
  setOpen,
  currUserInspected,
  setCurrUserInspected = () => {},
}) {
  const handleClose = () => {
    setOpen(false);
    setCurrUserInspected(null);
  };

  const { signOut, userDocument } = useAuth();
  const { fetchUsersInOrganization } = useOrgUsers();

  const handleDelete = withSentry(async () => {
    try {
      await disableAccount(currUserInspected, userDocument);
      await fetchUsersInOrganization();
      if (currUserInspected.email === auth.currentUser.email) {
        signOut();
      }
      handleClose();
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  return (
    <div>
      <Modal
        data-testid="delete-user-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        components={{
          Backdrop: CustomBackdrop,
        }}
      >
        <Box sx={style}>
          <Stack direction="column">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#153946",
                fontSize: "1.5625rem",
                fontWeight: 800,
              }}
            >
              <Trans>Are you sure?</Trans>
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{ fontSize: "0.9375rem", fontWeight: 300 }}
            >
              <Trans>You are about to delete the user:</Trans>
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="caption"
              style={{ fontSize: "0.9375rem", fontWeight: 1000 }}
            >
              {`${currUserInspected?.firstName} ${currUserInspected?.lastName}`}
            </Typography>

            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{ mt: 2 }}
              style={{
                fontSize: "0.9rem",
                fontWeight: 300,
              }}
            >
              <Trans>All associated data will also be deleted. There is no undo.</Trans>
            </Typography>
            <br />
            <Stack direction="row" justifyContent={"center"} spacing={4}>
              <Button
                variant="outlined"
                sx={{
                  border: "2px solid #8B8989",
                  color: "#8B8989",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  minWidth: "5.75rem",
                }}
                onClick={handleClose}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                variant="contained"
                onClick={handleDelete}
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "0.875rem",
                  background: "#4EB5CC",
                  minWidth: "5.75rem",
                }}
              >
                <Trans>Delete</Trans>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
