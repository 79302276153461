import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useUI } from "../../context/ui";

function DataExplore({ htmlFilePath }) {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 4,
        minWidth: "100%",
        minHeight: "80%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <iframe
        src={htmlFilePath}
        title="HTML Content"
        style={{ width: "100%", height: "100%", border: "none", flex: "1" }}
      ></iframe>
    </Box>
  );
}

export default DataExplore;
