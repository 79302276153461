import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";

import { availableCharts, availableChartsForCanvas } from "../../helpers";
import { useAuth } from "../auth";
import { useChatData } from "../chat";
import { ChartContextI, ChartType, TableColumns } from "./chart.i";

const initialState: ChartContextI = {
  selectedColumns: {},
  setSelectedColumns: (selectedColumns: TableColumns) => {},
  charts: [],
  setCharts: (charts: ChartType[]) => {},
  clearCharts: () => {},
};

const ChartContext = createContext(initialState);

export function useChart() {
  return useContext(ChartContext);
}

export const ChartProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isCanvasAccount } = useAuth();
  const [selectedColumns, setSelectedColumns] = useState<TableColumns>({});
  const [charts, setCharts] = useState<ChartType[]>([]);

  const { currentResult } = useChatData();

  const clearCharts = () => {
    setSelectedColumns({});
    setCharts([]);
  };

  useEffect(() => {
    clearCharts();
  }, [currentResult?.id]);

  useEffect(() => {
    const charts = isCanvasAccount
      ? (availableChartsForCanvas(selectedColumns) as ChartType[])
      : (availableCharts(selectedColumns) as ChartType[]);

    setCharts(charts);
  }, [selectedColumns]);

  const value = {
    selectedColumns,
    setSelectedColumns,
    charts,
    setCharts,
    clearCharts,
  };

  return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>;
};
