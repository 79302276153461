import { Box, Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useOrgUsers } from "../../context/users";
import { withSentry } from "../../helpers/wrapper";
import { Loading } from "../LoadingSpinner/Loading";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditUserModal";
import InviteModal from "./InviteModal";
import ResendEmailModal from "./ResendEmailModal";
import { TableHeader } from "./TableHeader";
import * as tableHeaderS from "./TableHeader.s";
import { TableRow } from "./TableRow";
import * as s from "./UserManagement.s";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

export const headerFields = {
  name: "name",
  email: "email",
  joinedOn: "joinedOn",
  lastLoggedIn: "lastLoggedIn",
  action: "action",
} as const;

export type HeaderFieldValues = keyof typeof headerFields;

export const headers = [
  { field: headerFields.name, label: msg`Name` },
  { field: headerFields.email, label: msg`Email` },
  { field: headerFields.joinedOn, label: msg`Joined On` },
  { field: headerFields.lastLoggedIn, label: msg`Last Logged In` },
];

export const UserManagement = () => {
  const { _ } = useLingui();

  const [sort, setSort] = useState<{
    [key in HeaderFieldValues]: "asc" | "desc";
  }>({
    [headerFields.name]: "asc",
    [headerFields.email]: "asc",
    [headerFields.joinedOn]: "asc",
    [headerFields.lastLoggedIn]: "asc",
    [headerFields.action]: "asc",
  });

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [currUserInspected, setCurrUserInspected] = useState(null);

  const {
    clearUsersData,
    fetchUsersInOrganization,
    organizationUsers,
    fetchingOrganizationUsers,
    setOrganizationUsers,
  } = useOrgUsers();

  useEffect(() => {
    const fetch = withSentry(async () => {
      await fetchUsersInOrganization();
    });

    fetch();

    return () => {
      clearUsersData();
    };
  }, [fetchUsersInOrganization, clearUsersData]);

  const sortUsers = (currentSortField, currentSortDirection) => {
    const sorted = [...organizationUsers].sort((a, b) => {
      let valueA, valueB;

      if (currentSortField === "joinedOn") {
        valueA = a?.registration?.date ? new Date(a?.registration?.date).getTime() : Infinity;
        valueB = b?.registration?.date ? new Date(b?.registration?.date).getTime() : Infinity;
      } else if (currentSortField === "lastLoggedIn") {
        valueA = a["LSO"] ? new Date(a["LSO"]).getTime() : Infinity;
        valueB = b["LSO"] ? new Date(b["LSO"]).getTime() : Infinity;
      } else if (currentSortField === "name") {
        valueA = `${a?.firstName || ""} ${a?.lastName || ""}`;
        valueB = `${b?.firstName || ""} ${b?.lastName || ""}`;
      } else {
        valueA = a[currentSortField] === undefined ? "zzz" : a[currentSortField];
        valueB = b[currentSortField] === undefined ? "zzz" : b[currentSortField];
      }
      const ascComparison = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      return currentSortDirection === "asc" ? ascComparison : -ascComparison;
    });

    setOrganizationUsers(sorted);
  };

  const handleSort = (field) => {
    const currentSortDirection = sort[field] === "asc" ? "desc" : "asc";
    setSort({ ...sort, [field]: currentSortDirection });
    sortUsers(field, currentSortDirection);
  };

  return (
    <s.UserManagement>
      <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
        <span
          style={{
            color: "#153946",
            fontSize: "1.5625rem",
            font: "Inter",
            fontWeight: 900,
          }}
        >
          <Trans>Current users</Trans>
        </span>
        <Button
          sx={{
            background: "#4EB5CC",
            fontWeight: "700",
            textTransform: "capitalize",
            "&:hover": { background: "#4EB5CC" },
          }}
          variant="contained"
          onClick={() => setInviteModalOpen(true)}
        >
          <Trans>Invite More Users</Trans>
        </Button>
      </Stack>
      <br />
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F5F5F5",
          padding: "0.5rem",
          borderRadius: "7px",
          position: "sticky",
          top: 0,
        }}
      >
        <Grid container>
          {headers.map((header, i) => (
            <TableHeader
              key={i}
              headerLabel={_(header.label)}
              sort={sort}
              handleSort={handleSort}
              sortField={header.field}
            />
          ))}
          <tableHeaderS.ResponsiveHeaderGrid item $headerField={headerFields.action}>
            <Box
              sx={{
                display: "flex",
                color: "#153946",
                fontWeight: 800,
                fontSize: "1rem",
              }}
            >
              <Trans>Actions</Trans>
            </Box>
          </tableHeaderS.ResponsiveHeaderGrid>
        </Grid>
      </Box>
      {fetchingOrganizationUsers ? (
        <s.EmptyState>
          <Loading />
        </s.EmptyState>
      ) : organizationUsers.length === 0 ? (
        <s.EmptyState>
          <p>
            <Trans>No other users to display yet.</Trans>
          </p>
          <p>
            <Trans>Click on Invite more Users to get started.</Trans>
          </p>
        </s.EmptyState>
      ) : (
        organizationUsers.map((user, i) => (
          <TableRow
            key={i}
            user={user}
            index={i}
            setDeleteModalOpen={setDeleteModalOpen}
            setEditModalOpen={setEditModalOpen}
            setEmailModalOpen={setEmailModalOpen}
            setCurrUserInspected={setCurrUserInspected}
          />
        ))
      )}
      <InviteModal open={inviteModalOpen} setOpen={setInviteModalOpen} />
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        currUserInspected={currUserInspected}
        setCurrUserInspected={setCurrUserInspected as any}
      />
      <EditModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        currUserInspected={currUserInspected}
        setCurrUserInspected={setCurrUserInspected}
      />
      <ResendEmailModal
        open={emailModalOpen}
        setOpen={setEmailModalOpen}
        currUserInspected={currUserInspected}
      />
    </s.UserManagement>
  );
};
