import axios from "axios";
import useSWR from "swr";
import { CHART_DATA_LIMIT } from "../Constants/constants";
import { getCurrentUserAuthorization } from "../api/base";
import { useChatData } from "../context/chat";
import { usePinboard } from "../context/pinboard";
import { useUI } from "../context/ui";
import { ViewEnum } from "../context/ui/ui.i";
import { ParentDocTypeEnum } from "../api/retriever.i";

export const useSwrQuery = ({ sql_query, doc_id }) => {
  const { currentThread } = useChatData();
  const { currBoardId } = usePinboard();
  const { view } = useUI();

  const sortModel = JSON.parse(localStorage.getItem(`sortModel-${doc_id}`) || "[]");
  const parentDocId = view === ViewEnum.CHAT_VIEW ? currentThread : currBoardId;
  const parentDocType =
    view === ViewEnum.CHAT_VIEW ? ParentDocTypeEnum.THREAD : ParentDocTypeEnum.PINBOARD;

  const key = JSON.stringify({
    sql_query: sql_query,
    sort_model: sortModel,
  });

  const url = process.env.REACT_APP_RETRIEVER_API_URL + "/retrieve";

  const dataRetrieve = async () => {
    console.log("Fetching data from API", doc_id);
    const token = await getCurrentUserAuthorization();
    const response = await axios.post(
      url,
      {
        doc_id: doc_id,
        parent_doc_id: parentDocId,
        parent_doc_type: parentDocType,
        sort_model: sortModel,
        page_size: CHART_DATA_LIMIT,
        page_number: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  const { data, error, isLoading } = useSWR(sql_query ? key : null, dataRetrieve, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
  });

  return {
    allData: data?.rows,
    isError: error,
    isLoading: isLoading,
    totalRows: data?.totalRows,
  };
};
