import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { usePinboard } from "../../context/pinboard";
import { withSentry } from "../../helpers/wrapper";
import { Trans } from "@lingui/macro";

export default function FavoriteBoardCreateModal({ open, setOpen }) {
  const [value, setValue] = React.useState("");
  const [isTitleInvalid, setIsTitleInvalid] = React.useState(false);
  const { addNewBoard } = usePinboard();
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = withSentry(async () => {
    if (value.trim() === "") {
      setIsTitleInvalid(true);
      return;
    }

    await addNewBoard(value);
    setValue("");
    setOpen(false);
  });

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle id="pinboard-modal-title">
          <Trans>Add a Pinboard</Trans>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            required
            fullWidth
            label={<Trans>New Pinboard Name:</Trans>}
            // inputProps={{
            //   maxLength: PINBOARD_NAME_CHARACTER_LIMIT
            // }}
            id="filled-error"
            defaultValue=""
            onChange={(e) => setValue(e.currentTarget.value)}
            variant="standard"
            error={isTitleInvalid}
            helperText={isTitleInvalid ? <Trans>Name cannot be empty!</Trans> : ""}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleAdd} variant="contained" color="primary">
            <Trans>Save</Trans>
          </Button>
          <Button onClick={handleClose}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
