import { post } from "./base";

export const sendInvitationEmail = async (invited_email: string, invited_by: string) => {
  try {
    const response = post("/send-invitation-email/", {
      invitation: {
        invited_email: invited_email,
        invited_by: invited_by,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending email", error);
  }
};
