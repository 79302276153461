import { Button } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { SAVANT_LINE, SQL_CHART, TABLE } from "../../../../Constants/constants";
import { getRelativeDateAndTime } from "../../../../canvas-new/components/ChatTab/ChatArea/ChatTile/SpeechBubble/BubbleTextArea/time-helper";
import { useChart } from "../../../../context/chart";
import { useChatData } from "../../../../context/chat";
import { usePinboard } from "../../../../context/pinboard";
import { VisualizationTypesEnum } from "../../../../context/pinboard/pinboard.i";
import { firebaseTimestampToLuxon } from "../../../../helpers/time";
import { withSentry } from "../../../../helpers/wrapper";
import { ChartSelector } from "../../../ChartSelector/ChartSelector";
import { Modal } from "../../../Modal/Modal";
import { FullScreenChart } from "./Chart/Chart";
import * as s from "./FavoriteFullScreenModal.s";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useLocaleContext } from "../../../../context/locale";

interface FavoriteFullScreenModalProps {
  pinId: string | null;
  handleClose: () => void;
}

export const FavoriteFullScreenModal: FC<FavoriteFullScreenModalProps> = ({
  pinId,
  handleClose,
}) => {
  const { _ } = useLingui();
  const { locale } = useLocaleContext();
  const { pinboardResults, currBoardId, updatePinVisualization } = usePinboard();
  const { charts, selectedColumns, setSelectedColumns } = useChart();
  const { fetchSummaryFromPin } = useChatData();

  const currentPin = useMemo(() => pinboardResults.find((pin) => pin.id === pinId), [pinId]);

  const [chartType, setChartType] = useState(
    currentPin?.type === "PREDICTION" ? SAVANT_LINE : currentPin?.chartConfig?.suggestion ?? TABLE
  );
  const [chatSummary, setChatSummary] = useState(_(msg`Loading...`));
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setSelectedColumns(currentPin?.selectedColumns ?? {});
  }, [currentPin, pinId]);

  useEffect(() => {
    const fetchData = withSentry(async () => {
      const summary = await (fetchSummaryFromPin as any)(currentPin);
      setChatSummary(summary);
    });
    if (currentPin) {
      fetchData();
    }
  }, [currentPin]);

  const [pinnedOn, lastUpdated] = useMemo(() => {
    if (!currentPin) return ["", ""];

    const createdAtLuxon = firebaseTimestampToLuxon(currentPin?.created_at);
    const createdAtRelative = getRelativeDateAndTime(createdAtLuxon, locale);

    const updatedAtLuxon = firebaseTimestampToLuxon(currentPin?.last_updated);
    const updatedAtRelative = getRelativeDateAndTime(updatedAtLuxon, locale);

    return [createdAtRelative, updatedAtRelative];
  }, [currentPin?.created_at, currentPin?.last_updated, locale]);

  const isDirty = useMemo(() => {
    if (chartType === SQL_CHART) return false;

    const orderedSelectedColumns = Object.keys(selectedColumns).sort().toString();
    const orderedPinSelectedColumns = Object.keys(currentPin?.selectedColumns ?? {})
      .sort()
      .toString();

    if (orderedSelectedColumns !== orderedPinSelectedColumns) {
      if (currentPin?.visualisation === TABLE && chartType === TABLE) return false;

      return true;
    }

    if (currentPin?.visualisation !== chartType) return true;

    return false;
  }, [selectedColumns, chartType]);

  const updatePin = async () => {
    if (!currentPin) return;

    setUpdating(true);

    await updatePinVisualization(
      currBoardId,
      pinId,
      selectedColumns,
      chartType as VisualizationTypesEnum
    );

    setUpdating(false);
    handleClose();
  };

  const createMarkdownText = (markdownText) => {
    try {
      if (!markdownText) return null;
      return <ReactMarkdown children={markdownText} rehypePlugins={[rehypeRaw]} />;
    } catch (e) {
      console.error(e);
      return markdownText;
    }
  };

  return (
    <Modal
      open={!!pinId}
      handleClose={handleClose}
      title={currentPin?.title}
      actions={
        <Button disabled={!isDirty || updating} variant="outlined" onClick={updatePin}>
          <Trans>Save</Trans>
        </Button>
      }
      disabled={updating}
    >
      <s.Timestamps>
        <span>
          <strong>
            <Trans>Pinned:</Trans>
          </strong>{" "}
          {pinnedOn}
        </span>
        |
        <span>
          <strong>
            <Trans>Updated:</Trans>
          </strong>{" "}
          {lastUpdated}
        </span>
      </s.Timestamps>
      <s.Question>{createMarkdownText(chatSummary)}</s.Question>
      <s.ChartContainer>
        <ChartSelector
          result={currentPin}
          chartType={chartType}
          setChartType={setChartType}
          charts={charts}
          isPinboard={true}
        />
        <FullScreenChart
          pin={currentPin}
          chartType={chartType}
          setChartType={setChartType}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          chatSummary={chatSummary}
        />
      </s.ChartContainer>
    </Modal>
  );
};
