import { Button } from "@mui/material";
import styled from "styled-components";

export const ChatSuggestion = styled(Button)`
  text-align: left;
  width: 100%;
  height: 100%;
  position: relative;
  text-transform: none !important;
  padding: 15px 15px !important;
  color: #003a47 !important;
  border: 1px solid #ccc !important;

  &:hover {
    > div {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    padding: 10px 15px !important;
  }
`;

export const ActionOverlay = styled.div`
  opacity: 0;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f55a;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
`;
