import styled from "styled-components";

export const SearchBar = styled.form<{ $standAlone?: boolean }>`
  position: relative;
  border: 1px solid #ccc; /* Add the border back */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add the drop shadow */
  border-radius: 5px; /* Add rounded corners */
  padding: ${({ $standAlone }) => ($standAlone ? "20px" : "10px 20px")};
  /* margin-bottom: 20px; */
  font-size: 1.2em;
  text-align: center;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  input[type="text"] {
    border: none;
    outline: none;
    font-size: 16px;
    flex-grow: 1;
    padding-right: 40px; /* Add some padding to the right of the input to make room for the icon */
    background-color: transparent; /* Remove the default background color */
  }

  button[type="submit"] {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    color: #000;
    font-size: inherit;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.2s ease;

    &:hover {
      background-color: transparent;
      opacity: 0.5;
    }

    &:active {
      background-color: transparent;
    }
  }

  button[type="button"] {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    color: #000;
    font-size: inherit;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.2s ease;

    &:hover {
      background-color: transparent;
      opacity: 0.5;
    }

    &:active {
      background-color: transparent;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

`;

export const Helper = styled.div`
  font-size: 14px;
  color: #003a47;
  margin-top: 10px;
  width: 100%;
  text-align: center;

  &:hover {
    text-decoration: underline; /* Set text decoration to underline on hover */
    cursor: pointer;
  }
`;

export const HelpersContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Align items on opposite ends */
  width: 100%; /* Take full width to spread items across the line */
`;

export const Helpers = styled.div`
  font-size: 14px;
  color: #003a47;
  margin-top: 10px;

  &:hover {
    text-decoration: underline; /* Underline on hover */
    cursor: pointer;
  }
`;
